<template>
  <div class="deposit_container">
    <div class="tips_container" v-if="status !== 2">
      <div class="tips">
        In order to ensure the safety of your assets, to minimize the risk of
        the account, you need to complete the following conditions before you
        can carry out legal currency business operations：
      </div>
      <div class="tips_btn_container">
        <div class="tips_btn">
          <img src="@/assets/tips_svg.svg" alt="" />
          <div class="tips_w">Complete LV2 Authentication</div>
        </div>
        <div class="tips_btn tips_btn_right" @click="gotoVerify">
          <div class="tips_g">Verify</div>
          <img src="@/assets/tips_right.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="select-container">
      <div class="select-container_flex select-container_flex_left">
        <div class="title">Currency</div>
        <div class="currency_country">
          <a-form :form="formSelect">
            <a-form-item>
              <a-input
                placeholder="00.00"
                :value="form.amount"
                size="large"
                type="number"
                @change="amountChange"
                v-decorator="[
                  'amount',
                  {
                    rules: [{ validator: checkAmount }],
                  },
                ]"
              >
                <a-select
                  class="spendClass"
                  slot="addonAfter"
                  :value="form.from_currency"
                  @change="fromCurrencyChange"
                >
                  <a-select-option
                    v-for="item of fromCurrencyOption"
                    :value="item.value"
                    :key="item.value"
                  >
                    <img :src="item.logo" class="selectIcon" /> {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-input>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="select-container_flex">
        <!-- <div class="title">Country</div>
        <div class="currency_country">
          <a-select
            slot="addonAfter"
            @change="countryCodeChange"
            size="large"
            class="currencySelect"
            placeholder="Please select country"
          >
            <a-select-option
              v-for="item of countryOption"
              :value="item.value"
              :key="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div> -->
      </div>
    </div>
    <div class="card-container">
      <div class="title">Credit/debit cards</div>
      <a-table
        :columns="CreditCardsColumns"
        :data-source="creditData"
        rowKey="completion"
        :pagination="false"
      >
        <span slot="logo" slot-scope="text">
          <img :src="text" alt="" /> Mastercard
        </span>
        <a-button
          :disabled="status !== 2"
          type="primary"
          slot="deposit"
          class="deposit-btn"
          @click="goDepositCredit"
          >Deposit</a-button
        >
      </a-table>
    </div>
    <div class="card-container">
      <div class="title">Bank transfers</div>
      <a-table
        :columns="BankCardsColumns"
        :data-source="BankCardsData"
        rowKey="completion"
        :pagination="false"
        :loading="bankTransferLoading"
      >
        <span slot="logo" slot-scope="text, record">
          <img :src="text" alt="" :style="{ width: '36px' }" />
          {{ record.deposit }}
        </span>
        <a-button
          :disabled="
            status !== 2 ||
            (text === 'ACH' && form.from_currency === 'EUR') ||
            (text === 'SEPA' && form.from_currency === 'USD')
          "
          slot="deposit"
          class="deposit-btn"
          type="primary"
          slot-scope="text"
          @click="goDepositBankTransfer(text)"
          >Deposit</a-button
        >
      </a-table>
    </div>
  </div>
</template>

<script>
import { createBankTransferNote } from '@/utils/api';
import { toCurrencyOption } from '@/utils/coinImg';
import countryList from '@/utils/Country.json';
import MasterCard from '@/assets/MasterCard.png';
import SepaIcon from '@/assets/sepa.png';
import ACHIcon from '@/assets/ACHIcon.png';
import USD from '@/assets/USD.svg';
import EUR from '@/assets/EUR.svg';

export default {
  name: 'Deposit',
  props: {
    status: {
      type: Number,
      require: true,
    },
    settingData: {
      type: Object,
      require: true,
    },
    changeDepositVal: {
      type: Function,
      require: false,
    },
    changeDepositDefaultMenu: {
      type: Function,
      require: false,
    },
    changeDefaultMenu: {
      type: Function,
      require: false,
    },
    changeDepositBankTransferMsg: {
      type: Function,
      require: false,
    },
  },
  data() {
    return {
      defaultStatus: null,
      loading: true,
      MasterCard,
      bankTransferLoading: false,
      formSelect: this.$form.createForm(this, { name: 'coordinated' }),
      form: {
        amount: '100',
        from_currency: 'EUR',
        to_currency: 'NEO',
        countryCode: '',
      },
      defaultDeposit: 'credit',
      note: null,
      bankData: null,
      toCurrencyOption: [],
      fromCurrencyOption: [
        { value: 'USD', label: 'USD', logo: USD },
        { value: 'EUR', label: 'EUR', logo: EUR },
      ],
      countryOption: [],
      CreditCards: [],
      BankCards: [],
      CreditCardsColumns: [
        {
          title: 'Card',
          dataIndex: 'logo',
          key: 'log',
          width: '170px',
          scopedSlots: { customRender: 'logo' },
        },
        {
          title: 'Fee%',
          dataIndex: 'feePercent',
          key: 'feePercent',
          scopedSlots: { customRender: 'feePercent' },
        },
        // {
        //   title: "Fee",
        //   dataIndex: "fee",
        //   key: "fee",
        //   scopedSlots: { customRender: "fee" },
        // },
        {
          title: 'Completion',
          dataIndex: 'completion',
          key: 'completion',
          scopedSlots: { customRender: 'completion' },
        },
        {
          title: 'Operation',
          dataIndex: 'deposit',
          key: 'deposit',
          fixed: 'right',
          scopedSlots: { customRender: 'deposit' },
        },
      ],
      BankCardsColumns: [
        {
          title: 'Transfer',
          dataIndex: 'logo',
          key: 'log',
          width: '170px',
          scopedSlots: { customRender: 'logo' },
        },
        {
          title: 'Fee%',
          dataIndex: 'feePercent',
          key: 'feePercent',
          scopedSlots: { customRender: 'feePercent' },
        },
        // {
        //   title: "Fee",
        //   dataIndex: "fee",
        //   key: "fee",
        //   scopedSlots: { customRender: "fee" },
        // },
        {
          title: 'Completion',
          dataIndex: 'completion',
          key: 'completion',
          scopedSlots: { customRender: 'completion' },
        },
        {
          title: 'Operation',
          dataIndex: 'deposit',
          key: 'deposit',
          fixed: 'right',
          scopedSlots: { customRender: 'deposit' },
        },
      ],
      creditData: [],
      BankCardsData: [],
      deepSettingData: {},
      card_min_amount: null,
      card_max_amount: null,
      sepa_min_amount: null,
      sepa_max_amount: null,
      optionsTest: { force: true },
    };
  },
  created() {
    this.defaultStatus = this.status;
    const initCountry = [];
    // eslint-disable-next-line array-callback-return
    countryList.map((item) => {
      initCountry.push({ value: item.countryCode, label: item.name_en });
    });
    this.countryOption = initCountry;
    this.toCurrencyOption = toCurrencyOption;
    this.creditData = [
      {
        logo: MasterCard,
        feePercent: `${this.settingData?.card_fee_rate * 100}%`,
        completion: '0.25 - 8 hours',
      },
    ];
    this.BankCardsData = [
      {
        logo: SepaIcon,
        feePercent: `${this.settingData?.sepa_fee_rate * 100}%`,
        completion: '0 - 2 working days',
        deposit: 'SEPA',
      },
      {
        logo: ACHIcon,
        feePercent: `${this.settingData?.ach_fee_rate * 100}%`,
        completion: '1 - 3 working days',
        deposit: 'ACH',
      },
    ];
    this.card_min_amount = this.settingData?.card_min_amount;
    this.card_max_amount = this.settingData?.card_max_amount;
    this.sepa_min_amount = this.settingData?.sepa_min_amount;
    this.sepa_max_amount = this.settingData?.sepa_max_amount;
  },
  watch: {
    settingData: {
      handler(newV) {
        this.deepSettingData = JSON.parse(JSON.stringify(newV));
        this.creditData = [
          {
            logo: MasterCard,
            feePercent: `${newV.card_fee_rate * 100}%`,
            completion: '0.25 - 8 hours',
          },
        ];
        this.BankCardsData = [
          {
            logo: SepaIcon,
            feePercent: `${newV.sepa_fee_rate * 100}%`,
            completion: '0 - 2 working days',
            deposit: 'SEPA',
          },
          {
            logo: ACHIcon,
            feePercent: `${this.settingData?.ach_fee_rate * 100}%`,
            completion: '1 - 3 working days',
            deposit: 'ACH',
          },
        ];
        this.card_min_amount = newV.card_min_amount;
        this.card_max_amount = newV.card_max_amount;
        this.sepa_min_amount = newV.sepa_min_amount;
        this.sepa_max_amount = newV.sepa_max_amount;
      },
      deep: true,
    },
    status: {
      handler(newV) {
        this.defaultStatus = newV;
      },
    },
  },
  methods: {
    gotoVerify() {
      this.changeDefaultMenu('identification');
    },
    checkAmount(rule, value, callback) {
      if (this.defaultDeposit === 'credit') {
        if (
          value >= Number(this.card_min_amount)
          && value <= Number(this.card_max_amount)
        ) {
          callback();
        } else {
          callback(
            `Please enter an amount between ${this.card_min_amount} and ${this.card_max_amount}`,
          );
        }
      } else if (
        value >= Number(this.sepa_min_amount)
        && value <= Number(this.sepa_max_amount)
      ) {
        callback();
      } else {
        callback(
          `please enter an amount between ${this.sepa_min_amount} and ${this.sepa_max_amount}`,
        );
      }
    },
    amountChange(value) {
      this.form.amount = value.target.value;
    },
    fromCurrencyChange(value) {
      this.form.from_currency = value;
    },
    countryCodeChange(value) {
      this.form.countryCode = value;
    },
    goDepositCredit() {
      this.defaultDeposit = 'credit';
      this.formSelect.validateFields(
        ['amount'],
        this.optionsTest,
        (err) => {
          if (!err) {
            this.changeDepositDefaultMenu('depositCreditCard');
            this.changeDepositVal(
              this.form.amount,
              this.form.from_currency,
              '',
            );
          }
        },
      );
    },
    async createBankTransferNoteRes(params) {
      return createBankTransferNote(params);
    },
    goDepositBankTransfer(DepositBankName) {
      this.defaultDeposit = 'bank';
      this.formSelect.validateFields(
        ['amount'],
        this.optionsTest,
        async (err) => {
          if (!err) {
            this.bankTransferLoading = true;
            const params = {
              currency: this.form.from_currency,
              amount: this.form.amount,
              deposit_bank: DepositBankName,
            };
            const [res] = await Promise.all([
              this.createBankTransferNoteRes(params),
            ]);
            this.bankTransferLoading = false;
            if (res.code === 0) {
              this.changeDepositDefaultMenu('depositBankTransfer');
              this.changeDepositVal(
                this.form.amount,
                this.form.from_currency,
                DepositBankName,
              );
              this.changeDepositBankTransferMsg(res.result, res.result.bank);
            }
          }
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.deposit_container {
  .tips_container {
    width: 100%;
    background: rgba(250, 179, 30, 0.1);
    border-radius: 20px;
    padding: 30px;
    margin-bottom: 30px;
    .tips {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ff8e00;
      margin-bottom: 30px;
    }
    .tips_btn_container {
      display: flex;
      justify-content: space-between;
      .tips_btn {
        display: flex;
      }
      .tips_btn_right {
        cursor: pointer;
      }
      .tips_w {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-left: 12px;
      }
      .tips_g {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #ff8e00;
        margin-right: 10px;
      }
    }
  }
  .select-container {
    display: flex;
    padding: 15px 0;
    /deep/.ant-select-selection {
      border-radius: 15px;
      height: 42px;
      line-height: 42px;
    }
    /deep/.ant-input-group {
      border: 1px solid #d9d9d9;
      border-radius: 15px;
      .ant-input {
        border: 0;
        border-radius: 15px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        font-weight: bold;
      }
    }
    /deep/.has-error {
      .ant-input-group {
        border-color: #f5222d;
      }
    }
    //input去除加减号样式
    /deep/ input::-webkit-outer-spin-button,
    /deep/ input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    /deep/ input[type="number"] {
      -moz-appearance: textfield;
    }
    /deep/.ant-input-group:hover {
      border: 1px solid #0045ff;
      box-shadow: 0 0 0 2px rgba(0, 69, 255, 0.06);
      transition: all 0.3s;
      .ant-select-arrow {
        color: #0045ff;
        margin-top: -4px;
      }
    }
    /deep/.ant-input-group:focus-within {
      border: 1px solid #0045ff;
      box-shadow: 0 0 0 2px rgba(0, 69, 255, 0.06);
      transition: all 0.3s;
      .ant-select-arrow {
        color: #0045ff;
        margin-top: -4px;
      }
    }
    /deep/.ant-input:hover {
      border: none;
    }
    /deep/.ant-input:focus {
      border: none;
      box-shadow: none;
      // box-shadow: 0 0 0 2px rgba(0, 69, 255, 0.06);
    }
    /deep/.ant-input-group-addon {
      border: none;
      border-radius: 15px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: #fff;
    }
    /deep/.ant-select-selection-selected-value {
      font-weight: bold;
    }
    /deep/.ant-select-lg .ant-select-selection--single {
      height: 42px;
    }
    /deep/ .ant-select-selection--single .ant-select-selection__rendered {
      height: 42px;
      line-height: 42px;
    }
    .select-container_flex {
      display: flex;
      flex: 1;
      flex-direction: column;
      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        mix-blend-mode: normal;
        opacity: 0.6;
        margin-bottom: 10px;
      }
      .currency_country {
        display: flex;
        width: 100%;
        .currencySelect {
          width: 100%;
        }
      }
    }
    .select-container_flex_left {
      margin-right: 24px;
    }
  }
  .spendClass {
    width: 150px;
  }
  .card-container {
    padding: 15px 0;
    .title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 16px;
    }
    .deposit-btn {
      border-radius: 8px;
      height: 40px;
      width: 120px;
    }
  }
}

.selectIcon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 10px;
}
</style>
