// eslint-disable-next-line import/prefer-default-export
export const RegCoin = {
  AAVE: /^(0x)[0-9A-Fa-f]{40}$/,
  BAT: /^(0x)[0-9A-Fa-f]{40}$/,
  AXS: /^(0x)[0-9A-Fa-f]{40}$/,
  BCH: process.env.NODE_ENV === 'development' ? /^(bchtest:)?(q|p)[a-z0-9]{41}$/ : /^(bitcoincash:)?(q|p)[a-z0-9]{41}$/,
  BTC: /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^(bc1)[0-9A-Za-z]{39,59}$/,
  'BUSD-BEP20': /^(0x)[0-9A-Fa-f]{40}$/,
  BDOG: /^(0x)[0-9A-Fa-f]{40}$/,
  CELO: /^(0x)[0-9A-Fa-f]{40}$/,
  CHZ: /^(0x)[0-9A-Fa-f]{40}$/,
  COMP: /^(0x)[0-9A-Fa-f]{40}$/,
  CUSD: /^(0x)[0-9A-Fa-f]{40}$/,
  DAI: /^(0x)[0-9A-Fa-f]{40}$/,
  DOGE: /^(D|A|9)[a-km-zA-HJ-NP-Z1-9]{33,34}$/,
  ENJ: /^(0x)[0-9A-Fa-f]{40}$/,
  EOS: /^[1-5a-z\\.]{1,12}$/,
  ETH: /^(0x)[0-9A-Fa-f]{40}$/,
  GAS: /^[N][A-Za-z0-9]{33}$/,
  KAVA: /^(kava1)[0-9a-z]{38}$/,
  KETH: /^(0x)[0-9A-Fa-f]{40}$/,
  KEY: /^(0x)[0-9A-Fa-f]{40}$/,
  LINK: /^(0x)[0-9A-Fa-f]{40}$/,
  LTC: /^(L|M|3)[A-Za-z0-9]{33}$|^(ltc1)[0-9A-Za-z]{39}$/,
  MANA: /^(0x)[0-9A-Fa-f]{40}$/,
  MATIC: /^(0x)[0-9A-Fa-f]{40}$/,
  MIOTA: /^[A-Z9]{90}$/,
  MKR: /^(0x)[0-9A-Fa-f]{40}$/,
  NEO: /^[N][A-Za-z0-9]{33}$/,
  bNEO: /^[N][A-Za-z0-9]{33}$/,
  FLM: /^[N][A-Za-z0-9]{33}$/,
  fUSDT: /^[N][A-Za-z0-9]{33}$/,
  OKB: /^(0x)[0-9A-Fa-f]{40}$/,
  OM: /^(0x)[0-9A-Fa-f]{40}$/,
  OMG: /^(0x)[0-9A-Fa-f]{40}$/,
  RFUEL: /^(0x)[0-9A-Fa-f]{40}$/,
  RINKETH: /^(0x)[0-9A-Fa-f]{40}$/,
  RUNE: /^(bnb1)[0-9a-z]{38}$/,
  SAND: /^(0x)[0-9A-Fa-f]{40}$/,
  SNX: /^(0x)[0-9A-Fa-f]{40}$/,
  SRM: /^(0x)[0-9A-Fa-f]{40}$/,
  STMX: /^(0x)[0-9A-Fa-f]{40}$/,
  TOMO: /^(0x)[0-9A-Fa-f]{40}$/,
  TRX: /^T[1-9A-HJ-NP-Za-km-z]{33}$/,
  TUSD: /^(0x)[0-9A-Fa-f]{40}$/,
  UNI: /^(0x)[0-9A-Fa-f]{40}$/,
  USDC: /^(0x)[0-9A-Fa-f]{40}$/,
  'USDT-ERC20': /^(0x)[0-9A-Fa-f]{40}$/,
  'USDT-TRC20': /^T[1-9A-HJ-NP-Za-km-z]{33}$/,
  UTK: /^(0x)[0-9A-Fa-f]{40}$/,
  WBTC: /^(0x)[0-9A-Fa-f]{40}$/,
  XLM: /^G[A-D]{1}[A-Z2-7]{54}$/,
  XRP: /^r[1-9A-HJ-NP-Za-km-z]{25,34}$/,
  ZRX: /^(0x)[0-9A-Fa-f]{40}$/,
  FLOW: /^(0x)[0-9A-Fa-f]{16}$/,
  FUSD: /^(0x)[0-9A-Fa-f]{16}$/,
};

export const regNumber = /^[1-9]\d*$/;
export const regEmail = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
export const regTwoPoint = /^(\d+)(.\d{0,2})?$/;
