<template>
  <div class="cardHolderContainer">
    <div class="cardContainer">
      <div class="container">
        <div class="historyContainer">
          <a-table :columns="columns" :data-source="data" rowKey="id">
            <span
              slot="to_address"
              slot-scope="text"
              class="address"
              v-clipboard:copy="text"
              v-clipboard:success="onAddressCopy"
              v-clipboard:error="onAddressError"
            >
              <span v-if="text">
                {{ text.substring(0, 4) }}...{{
                  text.substring(text.length - 4, text.length)
                }}
              </span>
              <span v-else>-</span>
            </span>
            <span slot="available_amount" slot-scope="text">
              <span v-if="text">
                {{ text.toFixed(2) }}
              </span>
              <span v-else> - </span>
            </span>
            <span slot="status" slot-scope="text">
              <span v-if="text === 0">Pending</span>
              <span v-else-if="text === 1" class="success">Success</span>
              <span v-else-if="text === 2" class="fail">Failed</span>
              <span v-else>{{ text }}</span>
            </span>
            <span slot="create_time" slot-scope="text">
              {{ $moment(text).format("YYYY-MM-DD HH:mm:ss") }}
            </span>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'History',
  props: {
    data: {
      type: Array,
      require: false,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'Order No.',
          dataIndex: 'order_no',
          key: 'order_no',
          scopedSlots: { customRender: 'order_no' },
        },
        {
          title: 'Available Amount',
          dataIndex: 'available_amount',
          key: 'available_amount',
          scopedSlots: { customRender: 'available_amount' },
        },
        { title: 'Amount', dataIndex: 'amount', key: 'amount' },
        {
          title: 'Destination Address',
          dataIndex: 'to_address',
          key: 'to_address',
          scopedSlots: { customRender: 'to_address' },
        },
        {
          title: 'Order status',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
        },
        { title: 'Reason', dataIndex: 'reason', key: 'reason' },
        { title: 'Transaction Hash', dataIndex: 'tx_hash', key: 'tx_hash' },
        {
          title: 'Time Created',
          dataIndex: 'create_time',
          key: 'create_time',
          scopedSlots: { customRender: 'create_time' },
        },
      ],
    };
  },
  methods: {
    onAddressCopy() {
      this.$message.success('Copy Address Success');
    },
    onAddressError() {
      this.$message.error('Copy Address Failed');
    },
  },
};
</script>

<style lang="scss" scoped>
.cardHolderContainer {
  width: 972px;
  @media (max-width: 750px) {
    width: 100%;
  }
  .cardContainer {
    width: 100%;
    .container {
      max-width: 904px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 48px;
      // box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
      @media (max-width: 750px) {
        margin-top: 10px;
      }
      .historyContainer {
        h2 {
          margin-left: 16px;
        }
        margin-top: 24px;
      }
    }
  }
}
.searchContent {
  margin: 16px;
  margin-top: 0;
  padding-top: 16px;
  .txIdInput {
    display: inline-block;
    width: 100px;
    margin-right: 20px;
  }
  .searchHistory {
    background: #0045ff;
    color: #fff;
    margin-left: 20px;
  }
  @media (max-width: 750px) {
    display: flex;
    /deep/ .ant-calendar-picker {
      flex: 3;
    }
    .searchHistory {
      flex: 1;
    }
  }
}
/deep/ .ant-table-wrapper {
  width: 100%;
  overflow-x: auto;
  // display: -webkit-box;
  // -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}
.success {
  color: #52c41a;
}
.fail {
  color: red;
}
.orderNo {
  cursor: pointer;
  color: #0045ff;
}
.address {
  cursor: pointer;
  color: #0045ff;
}
.transaction_hash {
  cursor: pointer;
}
.buyCryptoHistoryModal {
  /deep/.ant-modal-body {
    padding: 0;
  }
}
.success {
  color: #52c41a;
}
.fail {
  color: red;
}
.address {
  cursor: pointer;
  color: #0045ff;
}
</style>
