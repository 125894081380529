<template>
  <div class="deposit_bank_card_container">
    <div class="deposit_bank_card_container_content">
      <div class="back_container" @click="backBtn">
        <a-icon type="arrow-left" />
      </div>
      <div class="title">
        Funding Your Account with Bank Transfer ({{ depositBankName }})
      </div>
    </div>
    <div class="deposit_bank_container">
      <div class="tips_container">
        <div class="title_tips">
          <img src="@/assets/tips-ts.svg" alt="tips" />
          <span>{{ depositBankName }} Transfer Notice:</span>
        </div>
        <div v-if="depositBankName === 'SEPA'">
          <div class="tips">
            · Funds must be transferred from bank accounts under your name.
          </div>
          <div class="tips">
            · Only EUR Transfers via SEPA / SEPA Instant are accepted for this
            account (transfers via SWIFT will not be accepted).
          </div>
          <div class="tips">
            · SEPA Transfers normally arrive within 0-2 working days.
          </div>
        </div>
        <div v-if="depositBankName === 'ACH'">
          <div class="tips">
            · Individual users can only deposit USD for now.
          </div>
        </div>
      </div>
      <div v-if="depositBankName === 'SEPA'">
        <div class="info_title">Order information</div>
        <div class="deposit_bank_msg_container">
          <div class="deposit_bank_msg_container_left">
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Order ID</div>
              <div class="line_right">{{ order_id }}</div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Wallet ID</div>
              <div class="line_right">{{ address }}</div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Amount</div>
              <div class="line_right">{{ amount }} {{ from_currency }}</div>
            </div>
          </div>
        </div>
        <div class="attention_tips">
          Please transfer the fund within 4 hours. Send the order reference code
          with the transaction so we can know it’s from you.
        </div>
        <div class="info_title">Bank information</div>
        <div class="deposit_bank_msg_container">
          <div class="deposit_bank_msg_container_left">
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Account Name</div>
              <div class="line_right">
                {{ account_name
                }}<span
                  class="copyBtn"
                  v-clipboard:copy="account_name"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <a-icon class="copyAddress" type="copy" theme="twoTone" />
                </span>
              </div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">IBAN</div>
              <div class="line_right">
                {{ account_id
                }}<span
                  class="copyBtn"
                  v-clipboard:copy="account_id"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <a-icon class="copyAddress" type="copy" theme="twoTone" />
                </span>
              </div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Bank Name</div>
              <div class="line_right">
                {{ bank_name
                }}<span
                  class="copyBtn"
                  v-clipboard:copy="bank_name"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <a-icon class="copyAddress" type="copy" theme="twoTone" />
                </span>
              </div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">BIC(SWIFT Code)</div>
              <div class="line_right">
                {{ bic
                }}<span
                  class="copyBtn"
                  v-clipboard:copy="bic"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <a-icon class="copyAddress" type="copy" theme="twoTone" />
                </span>
              </div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Bank Address</div>
              <div class="line_right">
                {{ bank_address
                }}<span
                  class="copyBtn"
                  v-clipboard:copy="bank_address"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <a-icon class="copyAddress" type="copy" theme="twoTone" />
                </span>
              </div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">
                <div>Reference Code</div>
              </div>
              <div class="line_right">
                {{ postscript }}
                <span
                  class="copyBtn"
                  v-clipboard:copy="postscript"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <a-icon class="copyAddress" type="copy" theme="twoTone" />
                </span>
              </div>
            </div>
            <div class="rateTipsLeft">
              <img src="@/assets/jinggao.svg" alt="jinggao" />
              <span class="rateTipsTitle rateTipsTitleLeft">
                Reference code MUST be written correctly to accept your
                deposit.</span
              >
            </div>
          </div>
        </div>
      </div>
      <div v-if="depositBankName === 'ACH'">
        <div class="attention_tips">
          To initiate a ACH Transfer , sign in to your online bank account,
          locate the transfer menu, and enter the information below.
        </div>
        <div class="deposit_bank_msg_container">
          <div class="deposit_bank_msg_container_left">
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Account Name</div>
              <div class="line_right">
                {{ account_name
                }}<span
                  class="copyBtn"
                  v-clipboard:copy="account_name"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <a-icon class="copyAddress" type="copy" theme="twoTone" />
                </span>
              </div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Bank Account Number</div>
              <div class="line_right">
                {{ account_num
                }}<span
                  class="copyBtn"
                  v-clipboard:copy="account_num"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <a-icon class="copyAddress" type="copy" theme="twoTone" />
                </span>
              </div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Bank Name</div>
              <div class="line_right">
                {{ bank_name
                }}<span
                  class="copyBtn"
                  v-clipboard:copy="bank_name"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <a-icon class="copyAddress" type="copy" theme="twoTone" />
                </span>
              </div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">BIC(SWIFT Code)</div>
              <div class="line_right">
                {{ bic
                }}<span
                  class="copyBtn"
                  v-clipboard:copy="bic"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <a-icon class="copyAddress" type="copy" theme="twoTone" />
                </span>
              </div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Bank Address</div>
              <div class="line_right">
                {{ bank_address
                }}<span
                  class="copyBtn"
                  v-clipboard:copy="bank_address"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <a-icon class="copyAddress" type="copy" theme="twoTone" />
                </span>
              </div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">
                <div>Bank Code</div>
              </div>
              <div class="line_right">
                {{ account_desc }}
                <span
                  class="copyBtn"
                  v-clipboard:copy="account_desc"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <a-icon class="copyAddress" type="copy" theme="twoTone" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="attention_tips">
          ACH Verification - some banks may ask you to send 2 test transfers,
          and tell them what the exact amounts are {e.g: $0.02 and $0.54) You'll
          need to use these numbers to verify the ACH setting at your banking
          system
        </div>
        <div class="rateTipsLeft">
          <img src="@/assets/jinggao.svg" alt="jinggao" />
          <span class="rateTipsTitle rateTipsTitleLeft">
            Please Email support@inst.money and ask for the exact amounts, if
            you didn't receive an Email notification.</span
          >
        </div>
      </div>
      <div class="deposit_bank_bottom">
        <div class="deposit_bank_bottom_btn">
          <a-button type="primary" class="history_btn" @click="gotoHistory"
            >View History</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NewFooter from '@/components/NewFooter.vue';

export default {
  name: 'DepositBankTransfer',
  props: {
    CardData: {
      type: Array,
      request: true,
    },
    changeDepositDefaultMenu: {
      type: Function,
      require: true,
    },
    bankTransferNote: {
      type: Object,
      require: true,
    },
    bankTransferData: {
      type: Object,
      require: false,
    },
    from_currency: {
      type: String,
      require: true,
    },
    amount: {
      type: String,
      request: true,
    },
    depositBankName: {
      type: String,
      request: true,
    },
  },
  components: {
    // NewFooter,
  },
  data() {
    return {
      address: '',
      copyText: '1234',
      checked: false,
      account_id: '',
      account_name: '',
      bic: '',
      bank_address: '',
      bank_name: '',
      postscript: '',
      order_id: '',
      account_num: '',
      account_desc: '',
    };
  },
  created() {
    this.CardData.forEach((item) => {
      if (item.currency === this.from_currency) {
        this.address = item.address;
      }
    });
    this.account_id = this.bankTransferData.account_id;
    this.account_name = this.bankTransferData.account_name;
    this.bic = this.bankTransferData.bic;
    this.bank_address = this.bankTransferData.bank_address;
    this.bank_name = this.bankTransferData.bank_name;
    this.account_desc = this.bankTransferData.account_desc;
    this.postscript = this.bankTransferNote.postscript;
    this.order_id = this.bankTransferNote.tx_id;
    this.account_num = this.bankTransferData.account_num;
  },
  methods: {
    onCopy() {
      this.$message.success('Copy Success');
    },
    onError() {
      this.$message.error('Copy Failed');
    },
    backBtn() {
      this.changeDepositDefaultMenu('showTab');
    },
    checkBoxChange() {
      this.checked = !this.checked;
    },
    gotoHistory() {
      this.changeDepositDefaultMenu('history');
    },
  },
};
</script>

<style lang="scss" scoped>
.deposit_bank_card_container {
  @media (max-width: 960px) {
    width: 100%;
  }
  .deposit_bank_card_container_content {
    background: #fff;
    border-radius: 4px;
    position: relative;
    .back_container {
      position: absolute;
      font-size: 18px;
      cursor: pointer;
      top: 12px;
    }
    .title {
      font-family: SamsungSharpSans-Bold;
      font-weight: bold;
      font-size: 24px;
      line-height: 50px;
      opacity: 0.8;
      color: #000;
      margin-left: 28px;
    }
  }
  .deposit_bank_container {
    margin-top: 24px;
    padding-left: 28px;
    .tips_container {
      width: 100%;
      border-radius: 20px;
      padding: 30px;
      margin: 40px 0;
      background: rgba(250, 179, 30, 0.1);
      color: #ff8e00;
      .title_tips {
        display: flex;
        margin-bottom: 20px;
        font-size: 0;
        span {
          margin-left: 10px;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #ff8e00;
        }
      }
      .tips {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin: 5px;
      }
    }
    .info_title {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 10px;
    }
    .info_tips_icon {
      width: 8px;
      margin: 0px 0 4px;
    }
    .deposit_bank_msg_container {
      display: flex;
      width: 100%;
      border-radius: 8px;
      padding: 20px 30px;
      margin: 0px 0 20px;
      background: rgba(0, 69, 255, 0.03);
      .deposit_bank_msg_container_left {
        display: flex;
        flex: 1;
        flex-direction: column;
        .deposit_bank_msg_container_line {
          display: flex;
          flex: 1;
          justify-content: space-between;
          padding: 10px 0;
          .line_left {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }
          .line_right {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            text-align: right;
            .copyBtn {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .attention_tips {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #ff8e00;
      margin: 12px 0 40px;
    }
    .attention_tips_tips {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 20px;
    }
    .deposit_bank_steps {
      margin: 40px 0 20px;
      padding: 20px 30px;
      background: rgba(0, 69, 255, 0.03);
      border-radius: 8px;
      .deposit_bank_steps_line {
        margin: 10px 0;
        @media (max-width: 960px) {
          display: flex;
        }
        img {
          width: 32px;
        }
        padding: 10px 0;
        span {
          margin-left: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #000000;
        }
      }
    }
    .note_container {
      display: flex;
      .label {
        font-weight: 400;
        font-size: 16px;
        line-height: 48px;
        color: #000000;
        opacity: 0.6;
        margin-right: 20px;
      }
      .value {
        flex: 1;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        color: #000000;
      }
    }
    .deposit_bank_bottom {
      text-align: center;
      margin-top: 40px;
      .history_btn {
        border-radius: 8px;
        height: 48px;
        width: 400px;
        margin-bottom: 20px;
        @media (max-width: 960px) {
          width: 100%;
        }
      }
      .deposit_bank_bottom_link {
        .link {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          margin-left: 10px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
  .rateTipsLeft {
    color: #000;
    font-size: 14px;
    text-align: left;
    background: rgba(230, 154, 58, 0.12);
    display: flex;
    border-radius: 5px;
    padding: 10px;
    img {
      width: 24px;
      height: 24px;
    }
    .rateTipsTitleLeft {
      opacity: 0.8;
      margin-left: 4px;
    }
  }
}
</style>
