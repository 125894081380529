<template>
  <div class="wrapper_buyCrypto">
    <!-- <div v-show="step === 3">
      <div class="success_result_container">
        <img src="@/assets/success_icon.svg" alt="" />
        <div class="success_result">The funds have successfully sent</div>
      </div>
      <div class="step_confirm_container">
        <div class="details_column">
          <div class="details_column_val">
            <div class="title">From Wallet</div>
            <div class="value">{{ address }}</div>
          </div>
          <div class="details_column_val details_column_val_last">
            <div class="title">Amount of transfer</div>
            <div class="value">{{ amount }} {{ currency }}</div>
          </div>
        </div>
        <div class="details_column">
          <div class="details_column_val">
            <div class="title">
              To {{ method === "email" ? "Email" : "Wallet" }}
            </div>
            <div class="value">{{ email }}</div>
          </div>
          <div class="details_column_val details_column_val_last">
            <div class="title">Amount to be credited</div>
            <div class="value">{{ amount }} {{ currency }}</div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="container">
      <div class="wrapper-left" v-if="showStep1 === true">
        <div class="wrapper-left-item wrapper-left-item-first">
          <div class="select-label">I want to spend</div>
          <div class="select-container-top">
            <a-input
              placeholder="00.00"
              :value="form.amount"
              size="large"
              type="number"
              @change="amountChange"
              :disabled="!selectDisabled"
            >
              <a-select
                slot="addonAfter"
                :value="form.from_currency"
                @change="fromCurrencyChange"
                class="currencySelect"
              >
                <a-select-option
                  v-for="item of fromCurrencyOption"
                  :value="item.value"
                  :key="item.value"
                >
                  <img :src="item.logo" class="selectIcon" />
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-input>
          </div>
        </div>
        <div class="errorTipsContent">
          <div v-if="showMaxErrorTips">
            <div class="triangle" />
            <div class="maxTips">
              Maximum allowed: {{ numeral(maxAmount).format("0,0") }}
              {{ form.from_currency }}
            </div>
          </div>
          <div v-if="showMinErrorTips">
            <div class="triangle" />
            <div class="maxTips">
              Minimum allowed: {{ numeral(minAmount).format("0,0") }}
              {{ form.from_currency }}
            </div>
          </div>
        </div>
        <div class="wrapper-left-item">
          <div class="select-label">I want to buy</div>
          <div class="select-container-top">
            <a-input
              :placeholder="buyCurrencyPlaceholder"
              :value="form.approximatelyAmount"
              type="number"
              size="large"
              :disabled="selectDisabled"
              @change="toCurrencyAmountChange"
            >
              <a-select
                slot="addonAfter"
                :value="form.to_currency"
                @change="toCurrencyChange"
                class="currencySelect"
              >
                <a-select-option
                  v-for="item of toCurrencyOption"
                  :value="item.value"
                  :key="item.value"
                >
                  <img :src="item.logo" class="selectIcon" />
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-input>
          </div>
        </div>
        <div class="rateTipsLeft" v-if="form.to_currency === 'NEO'">
          <img src="@/assets/jinggao.svg" alt="jinggao" />
          <span class="rateTipsTitle rateTipsTitleLeft"
            >NEO is an indivisible currency. It means that it should represent a
            whole number.</span
          >
        </div>
        <div class="wrapper-left-item wrapper-left-item-address">
          <div class="select-label">Your Wallet Address</div>
          <div class="select-container-top">
            <a-input
              class="g-input"
              placeholder="Destination address"
              :value="form.address"
              size="large"
              @change="toAddressChange"
            >
            </a-input>
            <p class="addressTips" v-if="showAddressTips">
              Please enter correct wallet address
            </p>
          </div>
        </div>
        <!-- <div class="buyWarning">
          <div class="warningTop">
            <img src="@/assets/warningImg.svg" alt="warning" />
            Attention! Beware of scams!
          </div>
          <div class="warningBottom">
            Please make sure you have the credentials of the wallet you entered,
            and you have control over the funds in it. Do not enter an address
            that is given to you.
          </div>
        </div> -->
        <div class="wrapper-left-item">
          <div class="select-label">Pay With</div>
          <div class="select-container-top">
            <a-radio-group @change="selectChange" class="pay_with_sty">
              <a-list
                class="demo-loadmore-list"
                item-layout="horizontal"
                :data-source="relWallet"
                v-if="relWallet.length > 0"
              >
                <a-list-item
                  class="renderItem renderItem_wallet"
                  slot="renderItem"
                  slot-scope=""
                >
                  <a-list-item-meta>
                    <div class="renderItemTitle" slot="title">
                      <img src="@/assets/walletCircle.svg" alt="" />
                      Wallet
                    </div>
                  </a-list-item-meta>
                  <a-radio value="wallet">
                    <a slot="actions"></a>
                  </a-radio>
                </a-list-item>
              </a-list>
              <div class="wallet_bottom" v-if="chooseWallet">
                <div class="wallet_left">{{ relWallet[0].address }}</div>
                <div class="wallet_right">
                  <div class="wallet_right_balance">
                    {{ relWallet[0].balance }} {{ form.from_currency }}
                  </div>
                  <!-- <div class="wallet_right_deposit" @click="goWallet">
                    Deposit
                  </div> -->
                </div>
              </div>
              <div v-if="form.from_currency === 'EUR'" class="sepa_container">
                <a-list
                  class="demo-loadmore-list demo-loadmore-list-sepa"
                  item-layout="horizontal"
                  :data-source="sepaData"
                >
                  <a-list-item
                    class="renderItem"
                    slot="renderItem"
                    slot-scope="item"
                  >
                    <a-list-item-meta>
                      <div class="renderItemTitle" slot="title">
                        <img src="@/assets/sepaCircle.svg" alt="" />
                        {{ item.label }}
                      </div>
                    </a-list-item-meta>
                    <a-radio :value="item.value">
                      <a slot="actions"></a>
                    </a-radio>
                  </a-list-item>
                </a-list>
                <div class="bind_list_tips" v-if="chooseSepa">
                  <img src="@/assets/tipsIcon.svg" alt="" />
                  <div class="bind_list_title">
                    You could deposit funds in the wallet with your selected
                    method before purchasing.
                  </div>
                </div>
              </div>
            </a-radio-group>
          </div>
        </div>
        <a-button
          class="deposit-btn"
          type="primary"
          @click="submit"
          :disabled="
            !verifyAddress ||
            showMaxErrorTips ||
            showMinErrorTips ||
            !(chooseSepa || chooseWallet)
          "
          >Continue</a-button
        >
      </div>
      <div class="wrapper-left wrapper-wallet" v-if="showStep2 === true">
        <img class="successIcon" src="@/assets/Success.png" alt="img" />
        <h3 class="successTitle">Payment Successful</h3>
        <div class="successTips">Awesome. Your payment was successful.</div>
        <a-button class="goToBtnGot" type="primary" @click="changeSteps">
          Got it
        </a-button>
      </div>
      <div class="wrapper-left wrapper-wallet" v-if="showStep3 === true">
        <img
          class="successIcon"
          src="@/assets/WaittingIcon.svg"
          alt="img"
        />
        <h3 class="waitTips successTitle">Payment processing</h3>
        <div class="successTips" @click="openSepaA">
          Please click order
          <span class="g-order">{{ speaData.order_no }}</span> to view the
          payment information
        </div>
        <a-button class="goToBtnGot" type="primary" @click="changeSteps">
          Got it
        </a-button>
      </div>
    </div>
    <a-modal
      v-model="verifyGoogleVisible"
      title="Verify"
      :footer="null"
      :maskClosable="false"
      centered
    >
      <a-form :form="verifyGoogleAuthForm" @submit="handleVerifyGoogleSubmit">
        <a-form-item
          label="Google Authenticator Verification Code"
          v-if="gaStatus"
        >
          <a-input
            class="google_input"
            placeholder="Google Authenticator Verification Code"
            size="large"
            v-decorator="[
              'google_code',
              {
                rules: [
                  {
                    required: true,
                    len: 6,
                    message:
                      'Please enter the six-digit code from Google Authenticator!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Email Verification Code">
          <a-row :gutter="8">
            <a-col :span="20">
              <a-input
                size="large"
                v-decorator="[
                  'email-code',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter the captcha you got',
                      },
                    ],
                  },
                ]"
              />
            </a-col>
            <a-col :span="4">
              <a-button
                class="goToBtn sendMail"
                type="primary"
                @click="getCaptcha"
                :loading="sendEmailLoading"
                :disabled="sendEmailDisabled"
                >{{ sendEmailText }}</a-button
              >
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item class="confirmContainer">
          <a-button class="goToBtn" html-type="submit" :loading="sepaLoading">
            Confirm
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      v-model="speaModalVisible"
      title="Funding Your Account with Bank Transfer(SPEA)"
      centered
      :footer="null"
      width="450"
      :maskClosable="false"
    >
      <div class="info_title">Order information</div>
      <div class="deposit_bank_msg_container">
        <div class="deposit_bank_msg_container_left">
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Order No.</div>
            <div class="line_right">{{ speaData.order_no }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Wallet Transaction ID</div>
            <div class="line_right">{{ speaData.external_tx_id }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Wallet ID</div>
            <div class="line_right">{{ speaData.address }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Amount</div>
            <div class="line_right">
              {{ speaData.amount }} {{ speaData.currency }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Reference Code</div>
            <div class="line_right">
              {{ speaData.postscript }}
              <span
                class="copyBtn"
                v-clipboard:copy="speaData.postscript"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="attention_tips">
        Please transfer the fund within 4 hours. Send the order reference code
        with the transaction so we can know it’s from you.
      </div>
      <div class="attention_tips_tips">
        Make a payment to Inst using the following bank account details .
      </div>
      <div class="info_title">Bank information</div>
      <div class="deposit_bank_msg_container">
        <div class="deposit_bank_msg_container_left">
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Account Name</div>
            <div class="line_right">
              {{ speaData.bank.account_name
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.account_name"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">IBAN</div>
            <div class="line_right">
              {{ speaData.bank.account_id
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.account_id"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Bank Name</div>
            <div class="line_right">
              {{ speaData.bank.bank_name
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.bank_name"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">BIC(SWIFT Code)</div>
            <div class="line_right">
              {{ speaData.bank.bic
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.bic"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Bank Address</div>
            <div class="line_right">
              {{ speaData.bank.bank_address
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.bank_address"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import numeral from 'numeral';
import { fromCurrencyNEOOption } from '@/utils/coinImg';
import {
  getGaStatus,
  getRates,
  getCustCrypto,
  getUserWallet,
  fastBuyCurrencyNew,
  getWalletRate,
  sendEmail,
} from '@/utils/api';
import { authAfter, neoAuthBefore } from '@/utils/config';
import { RegCoin } from '@/utils/reg';
import NEO from '@/assets/NEO.png';
import GAS from '@/assets/gasImg.svg';
import USDT from '@/assets/USDT.png';
import FLM from '@/assets/FLM.png';
import BNEO from '@/assets/BNEO.png';
import USD from '@/assets/USD.svg';
import EUR from '@/assets/EUR.svg';
import GBP from '@/assets/GBP.svg';
import ETH from '@/assets/ETH.svg';
import BTC from '@/assets/BTC.svg';

export default {
  name: 'SendFunds',
  components: {},
  props: {
    changeDefaultMenu: {
      type: Function,
      require: false,
    },
  },
  data() {
    return {
      verifyGoogleAuthForm: this.$form.createForm(this, {
        name: 'verifyGoogleAuthFormName',
      }),
      form: {
        amount: '',
        from_currency: 'EUR',
        approximatelyAmount: '',
        to_currency: 'NEO',
        detailApproximatelyAmount: '',
        address: '',
      },
      numeral,
      NEO,
      ETH,
      GAS,
      USDT,
      FLM,
      BNEO,
      USD,
      EUR,
      GBP,
      network_fee: '',
      wallet_rate: '',
      sepa_rate: '',
      rate: '',
      fromCurrencyOption: [],
      toCurrencyOption: [],
      selectDisabled: true,
      maxAmount: '',
      minAmount: '',
      showMaxErrorTips: false,
      showMinErrorTips: false,
      buyCurrencyPlaceholder: '00.00',
      wallets: [],
      sepaData: [{ label: 'SEPA Bank Transfer', value: 'sepa' }],
      chooseSepa: false,
      chooseWallet: false,
      showAddressTips: false,
      verifyGoogleVisible: false,
      speaData: { bank: {} },
      showStep1: true,
      showStep2: false,
      showStep3: false,
      speaModalVisible: false,
      relBalance: '',
      relWallet: [],
      sepaLoading: false,
      gaStatus: false,
      sendEmailLoading: false,
      sendEmailDisabled: false,
      mail_code_token: '',
      sendEmailText: 'Send',
      timer: null,
      defaultTime: 60,
    };
  },
  computed: {
    verifyAddress() {
      return RegCoin[this.form.to_currency].test(this.form.address);
    },
  },
  created() {
    this.limit_amount = localStorage.getItem('limit_amount') || '--';
    this.fromCurrencyOption = fromCurrencyNEOOption;
    const mail = localStorage.getItem('inst_mail');
    const authorization = `${neoAuthBefore}${Date.now()}${authAfter}`;
    const toOption = [];
    const params = {
      from_currency: this.form.from_currency,
      to_crypto: this.form.to_currency,
    };
    this.getRate(params);
    getCustCrypto(authorization).then((res) => {
      if (res.code === 0) {
        res.result.forEach((item) => {
          if (item === 'NEO') {
            toOption.push({ value: item, label: 'NEO(N3)', logo: NEO });
          } else if (item === 'GAS') {
            toOption.push({ value: item, label: 'GAS(N3)', logo: GAS });
          } else if (item === 'USDT-TRC20') {
            toOption.push({ value: item, label: 'USDT-TRC20', logo: USDT });
          } else if (item === 'bNEO') {
            toOption.push({ value: item, label: 'bNEO', logo: BNEO });
          } else if (item === 'FLM') {
            toOption.push({ value: item, label: 'FLM', logo: FLM });
          } else if (item === 'fUSDT') {
            toOption.push({ value: item, label: 'fUSDT', logo: USDT });
          } else if (item === 'BTC') {
            toOption.push({ value: item, label: 'BTC', logo: BTC });
          } else if (item === 'ETH') {
            toOption.push({ value: item, label: 'ETH', logo: ETH });
          } else {
            toOption.push({ value: item, label: item, logo: '' });
          }
        });
        this.toCurrencyOption = toOption;
        this.initParams(res.result);
      }
    });
    getUserWallet().then((res) => {
      if (res.code === 0) {
        this.wallets = res.result.wallets;
        res.result.wallets.forEach((item) => {
          if (this.form.from_currency === item.currency) {
            this.relWallet = [item];
            this.relBalance = item.balance;
          }
        });
      }
    });
    getGaStatus(mail).then((res) => {
      this.loading = false;
      if (res.code === 0) {
        this.gaStatus = res.result.ga_enable;
      }
    });
  },
  methods: {
    onCopy() {
      this.$message.success('Copy Success');
    },
    onError() {
      this.$message.error('Copy Failed');
    },
    getRate(params) {
      getWalletRate(params).then((response) => {
        const res = response.data;
        if (res.code === 0) {
          this.wallet_rate = res.result.wallet_rate;
          this.sepa_rate = res.result.sepa_rate;
        }
      });
    },
    initParams(cryptoParams) {
      if (cryptoParams.indexOf('NEO') !== -1) {
        this.selectDisabled = false;
        this.form.approximatelyAmount = '10';
        this.buyCurrencyPlaceholder = '0';
        this.detailApproximatelyAmount = '10';
      } else {
        [this.form.to_currency] = cryptoParams;
        this.selectDisabled = true;
        this.buyCurrencyPlaceholder = '00.00';
        this.form.amount = '100.00';
        this.form.approximatelyAmount = '00.00';
        this.form.detailApproximatelyAmount = '00.00';
      }
      this.initRate();
    },
    initRate() {
      const params = {
        from_currency: this.form.from_currency,
        to_crypto: this.form.to_currency,
      };
      const authorization = `${neoAuthBefore}${Date.now()}${authAfter}`;
      this.loading = true;
      getRates(params, authorization)
        .then((response) => {
          this.loading = false;
          const res = response.data;
          if (res.code === 0) {
            if (res.result.rates.length) {
              let indexOfMax = 0;
              res.result.rates.reduce(
                // eslint-disable-next-line no-return-assign
                (a, c, i) => (c.priority > a ? ((indexOfMax = i), c.priority) : a),
                0,
              );
              // 网络费
              this.network_fee = res.result.network_fee;
              // 币种汇率
              this.rate = res.result.rates[indexOfMax].rate;
              // 币种最大值
              this.maxAmount = res.result.rates[indexOfMax].max_amount;
              // 币种最小值
              this.minAmount = res.result.rates[indexOfMax].min_amount;
              // 通道名称
              this.channel_name = res.result.rates[indexOfMax].channel_name;
              if (this.form.to_currency === 'NEO') {
                const amounts = BigNumber(res.result.rates[indexOfMax].rate)
                  .multipliedBy(this.form.approximatelyAmount)
                  .plus(
                    BigNumber(res.result.rates[indexOfMax].rate).multipliedBy(
                      res.result.network_fee,
                    ),
                  )
                  .toFixed(2);
                this.form.amount = amounts;
                if (amounts > res.result.rates[indexOfMax].max_amount) {
                  this.showMaxErrorTips = true;
                } else {
                  this.showMaxErrorTips = false;
                }
                if (amounts < res.result.rates[indexOfMax].min_amount) {
                  this.showMinErrorTips = true;
                } else {
                  this.showMinErrorTips = false;
                }
              } else {
                const approAmounts = this.form.amount
                  ? `${BigNumber(this.form.amount)
                    .minus(
                      BigNumber(
                        res.result.rates[indexOfMax].rate,
                      ).multipliedBy(res.result.network_fee),
                    )
                    .dividedBy(BigNumber(res.result.rates[indexOfMax].rate))
                    .toFixed(4)}`
                  : '';
                this.form.approximatelyAmount = approAmounts;
                this.form.detailApproximatelyAmount = approAmounts;
                if (
                  this.form.amount > res.result.rates[indexOfMax].max_amount
                ) {
                  this.showMaxErrorTips = true;
                } else {
                  this.showMaxErrorTips = false;
                }
                if (
                  this.form.amount < res.result.rates[indexOfMax].min_amount
                ) {
                  this.showMinErrorTips = true;
                } else {
                  this.showMinErrorTips = false;
                }
              }
            } else {
              this.$message.error('currency not support');
            }
          } else {
            this.$message.error(`${res.msg}`);
          }
        })
        .catch((err) => {
          this.$message.error(`${err}`);
          this.loading = false;
        });
    },
    // I want to Spend USD
    amountChange(value) {
      this.form.amount = value.target.value;
      this.form.approximatelyAmount = value.target.value
        ? `${BigNumber(value.target.value)
          .dividedBy(BigNumber(this.rate))
          .toFixed(4)}`
        : '';
      this.form.detailApproximatelyAmount = value.target.value
        ? `${BigNumber(value.target.value)
          .dividedBy(BigNumber(this.rate))
          .toFixed(4)}`
        : '';
      if (new BigNumber(value.target.value) > this.maxAmount) {
        this.showMaxErrorTips = true;
      } else {
        this.showMaxErrorTips = false;
      }
      if (new BigNumber(value.target.value) < this.minAmount) {
        this.showMinErrorTips = true;
      } else {
        this.showMinErrorTips = false;
      }
    },
    fromCurrencyChange(value) {
      this.form.from_currency = value;
      this.wallets.forEach((item) => {
        if (item.currency === value) {
          this.relBalance = item.balance;
          this.relWallet = [item];
        }
      });
      this.initRate();
      const params = {
        from_currency: value,
        to_crypto: this.form.to_currency,
      };
      this.getRate(params);
    },
    toCurrencyChange(value) {
      this.form.to_currency = value;
      if (value === 'NEO') {
        this.selectDisabled = false;
      } else {
        this.selectDisabled = true;
      }
      if (
        RegCoin[this.form.to_currency].test(this.form.address)
        || !this.form.address
      ) {
        this.showAddressTips = false;
      } else {
        this.showAddressTips = true;
      }
      this.form.approximatelyAmount = 10;
      this.form.detailApproximatelyAmount = 10;
      this.initRate();
      const params = {
        from_currency: this.form.from_currency,
        to_crypto: value,
      };
      this.getRate(params);
    },
    // I want to buy NEO
    toCurrencyAmountChange(value) {
      this.form.approximatelyAmount = value.target.value;
      this.form.detailApproximatelyAmount = value.target.value;
      if (value.target.value) {
        this.form.amount = BigNumber(value.target.value)
          .multipliedBy(this.rate)
          .toFixed(2);
      } else {
        this.form.amount = '0';
      }
      if (
        new BigNumber(value.target.value).multipliedBy(this.rate).toFixed(2)
        > this.maxAmount
      ) {
        this.showMaxErrorTips = true;
      } else {
        this.showMaxErrorTips = false;
      }
      if (
        new BigNumber(value.target.value).multipliedBy(this.rate).toFixed(2)
        < this.minAmount
      ) {
        this.showMinErrorTips = true;
      } else {
        this.showMinErrorTips = false;
      }
    },
    selectChange(e) {
      this.submitTitle = 'Deposit and Pay';
      if (e.target.value === 'sepa') {
        this.masterCardMenuOpen = false;
        this.chooseSepa = true;
        this.chooseWallet = false;
      } else if (e.target.value === 'wallet') {
        this.submitTitle = 'Payment';
        this.chooseSepa = false;
        this.chooseWallet = true;
      }
    },
    toAddressChange(value) {
      this.form.address = value.target.value;
      if (
        RegCoin[this.form.to_currency].test(value.target.value)
        || !value.target.value
      ) {
        this.showAddressTips = false;
      } else {
        this.showAddressTips = true;
      }
    },
    goWallet() {
      this.changeDefaultMenu('wallet');
    },
    changeSteps() {
      this.showStep1 = true;
      this.showStep2 = false;
      this.showStep3 = false;
    },
    getCaptcha() {
      this.sendEmailLoading = true;
      this.sendEmailDisabled = true;
      const mail = localStorage.getItem('inst_mail');
      const params = {
        mail,
      };
      sendEmail(params)
        .then((res) => {
          this.mail_code_token = res.result;
          this.sendEmailLoading = false;
          this.$message.success('send email success');
          this.timer = setInterval(this.CountDown, 1000);
        })
        .catch(
          () => {
            this.sendEmailLoading = false;
            this.sendEmailDisabled = false;
          },
        );
    },
    CountDown() {
      if (this.defaultTime > 0) {
        this.defaultTime = this.defaultTime - 1;
        this.sendEmailText = this.defaultTime;
      } else {
        clearInterval(this.timer);
        this.defaultTime = 60;
        this.sendEmailDisabled = false;
        this.sendEmailText = 'Send';
      }
    },
    submit() {
      const authorization = `${neoAuthBefore}${Date.now()}${authAfter}`;
      if (this.chooseWallet) {
        if (Number(this.relBalance) < Number(this.form.amount)) {
          this.$message.error('Insufficient wallet balance');
        } else {
          this.verifyGoogleVisible = true;
        }
      } else {
        const params = {
          from_currency: this.form.from_currency,
          amount: BigNumber(this.form.amount).toFixed(2),
          pay_with: 'sepa',
          to_crypto: this.form.to_currency,
          address: this.form.address,
          rate: this.sepa_rate,
        };
        this.submitOrder(params, authorization);
      }
    },
    handleVerifyGoogleSubmit(e) {
      e.preventDefault();
      this.verifyGoogleAuthForm.validateFields((err, values) => {
        this.sepaLoading = true;
        if (!err) {
          const authorization = `${neoAuthBefore}${Date.now()}${authAfter}`;
          const params = {
            pay_with: 'wallet',
            wallet_address: this.relWallet[0]?.address,
            from_currency: this.form.from_currency,
            amount: BigNumber(this.form.amount).toFixed(2),
            rate: this.wallet_rate,
            to_crypto: this.form.to_currency,
            address: this.form.address,
            mail_token: this.mail_code_token,
            mail_code: values['email-code'],
          };
          if (this.gaStatus) {
            params['ga-code'] = values.google_code;
          }
          this.submitOrder(params, authorization);
        }
      });
    },
    submitOrder(params, authorization) {
      fastBuyCurrencyNew(params, authorization)
        .then((res) => {
          this.sepaLoading = false;
          if (res.code === 0) {
            this.showStep1 = false;
            if (params.pay_with === 'wallet') {
              this.showStep2 = true;
              this.verifyGoogleVisible = false;
            } else if (params.pay_with === 'sepa') {
              this.speaData = res.result;
              this.speaModalVisible = true;
              this.showStep3 = true;
            }
          } else {
            this.$message.error(`${res.msg}`);
          }
        })
        .catch((err) => {
          this.sepaLoading = false;
          if (err.code === 119010) {
            this.changeDefaultMenu('identification');
          }
        });
    },
    openSepaA() {
      this.speaModalVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper_buyCrypto {
  @media (max-width: 960px) {
    width: 100%;
  }
  .container {
    padding: 30px;
    background: rgba(0, 69, 255, 0.03);
    .wrapper-left {
      width: 420px;
      @media (max-width: 960px) {
        width: 100%;
        margin: auto;
        margin-bottom: 12px;
      }
      .successIcon {
        margin: 36px;
      }
      .successTitle {
        font-size: 20px;
        font-weight: 500;
        margin: 24px 0;
      }
      .successTips {
        color: #888;
      }
      .goToBtnGot {
        margin-top: 100px;
        height: 48px;
        width: 200px;
      }
      .select-label {
        margin-bottom: 12px;
        font-size: 14px;
        text-align: left;
      }
    }
    .wrapper-wallet {
      width: 100%;
      text-align: center;
    }
    .wrapper-left-item {
      margin-top: 30px;
    }
    .wrapper-left-item-first {
      margin-top: 0;
    }
    .wrapper-left-item-last {
      margin-bottom: 5px;
    }
    .wrapper-left-item-address {
      margin-bottom: 54px;
    }
    .rateTipsLeft {
      color: #000;
      font-size: 14px;
      text-align: left;
      margin-top: 10px;
      background: rgba(230, 154, 58, 0.12);
      display: flex;
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 15px;
      img {
        width: 24px;
        height: 24px;
      }
      .rateTipsTitleLeft {
        opacity: 0.8;
        margin-left: 4px;
      }
    }
  }
  .select-container-top {
    /deep/.ant-input-group {
      border: 1px solid #d9d9d9;
      border-radius: 15px;
      .ant-input {
        border: 0;
        border-radius: 15px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        font-weight: bold;
      }
    }
    .pay_with_sty {
      width: 420px;
      @media (max-width: 960px) {
        width: 100%;
      }
    }
    //input去除加减号样式
    /deep/ input::-webkit-outer-spin-button,
    /deep/ input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    /deep/ input[type="number"] {
      -moz-appearance: textfield;
    }
    /deep/.ant-input-group:hover {
      border: 1px solid #0045ff;
      box-shadow: 0 0 0 2px rgba(0, 69, 255, 0.06);
      transition: all 0.3s;
    }
    /deep/.ant-input-group:focus-within {
      border: 1px solid #0045ff;
      box-shadow: 0 0 0 2px rgba(0, 69, 255, 0.06);
      transition: all 0.3s;
    }
    /deep/.ant-input-group-addon {
      border: none;
      border-radius: 15px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: #fff;
    }
    /deep/.ant-select-selection-selected-value {
      font-weight: bold;
    }
  }
  .step_confirm_container {
    padding: 30px;
    background: rgba(0, 69, 255, 0.03);
    border-radius: 8px;
    @media (max-width: 960px) {
      padding: 12px;
    }
    .details_column {
      display: flex;
      flex-direction: row;
      .details_column_val {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 20px;
        @media (max-width: 960px) {
          margin: 5px;
        }
        .title {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.6);
          margin-bottom: 5px;
        }
        .value {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #000000;
        }
      }
    }
  }
}

.success_result_container {
  width: 100%;
  text-align: center;
  .success_result {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 40px;
  }
}
.confirmContainer_step3 {
  justify-content: center;
}

.currencySelect {
  min-width: 160px;
}
.select-label-top {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  color: #000;
  img {
    width: 14px;
    cursor: pointer;
    margin-right: 10px;
  }
}
.select-label {
  font-size: 14px;
  text-align: left;
}
.select-label-option {
  font-size: 12px;
  opacity: 0.6;
}
.selectIcon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  margin-top: -3px;
}

.triangle {
  border-style: solid;
  border-width: 5px 5px 5px 5px;
  border-color: transparent transparent rgba(230, 154, 58, 0.12) transparent;
  width: 0px;
  height: 0px;
  margin-left: 10px;
}

.maxTips {
  background: rgba(230, 154, 58, 0.12);
  padding: 5px;
  border-radius: 5px;
  text-align: left;
}
.g-input {
  border-radius: 15px;
}
.buyWarning {
  background-color: #fef3f2;
  border-radius: 15px;
  padding: 12px;
  margin-top: 20px;
}

.buyWarning .warningTop {
  font-size: 20px;
  color: #bc2d1a;
  font-weight: 500;
  margin-bottom: 20px;
  vertical-align: middle;
  text-align: left;
}

.buyWarning .warningTop img {
  width: 22px;
  vertical-align: middle;
  margin-right: 12px;
}

.buyWarning .warningBottom {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
.addressTips {
  color: #c91919;
  font-size: 12px;
  margin-top: 10px;
  text-align: left;
}
.renderItem {
  text-align: left;
  border-bottom: none;
  cursor: pointer;
  padding-left: 12px;
  .renderItemImg {
    height: 36px;
  }
  .renderItemTitle {
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    font-weight: bold;
    img {
      width: 32px;
      margin-right: 10px;
    }
  }
  .renderItemTitleOpacity {
    opacity: 0.4;
    font-weight: 400;
    font-size: 12px;
    padding-left: 48px;
  }
}
.renderItem_wallet {
  margin-bottom: 0;
}
.wallet_bottom {
  display: flex;
  justify-content: space-between;
  padding: 0 8px 20px 56px;
  .wallet_left {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    opacity: 0.4;
  }
  .wallet_right {
    display: flex;
    .wallet_right_balance {
      // margin-right: 20px;
      font-weight: 500;
      font-size: 12px;
      color: #000;
    }
    .wallet_right_deposit {
      color: #0045ff;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}
.sepa_container {
  border-top: 1px solid #f0f0f0;
}
.bind_list_tips {
  display: flex;
  justify-content: space-between;
  img {
    width: 10px;
  }
  .bind_list_title {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ff8e00;
    margin-left: 8px;
    text-align: left;
    padding-right: 8px;
  }
}
.deposit-btn {
  border-radius: 8px;
  height: 48px;
  width: 400px;
  margin-top: 30px;
  @media (max-width: 960px) {
    width: 100%;
  }
}
.confirmContainer {
  .goToBtn {
    background: #0045ff;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    color: #fff;
    margin-top: 40px;
  }
}
.info_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 10px;
}

.g-order {
  cursor: pointer;
  color: #0045ff;
}
.attention_tips {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ff8e00;
  margin: 12px 0 40px;
}
.attention_tips_tips {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;
}
.waitTips {
  margin-top: 48px;
  margin-bottom: 48px;
  color: #0045ff;
}
</style>
