const authBefore = process.env.VUE_APP_AUTH_BEFORE || 'Inst:8c9a0c618e144f5ab76fc35d706fbec9:';
const authAfter = process.env.VUE_APP_AUTH_AFTER || ':yYXX2O6Pn0PVFDpXeSYodHrlUk5URKrO2akSH4drLJ0=';

const authBeforeBDOG = process.env.VUE_APP_AUTH_BEFORE_BDOG || 'Inst:8c9a0c618e144f5ab76fc35d706fbec9:';
const authAfterBDOG = process.env.VUE_APP_AUTH_AFTER_BDOG || ':yYXX2O6Pn0PVFDpXeSYodHrlUk5URKrO2akSH4drLJ0=';

const neoAuthBefore = process.env.VUE_APP_NEO_AUTH_BEFORE || 'Inst:8f8c23fd5bfc4f34a7bfd9746596a2f9:';

export {
  authBefore, authAfter, authBeforeBDOG, authAfterBDOG, neoAuthBefore,
};
