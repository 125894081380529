<template>
  <div class="cardHolderContainer">
    <div class="cardContainer">
      <div class="container">
        <div class="historyContainer">
          <a-table
            :columns="columns"
            :data-source="data"
            rowKey="order_no"
            :loading="dataLoading"
            @change="onFastBuyTableChange"
            :pagination="{
              pageSize: 10,
              showTotal: (total) => `Total ${total} items`,
              total,
            }"
          >
            <div
              slot="order_no"
              slot-scope="text, record"
              :class="record.settle_status === 0 ? 'orderNo' : null"
              @click="openOrderModal(record)"
            >
              {{ text }}
            </div>
            <span
              slot="address"
              slot-scope="text"
              class="address"
              v-clipboard:copy="text"
              v-clipboard:success="onAddressCopy"
              v-clipboard:error="onAddressError"
            >
              <span v-if="text">
                {{ text.substring(0, 4) }}...{{
                  text.substring(text.length - 4, text.length)
                }}
              </span>
              <span v-else>-</span>
            </span>
            <span
              slot="tx_hash"
              slot-scope="text"
              class="transaction_hash"
              v-clipboard:copy="text"
              v-clipboard:success="onHashCopy"
              v-clipboard:error="onHashError"
            >
              <span v-if="text">
                {{ text.substring(0, 4) }}...{{
                  text.substring(text.length - 4, text.length)
                }}
              </span>
              <span v-else>-</span>
            </span>
            <span slot="currency" slot-scope="text, record">
              {{ record.cust_order?.currency }}
            </span>
            <span slot="amount" slot-scope="text, record">
              {{ record.cust_order?.amount }}
            </span>
            <span slot="purchase_type" slot-scope="text">
              <span v-if="text === '0'">Card</span>
              <span v-else-if="text === '1'">Wallet</span>
              <span v-else-if="text === '2'">SEPA</span>
            </span>
            <span slot="settle_status" slot-scope="text">
              <span v-if="text === 0" class="g-pending">Pending</span>
              <span v-else-if="text === 1" class="success">Succeed</span>
              <span v-else-if="text === 2">Confirming</span>
              <span v-else-if="text === 3" class="fail">Abnormal</span>
              <span v-else-if="text === 4" class="fail">Failed</span>
              <span v-else-if="text === 5" class="cancel">Cancelled</span>
              <span v-else-if="text === 6">Expire</span>
              <span v-else-if="text === 7">Refund in progress</span>
              <span v-else-if="text === 8" class="success">Refunded</span>
              <span v-else-if="text === 9" class="fail">Refund Failed</span>
            </span>
            <span slot="transfer_status" slot-scope="text">
              <span v-if="text === 0" class="g-pending">Pending</span>
              <span v-else-if="text === 1" class="success">Succeed</span>
              <span v-else-if="text === 2" class="fail">Failed</span>
              <span v-else-if="text === 3">Cancelled</span>
              <span v-else-if="text === 4">Pending</span>
            </span>
            <span slot="create_time" slot-scope="text">
              {{ $moment(text).format("YYYY-MM-DD HH:mm:ss") }}
            </span>
            <span slot="update_time" slot-scope="text">
              {{ $moment(text).format("YYYY-MM-DD HH:mm:ss") }}
            </span>
            <span slot="option" slot-scope="text, record">
              <span class="address" @click="openModal(record)">Details</span>
            </span>
          </a-table>
        </div>
      </div>
    </div>
    <a-modal
      class="buyCryptoHistoryModal"
      v-model="orderModalShower"
      centered
      :footer="null"
      width="450"
      :maskClosable="false"
      :destroyOnClose="true"
    >
      <iframe :src="orderShowerUrl" width="365" height="560" frameBorder="no">
      </iframe>
    </a-modal>
    <a-modal
      v-model="walletSepaModalVisible"
      title="Details"
      centered
      :footer="null"
      :maskClosable="false"
      width="720px"
    >
      <div class="details_tips_title">Order Details:</div>
      <div class="deposit_bank_msg_container">
        <div class="deposit_bank_msg_container_left">
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Order No.</div>
            <div class="line_right">
              {{ order_no || "-" }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Amount</div>
            <div class="line_right">
              {{ custOrder.amount }} {{ custOrder.currency }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Payment Status</div>
            <div class="line_right">
              <span v-if="orderDetails.settle_status === 0" class="g-pending"
                >Pending</span
              >
              <span v-else-if="orderDetails.settle_status === 1" class="success"
                >Succeed</span
              >
              <span
                v-else-if="orderDetails.settle_status === 2"
                class="g-pending"
                >Confirming</span
              >
              <span v-else-if="orderDetails.settle_status === 3" class="fail"
                >Abnormal</span
              >
              <span v-else-if="orderDetails.settle_status === 4" class="fail"
                >Failed</span
              >
              <span v-else-if="orderDetails.settle_status === 5"
                >Cancelled</span
              >
              <span v-else-if="orderDetails.settle_status === 6">Expire</span>
              <span
                v-else-if="orderDetails.settle_status === 7"
                class="g-pending"
                >Refund in progress</span
              >
              <span v-else-if="orderDetails.settle_status === 8" class="success"
                >Refunded</span
              >
              <span v-else-if="orderDetails.settle_status === 9" class="fail"
                >Refund Failed</span
              >
              <span v-else>{{ orderDetails.settle_status }}</span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Purchase</div>
            <div class="line_right">
              {{ orderDetails.amount }} {{ orderDetails.coin_type }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Destination Address</div>
            <div class="line_right">
              {{ orderDetails.address }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Transaction Hash</div>
            <div class="line_right">
              {{ orderDetails.tx_hash || "-" }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Transfer Status</div>
            <div class="line_right">
              <span v-if="orderDetails.transfer_status === 0">Pending</span>
              <span
                v-else-if="orderDetails.transfer_status === 1"
                class="success"
                >Succeed</span
              >
              <span v-else-if="orderDetails.transfer_status === 2" class="fail"
                >Failed</span
              >
              <span v-else-if="orderDetails.transfer_status === 3"
                >Cancelled</span
              >
              <span v-else-if="orderDetails.transfer_status === 4"
                >Pending</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="details_tips_title" v-if="walletSepaData">
        Payment Details:
      </div>
      <div class="deposit_bank_msg_container" v-if="walletSepaData">
        <div class="deposit_bank_msg_container_left">
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Wallet Transaction ID</div>
            <div class="line_right">{{ walletSepaData.external_tx_id }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Wallet</div>
            <div class="line_right">{{ walletSepaData.address }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Amount</div>
            <div class="line_right">
              {{ walletSepaData.amount }} {{ walletSepaData.currency }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Balance</div>
            <div class="line_right">
              {{ walletSepaData.balance || "-" }} {{ walletSepaData.currency }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Time Updated</div>
            <div class="line_right">
              {{
                $moment(walletSepaData.update_time).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal
      v-model="sepaTransferVisible"
      title="Funding Your Account with Bank Transfer(SPEA)"
      centered
      :footer="null"
      width="450"
      :maskClosable="false"
    >
      <div class="info_title">Order information</div>
      <div class="deposit_bank_msg_container">
        <div class="deposit_bank_msg_container_left">
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Order No.</div>
            <div class="line_right">{{ speaData.order_no }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Wallet Transaction ID</div>
            <div class="line_right">{{ speaData.external_tx_id }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Wallet ID</div>
            <div class="line_right">{{ speaData.address }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Amount</div>
            <div class="line_right">
              {{ speaData.amount }} {{ speaData.currency }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Reference Code</div>
            <div class="line_right">
              {{ speaData.postscript }}
              <span
                class="copyBtn"
                v-clipboard:copy="speaData.postscript"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="attention_tips">
        Please transfer the fund within 4 hours. Send the order reference code
        with the transaction so we can know it’s from you.
      </div>
      <div class="attention_tips_tips">
        Make a payment to Inst using the following bank account details .
      </div>
      <div class="info_title">Bank information</div>
      <div class="deposit_bank_msg_container">
        <div class="deposit_bank_msg_container_left">
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Account Name</div>
            <div class="line_right">
              {{ speaData.bank.account_name
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.account_name"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">IBAN</div>
            <div class="line_right">
              {{ speaData.bank.account_id
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.account_id"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Bank Name</div>
            <div class="line_right">
              {{ speaData.bank.bank_name
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.bank_name"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">BIC(SWIFT Code)</div>
            <div class="line_right">
              {{ speaData.bank.bic
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.bic"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Bank Address</div>
            <div class="line_right">
              {{ speaData.bank.bank_address
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.bank_address"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { getBuyCryptoHistory } from '@/utils/api';
import { basePayonePayUrl } from '@/utils/baseUrl';

export default {
  name: 'History',
  data() {
    return {
      columns: [
        {
          title: 'Order No.',
          dataIndex: 'order_no',
          key: 'order_no',
          scopedSlots: { customRender: 'order_no' },
        },
        {
          title: 'Pay With',
          dataIndex: 'purchase_type',
          key: 'purchase_type',
          scopedSlots: { customRender: 'purchase_type' },
        },
        {
          title: 'Payment status',
          dataIndex: 'settle_status',
          key: 'settle_status',
          scopedSlots: { customRender: 'settle_status' },
        },
        { title: 'Asset', dataIndex: 'coin_type', key: 'coin_type' },
        {
          title: 'Amount',
          dataIndex: 'amount_trim',
          key: 'amount_trim',
        },
        {
          title: 'Destination Address',
          dataIndex: 'address',
          key: 'address',
          scopedSlots: { customRender: 'address' },
        },
        {
          title: 'Transaction Hash',
          dataIndex: 'tx_hash',
          key: 'tx_hash',
          scopedSlots: { customRender: 'tx_hash' },
        },
        {
          title: 'Transfer Status',
          dataIndex: 'transfer_status',
          key: 'transfer_status',
          scopedSlots: { customRender: 'transfer_status' },
        },
        {
          title: 'Time Created',
          dataIndex: 'create_time',
          key: 'create_time',
          scopedSlots: { customRender: 'create_time' },
        },
        {
          title: 'Operation',
          dataIndex: 'option',
          key: 'option',
          scopedSlots: { customRender: 'option' },
        },
      ],
      data: [],
      dataLoading: true,
      former_month_year: null,
      latter_month_year: null,
      address: null,
      coin_type: null,
      order_no: null,
      orderModalShower: false,
      orderShowerUrl: '',
      current: 1,
      total: 0,
      page_size: 10,
      walletSepaModalVisible: false,
      walletSepaData: {},
      custOrder: {},
      speaData: { bank: {} },
      sepaTransferVisible: false,
      purchase_type: null,
      orderDetails: {},
    };
  },
  created() {
    this.searchHistory();
  },
  methods: {
    onCopy() {
      this.$message.success('Copy Success');
    },
    onError() {
      this.$message.error('Copy Failed');
    },
    onAddressCopy() {
      this.$message.success('Copy Address Success');
    },
    onAddressError() {
      this.$message.error('Copy Address Failed');
    },
    onHashCopy() {
      this.$message.success('Copy Hash Success');
    },
    onHashError() {
      this.$message.error('Copy Hash Failed');
    },
    onChange(date) {
      if (date) {
        this.former_month_year = date[0].valueOf();
        this.latter_month_year = date[1].valueOf();
      }
      this.searchHistory();
    },
    onFastBuyTableChange(pagination) {
      const params = {
        page_num: pagination.current,
        page_size: this.page_size,
      };
      this.current = pagination.current;
      this.dataLoading = true;
      this.searchHistory(params);
    },
    searchHistory() {
      const params = {
        page_num: this.current,
        page_size: this.page_size,
      };
      getBuyCryptoHistory(params)
        .then((res) => {
          this.dataLoading = false;
          if (res.code === 0) {
            const records = res.result.records.map((item) => {
              const newItem = item;
              if (newItem.cust_order) {
                newItem.settle_status = item.cust_order.settle_status;
              }
              return newItem;
            });
            this.data = records;
            this.total = res.result.total;
          }
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
    openOrderModal(item) {
      if (item.settle_status === 0) {
        // pending中
        if (item.purchase_type === '0') {
          this.orderShowerUrl = `${basePayonePayUrl}/?id=${item.order_no}`;
          this.orderModalShower = true;
        } else if (item.purchase_type === '2') {
          this.sepaTransferVisible = true;
          this.speaData = item.wallet_order;
        }
      }
    },
    openModal(item) {
      this.walletSepaModalVisible = true;
      this.orderDetails = item;
      this.custOrder = item.cust_order;
      this.order_no = item.order_no;
      this.purchase_type = item.purchase_type;
      this.walletSepaData = item.wallet_order;
      this.orderShowerUrl = `${basePayonePayUrl}/?id=${item.order_no}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.cardHolderContainer {
  width: 100%;
  .cardContainer {
    width: 100%;
    .container {
      max-width: 100%;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 48px;
      // box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
      @media (max-width: 750px) {
        margin-top: 10px;
      }
      .historyContainer {
        h2 {
          margin-left: 16px;
        }
        margin-top: 24px;
      }
    }
  }
}
.searchContent {
  margin: 16px;
  margin-top: 0;
  padding-top: 16px;
  .txIdInput {
    display: inline-block;
    width: 100px;
    margin-right: 20px;
  }
  .searchHistory {
    background: #0045ff;
    color: #fff;
    margin-left: 20px;
  }
  @media (max-width: 750px) {
    display: flex;
    /deep/ .ant-calendar-picker {
      flex: 3;
    }
    .searchHistory {
      flex: 1;
    }
  }
}
/deep/ .ant-table-wrapper {
  width: 100%;
  overflow-x: auto;
  // display: -webkit-box;
  // -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}
.success {
  color: #52c41a;
}
.cancel {
  color: #0045ff;
}
.fail {
  color: red;
}
.orderNo {
  cursor: pointer;
  color: #0045ff;
}
.address {
  cursor: pointer;
  color: #0045ff;
}
.transaction_hash {
  cursor: pointer;
}
.buyCryptoHistoryModal {
  /deep/.ant-modal-body {
    padding: 0;
  }
}
.deposit_bank_msg_container {
  display: flex;
  width: 100%;
  border-radius: 8px;
  padding: 20px 30px;
  margin: 0px 0 20px;
  background: rgba(0, 69, 255, 0.03);
  .deposit_bank_msg_container_left {
    display: flex;
    flex: 1;
    flex-direction: column;
    .deposit_bank_msg_container_line {
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding: 10px 0;
      .line_left {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
      .line_right {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        .copyBtn {
          margin-left: 10px;
        }
      }
    }
  }
}
.details_tips_title {
  margin-bottom: 8px;
}
.g-pending {
  color: #ff8e00;
}
</style>
