<template>
  <div class="invite-wrapper">
    <h2>Invite your friends to enjoy rewards</h2>
    <div class="inviteBanner">
      <div class="inviteTips">
        You can earn commission in USDT on transactions of your referrals. You
        will earn 0.4% of your 1st tier referrals' transaction amount, and 0.1%
        of your 2nd tier referrals' transaction amount.
      </div>
      <div class="inviteTipsLast">
        Only users who have bought crypto currency more than $100 will have an
        invitation code.
      </div>
      <div class="inviteCard">
        <div class="inviteCardLeft">
          <div class="title">Invitation Code</div>
          <div class="codeText" v-if="inviteCode">
            {{ inviteCode }}
            <span
              class="copyBtn"
              v-clipboard:copy="inviteCode"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <a-icon class="copyAddress" type="copy" theme="twoTone" />
            </span>
          </div>
          <div class="link" v-else>-</div>
        </div>
        <div class="inviteRight">
          <div class="title">Invitation Links</div>
          <div class="link" v-if="inviteCode">
            {{ registerUrl }}
            <span
              class="copyBtn"
              v-clipboard:copy="registerUrl"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <a-icon class="copyAddress" type="copy" theme="twoTone" />
            </span>
          </div>
          <div class="link" v-else>-</div>
        </div>
      </div>
    </div>
    <a-steps :current="3" class="stepContainer" direction="vertical">
      <a-step title="Invite friends to sign up for Inst by sharing a link">
        <div class="step" slot="icon">1</div>
      </a-step>
      <a-step
title="Friend accepts the invitation,completes the registration and carries on the transaction"
      >
        <div class="step" slot="icon">2</div>
      </a-step>
      <a-step title="Easy access to transaction fees rebate benefits">
        <div class="step" slot="icon">3</div>
      </a-step>
    </a-steps>
    <div class="overview">
      <h3>Overview</h3>
      <div class="cardContainer">
        <a-card title="Rewards" class="card">
          <p>
            Total commission:
            <span class="textW">{{ totalCommission }}</span> USDT
          </p>
          <p>
            Withdrawn commission:
            <span class="textW">{{ withdrawnCommission }}</span> USDT
          </p>
          <p>
            Available commission:
            <span class="textW">{{ availableCommission }}</span> USDT
          </p>
          <div class="withdrawContainer">
            <a-button
              type="primary"
              @click="openWithdrawModal"
              :loading="withdrawBtnLoading"
              >Withdraw</a-button
            >
          </div>
        </a-card>
        <a-card title="Referrals" class="card">
          <!-- <p>
            Invited:
            <span class="textW">{{
              direct_invitee_num + indirect_invitee_num
            }}</span>
          </p>
          <p>
            Total Transactions:
            <span class="textW">{{ total_transactions }}</span>
          </p> -->
          <p>
            1st Tier:
            <span class="textW">{{ direct_invitee_num }}</span>
          </p>
          <p>
            2nd Tier:
            <span class="textW">{{ indirect_invitee_num }}</span>
          </p>
          <p>
            1st Tier transactions:
            <span class="textW">{{ direct_transaction_num }}</span>
          </p>
          <p>
            2nd Tier transactions:
            <span class="textW">{{ indirect_transaction_num }}</span>
          </p>
        </a-card>
      </div>
    </div>
    <div class="history">
      <a-tabs @change="changeTab">
        <a-tab-pane key="invite" tab="Invitation History">
          <InviteRecord ref="ReturnRecord" :data="records" />
        </a-tab-pane>
        <a-tab-pane key="return" tab="Commission">
          <ReturnRecord ref="InviteRecord" :data="commissionData" />
        </a-tab-pane>
        <a-tab-pane key="withdraw" tab="Withdraw History">
          <WithdrawRecord ref="WithdrawRecord" :data="commissionWithdrawData" />
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- <canvas id="canvas" width="500px" height="500px"></canvas>
    <img
      id="tulip"
      src="@/assets/topBackgroundImg.png"
      style="width: 500px; height: 500px; display: none"
    />
    <a-button @click="print">下载</a-button> -->
    <a-modal
      v-model="withdrawVisible"
      title="Withdraw"
      :footer="null"
      :maskClosable="false"
      centered
    >
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item label="Amount">
          <a-input-number
            class="withdraw_input"
            placeholder="Amount"
            size="large"
            v-decorator="[
              'amount',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please enter amount',
                  },
                  {
                    validator: regAmount,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Destination USDT-TRC20 address">
          <a-input
            class="withdraw_input"
            placeholder="USDT-TRC20 address"
            size="large"
            v-decorator="[
              'to_address',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please enter destination address',
                  },
                  {
                    validator: regAddress,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Verify Google Authenticator" v-if="bindGA">
          <a-input
            class="withdraw_input"
            placeholder="Google Authenticator Code"
            size="large"
            v-decorator="[
              'google_code',
              {
                rules: [
                  {
                    required: true,
                    len: 6,
                    message:
                      'Please enter the six-digit code from Google Authenticator',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="Email Verification Code">
          <a-row :gutter="8">
            <a-col :span="18">
              <a-input
                v-decorator="[
                  'email-code',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter the captcha you got',
                      },
                    ],
                  },
                ]"
              />
            </a-col>
            <a-col :span="6">
              <a-button
                class="sendMailBtn"
                type="primary"
                @click="getCaptcha"
                :loading="sendEmailLoading"
                :disabled="sendEmailDisabled"
                >{{ sendEmailText }}</a-button
              >
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item class="confirmContainer">
          <a-button class="goToBtn" html-type="submit"> Confirm </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import QRCode from 'qrcode';
import {
  getInviteInfo,
  getCommissionData,
  getInviteRewards,
  getGaStatus,
  sendEmail,
  commissionWithdraw,
  getCommissionWithdraw,
} from '@/utils/api';
import { RegCoin } from '@/utils/reg';
import { baseWebsiteRegisterUrl } from '@/utils/baseUrl';
import InviteRecord from './components/InviteRecord.vue';
import ReturnRecord from './components/ReturnRecord.vue';
import WithdrawRecord from './components/WithdrawRecord.vue';

export default {
  name: 'Invite',
  components: {
    InviteRecord,
    ReturnRecord,
    WithdrawRecord,
  },
  data() {
    return {
      mail: '',
      inviteCode: '',
      direct_invitee_num: 0,
      indirect_invitee_num: 0,
      inviter: 0,
      direct_transaction_num: 0,
      indirect_transaction_num: 0,
      registerUrl: '',
      records: [],
      commissionData: [],
      commissionWithdrawData: [],
      totalCommission: '',
      withdrawnCommission: '',
      availableCommission: '',
      withdrawVisible: false,
      withdrawBtnLoading: false,
      form: this.$form.createForm(this, { name: 'withdraw' }),
      bindGA: false,
      sendEmailLoading: false,
      sendEmailDisabled: false,
      sendEmailText: 'Send',
      mail_code_token: '',
      timer: null,
      defaultTime: 60,
    };
  },
  created() {
    this.mail = localStorage.getItem('inst_mail');
    this.toGetInviteInfoData();
    this.toGetCommissionData();
    this.toGetInviteRewardsData();
    this.toGetCommissionWithdrawData();
  },
  methods: {
    toGetInviteInfoData() {
      getInviteInfo().then((res) => {
        if (res.code === 0) {
          this.inviteCode = res.result.code;
          this.direct_invitee_num = res.result.direct_invitee_num;
          this.indirect_invitee_num = res.result.indirect_invitee_num;
          this.inviter = res.result.inviter;
          this.direct_transaction_num = res.result.direct_transaction_num;
          this.indirect_transaction_num = res.result.indirect_transaction_num;
          this.registerUrl = `${baseWebsiteRegisterUrl}?inviteCode=${res.result.code}`;
          this.records = res.result.records;
        }
      });
    },
    toGetCommissionData() {
      getCommissionData().then((res) => {
        this.commissionData = res.result;
      });
    },
    toGetInviteRewardsData() {
      getInviteRewards().then((res) => {
        if (res.code === 0) {
          this.totalCommission = res.result.total.toFixed(4);
          this.withdrawnCommission = res.result.withdrawn.toFixed(4);
          this.availableCommission = res.result.available.toFixed(4);
        }
      });
    },
    toGetCommissionWithdrawData() {
      getCommissionWithdraw().then((res) => {
        this.commissionWithdrawData = res.result.records;
      });
    },
    print() {
      const can = document.getElementById('canvas');
      const ctx = can.getContext('2d');
      const img = document.getElementById('tulip');
      ctx.drawImage(img, 0, 0, 500, 500);
      const jumpUrl = 'https://www.baidu.com/';
      // # 绘制二维码 - 放置在右下角
      QRCode.toDataURL(jumpUrl)
        .then((qrCodeSrc) => {
          const qrCodeImg = new Image();
          qrCodeImg.src = qrCodeSrc;
          qrCodeImg.onload = () => {
            // // 比例 = 图片元素宽度/设计图宽度
            // const ratio = w / 375;
            // // 间距 = 设计图上的间距 * 比例（右侧间距）
            // const spacing = 8 * ratio;
            // // 尺寸 = 设计图上的尺寸 * 比例
            // const qrSize = 62 * ratio;
            // // 计算x、y值
            // const x = w - qrSize - spacing;
            // const y = h - qrSize - spacing;
            // 绘制
            ctx.drawImage(qrCodeImg, 100, 100, 100, 100);
            // 导出
            this.dataURL = can.toDataURL();
            // var a = document.createElement("a");
            // a.href = can.toDataURL();
            // a.download = "hechengtupian.png";
            // a.click();
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onCopy() {
      this.$message.success('Copy Success');
    },
    onError() {
      this.$message.error('Copy Failed');
    },
    changeTab(e) {
      if (e === 'invite') {
        this.toGetInviteInfoData();
      } else if (e === 'return') {
        this.toGetCommissionData();
      } else if (e === 'withdraw') {
        this.toGetCommissionWithdrawData();
      }
    },
    openWithdrawModal() {
      this.withdrawBtnLoading = true;
      getGaStatus(this.mail)
        .then((res) => {
          this.withdrawBtnLoading = false;
          this.withdrawVisible = true;
          if (res.code === 0) {
            if (res.result.ga_enable && res.result.GABound) {
              this.bindGA = true;
            }
          }
        })
        .catch(() => {
          this.withdrawBtnLoading = false;
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            amount: values.amount,
            to_address: values.to_address,
            token: this.mail_code_token,
          };
          commissionWithdraw(
            params,
            values['email-code'],
            values.google_code || '',
          ).then((res) => {
            if (res.code === 0) {
              this.withdrawVisible = false;
              this.$message.success('withdraw success');
            }
          });
        }
      });
    },
    getCaptcha() {
      this.sendEmailLoading = true;
      this.sendEmailDisabled = true;
      const params = {
        mail: this.mail,
      };
      sendEmail(params)
        .then((res) => {
          this.mail_code_token = res.result;
          this.sendEmailLoading = false;
          this.$message.success('send email success');
          this.timer = setInterval(this.CountDown, 1000);
        })
        .catch(
          () => {
            this.sendEmailLoading = false;
            this.sendEmailDisabled = false;
          },
        );
    },
    CountDown() {
      if (this.defaultTime > 0) {
        this.defaultTime = this.defaultTime - 1;
        this.sendEmailText = this.defaultTime;
      } else {
        clearInterval(this.timer);
        this.defaultTime = 60;
        this.sendEmailDisabled = false;
        this.sendEmailText = 'Send';
      }
    },
    regAddress(rule, value, callback) {
      if (!RegCoin['USDT-TRC20'].test(value)) {
        callback('Please enter the USDT-TRC20 address');
      } else {
        callback();
      }
    },
    regAmount(rule, value, callback) {
      const reg = /^(\d+)(.\d{0,2})?$/;
      if (!reg.test(value)) {
        callback('Please enter correct amount');
      } else {
        callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.invite-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
  min-height: 800px;
  padding-bottom: 24px;
  background: #f8f8f8;
  @media (max-width: 960px) {
    height: auto;
  }
  h2 {
    font-size: 40px;
    line-height: 56px;
    margin: 20px 0;
    text-align: center;
  }
  .inviteBanner {
    background: #fff;
    padding: 24px 0;
    .inviteTips {
      padding: 0 24px;
      font-size: 16px;
      margin-top: 24px;
    }
    .inviteTipsLast {
      padding: 0 24px;
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 12px;
    }
    .inviteCard {
      padding: 20px 48px;
      display: flex;
      flex-direction: row;
      background: rgba(0, 69, 255, 0.03);
      background: #e5ecff;
      margin: 48px 0px 24px 24px;
      border-radius: 8px;
      width: 820px;
      @media (max-width: 1080px) {
        display: flex;
        flex-direction: column;
        width: calc(100% - 24px);
        margin: 24px 12px 0;
      }
      .inviteCardLeft {
        width: 200px;
        .title {
          font-size: 16px;
          height: 36px;
          line-height: 36px;
          opacity: 0.6;
        }
        .codeText {
          font-size: 24px;
          font-weight: bold;
          .copyBtn {
            cursor: pointer;
          }
        }
      }
      .inviteRight {
        display: flex;
        flex: 1;
        flex-direction: column;
        .title {
          font-size: 16px;
          height: 36px;
          line-height: 36px;
          opacity: 0.6;
        }
        .link {
          font-size: 16px;
          height: 36px;
          line-height: 36px;
          @media (max-width: 1080px) {
            height: inherit;
          }
          .copyBtn {
            cursor: pointer;
          }
        }
      }
    }
  }
  .stepContainer {
    padding: 24px;
    background: #fff;
    /deep/ .ant-steps-item {
      white-space: normal;
    }
    /deep/ .ant-steps-item-container {
      display: flex;
    }
    .step {
      width: 30px;
      height: 30px;
      line-height: 30px;
      border: 1px solid #0045ff;
      border-radius: 15px;
      font-size: 20px;
    }
  }
  .overview {
    margin-top: 24px;
    padding: 0 12px;
    h3 {
      font-size: 26px;
      margin-bottom: 24px;
      padding: 0 12px;
    }
    .cardContainer {
      display: flex;
      @media (max-width: 1080px) {
        flex-direction: column;
      }
      .card {
        flex: 1;
        margin: 0 8px;
        @media (max-width: 1080px) {
          margin: 8px;
        }
        p {
          padding: 4px 0;
          font-size: 14px;
          color: #000;
          .textW {
            font-size: 16px;
            font-weight: bold;
          }
        }
        .withdrawContainer {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        }
      }
    }
  }
  .history {
    margin: 24px;
    padding: 24px;
    background: #fff;
  }
}
#canvas {
  width: 500px;
  height: 500px;
}
.sendMailBtn {
  width: 100%;
}
.confirmContainer {
  text-align: center;
  .goToBtn {
    background: #0045ff;
    border-radius: 8px;
    height: 40px;
    width: 100px;
    color: #fff;
  }
}
.withdraw_input {
  width: 100%;
}
</style>
