<template>
  <div>
    <Security
      v-if="changeDefaultShower === 'security'"
      :changeDefaultMenu="changeDefaultMenu"
      :changeShower="changeShower"
    />
    <ChangePassword
      v-if="changeDefaultShower === 'password'"
      :changeShower="changeShower"
    />
    <GoogleAuth
      v-if="changeDefaultShower === 'googleAuth'"
      :changeShower="changeShower"
    />
  </div>
</template>

<script>
import Security from './component/Security.vue';
import ChangePassword from './component/ChangePassword.vue';
import GoogleAuth from './component/GoogleAuth.vue';

export default {
  name: 'SecurityContainer',
  components: {
    Security,
    ChangePassword,
    GoogleAuth,
  },
  props: {
    changeDefaultMenu: {
      type: Function,
      require: false,
    },
  },
  data() {
    return {
      changeDefaultShower: 'security',
    };
  },
  methods: {
    changeShower(item) {
      this.changeDefaultShower = item;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #f8f8f8;
  .my_right {
    margin-left: 20px;
    width: 1020px;
    @media (max-width: 960px) {
      width: 100%;
      margin-left: 0;
    }
  }
}
</style>
