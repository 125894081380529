<template>
  <div class="deposit_credit_card_container">
    <div class="deposit_credit_card_container_content">
      <div class="back_container" @click="backBtn">
        <a-icon type="arrow-left" />
      </div>
      <div class="title">Deposit with a Credit/debit card</div>
    </div>
    <div class="deposit_credit_container">
      <h3>Requirements:</h3>
      <div class="deposit_steps">
        <img src="@/assets/right_g.svg" alt="" />
        <span>This is your card</span>
      </div>
      <div class="deposit_steps">
        <img src="@/assets/right_g.svg" alt="" />
        <span
          >Total amount of deposit by card does not exceed
          {{ limit_amount }} USD today.</span
        >
      </div>
      <div class="deposit_steps">
        <img src="@/assets/right_g.svg" alt="" />
        <span>You use max 3 different cards in the last 60 days</span>
      </div>
      <h3>
        The deposit will be canceled if any of the above requirements is not
        met.
      </h3>
      <div class="tips_container tips_container_tips">
        <div class="title_tips">Attention:</div>
        After pre-authorization, you may go through a quick verification of your
        card payment. It's part of our effort against credit card fraud. Once
        completed, your fund will be deposited.
      </div>
      <div class="tips_container">
        <div class="tips_container_center">
          <h3>Your payment</h3>
          <div>
            <div class="tips_line">
              <span class="tips_line_left">Wallet ID</span>
              <span class="tips_line_right">{{ address }}</span>
            </div>
            <div class="tips_line">
              <span class="tips_line_left">Credit Amount</span>
              <span class="tips_line_right"
                >{{ creditAmount }} {{ from_currency }}</span
              >
            </div>
            <div class="tips_line">
              <span class="tips_line_left">Debit Amount</span>
              <span class="tips_line_right"
                >~ {{ amount }} {{ from_currency }}</span
              >
            </div>
            <div class="tips_line">
              <span class="tips_line_left">FEE</span>
              <span class="tips_line_right"
                >~ {{ fee }} {{ from_currency }}</span
              >
            </div>
          </div>
        </div>
        <h4>
          By clicking the "Continue" button you agree to all conditions listed
          above.
        </h4>
        <div class="tips_bottom">
          Using cards you can deposit up to {{ limit_amount }} USD (or its
          equivalent amount in other currencies) per day. This deposit limit
          resets every 24 hours.
        </div>
      </div>
      <div class="confirm_container">
        <a-button type="primary" class="confirm-btn" @click="submitFun"
          >Continue</a-button
        >
      </div>
    </div>
    <a-modal
      v-model="showOrderModal"
      centered
      :footer="null"
      width="450"
      :maskClosable="false"
    >
      <iframe :src="orderUrl" height="800" width="350" frameBorder="no">
        <p>您的浏览器暂不支持 iframe 标签。</p>
      </iframe>
    </a-modal>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import { submitCreateOrder } from '@/utils/api';
import { authAfter, neoAuthBefore } from '@/utils/config';
// import NewFooter from '@/components/NewFooter.vue';

export default {
  name: 'DepositCreditCard',
  props: {
    CardData: {
      type: Array,
      request: true,
    },
    settingData: {
      type: Object,
      request: true,
    },
    changeDepositDefaultMenu: {
      type: Function,
      require: false,
    },
    from_currency: {
      type: String,
      require: true,
    },
    amount: {
      type: String,
      request: true,
    },
  },
  components: {
    // NewFooter,
  },
  data() {
    return {
      address: '',
      showOrderModal: false,
      orderUrl: '',
      limit_amount: '',
      creditAmount: '',
      fee: '',
    };
  },
  created() {
    this.limit_amount = localStorage.getItem('limit_amount');
    this.CardData.forEach((item) => {
      if (item.currency === this.from_currency) {
        this.address = item.address;
      }
    });
    this.fee = BigNumber(this.settingData.card_fee_rate)
      .multipliedBy(this.amount)
      .toFixed(2);
    this.creditAmount = this.amount
      - BigNumber(this.settingData.card_fee_rate)
        .multipliedBy(this.amount)
        .toFixed(2);
  },
  methods: {
    backBtn() {
      this.changeDepositDefaultMenu('showTab');
    },
    submitFun() {
      const params = {
        currency: this.from_currency,
        amount: this.amount,
        channel_name: 'unionintl',
        cust_order_id: Date.now(),
      };
      const authorization = `${neoAuthBefore}${Date.now()}${authAfter}`;
      submitCreateOrder(params, authorization).then((res) => {
        if (res.code === 0) {
          this.showOrderModal = true;
          this.orderUrl = res.result.redirect_url;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.deposit_credit_card_container {
  @media (max-width: 960px) {
    width: 100%;
  }
  .deposit_credit_card_container_content {
    background: #fff;
    border-radius: 4px;
    position: relative;
    .back_container {
      position: absolute;
      font-size: 18px;
      cursor: pointer;
      top: 12px;
    }
    .title {
      font-family: SamsungSharpSans-Bold;
      font-weight: bold;
      font-size: 24px;
      line-height: 50px;
      opacity: 0.8;
      color: #000;
      margin-left: 28px;
    }
  }
  .deposit_credit_container {
    margin-top: 24px;
    padding-left: 28px;
    h3 {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      margin-top: 20px;
    }
    .deposit_steps {
      margin: 15px 0;
      img {
        width: 16px;
        margin-right: 10px;
      }
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.4);
      }
    }
    .tips_container {
      width: 100%;
      border-radius: 8px;
      background: rgba(0, 69, 255, 0.05);
      padding: 30px;
      margin: 30px 0;
      .tips_line {
        margin: 12px 0;
        display: flex;
        justify-content: flex-end;
        .tips_line_left {
          margin-right: 24px;
          width: 120px;
          opacity: 0.6;
        }
        .tips_line_right {
          font-weight: 500;
          width: 120px;
          text-align: right;
        }
      }
      h4 {
        margin: 40px 0 10px 0;
        color: rgba(0, 0, 0, 0.6);
      }
      .tips_bottom {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #0045ff;
      }
      .title_tips {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #ff8e00;
        margin-bottom: 10px;
      }
      .tips_container_center {
        display: flex;
        justify-content: space-between;
        @media (max-width: 960px) {
          flex-direction: column;
        }
        h3 {
          font-family: "Poppins-Regular";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 22px;
          color: #000000;
        }
      }
    }
    .tips_container_tips {
      background: rgba(250, 179, 30, 0.1);
      color: #ff8e00;
    }
    .confirm_container {
      display: flex;
      justify-content: flex-end;
      @media (max-width: 960px) {
        justify-content: center;
      }
      .confirm-btn {
        height: 40px;
        width: 120px;
      }
    }
  }
}
.goToBtn {
  background: #0045ff;
  border-radius: 8px;
  height: 48px;
  width: 120px;
  color: #fff;
  margin-top: 24px;
}
</style>
