<template>
  <div class="wrapper_container">
    <a-tabs :activeKey="activeKey" @change="changeTab">
      <a-tab-pane key="cryptoBuy" tab="Fast Buy">
        <CryptoBuy
          :changeDefaultMenu="changeDefaultMenu"
          :changeDefaultATab="changeDefaultATab"
          :changeReviewListVisible="changeReviewListVisible"
        />
      </a-tab-pane>
      <a-tab-pane key="bindCard" tab="Payment Methods">
        <BindCard ref="bindCard" />
      </a-tab-pane>
      <a-tab-pane key="history" tab="History">
        <FastBuyHistory ref="FastBuyHistory" />
      </a-tab-pane>
    </a-tabs>
    <a-modal
      v-model="blankListVisible"
      centered
      :footer="null"
      :maskClosable="false"
      width="450px"
      title="The account is disabled"
      @cancel="handleBlankListModalCancel"
    >
      <div class="topContainer">
        <div class="tipsContact">
          Your account has been disabled due to violation of terms and
          conditions. Please contact us at
          <a href="mailto:support@inst.money">support@inst.money</a> if you have
          any questions.
        </div>
      </div>
    </a-modal>
    <a-modal
      v-model="reviewListVisible"
      centered
      :footer="null"
      :maskClosable="false"
      width="450px"
      title="Risk warning"
      @cancel="handleReviewListModalCancel"
    >
      <div class="topContainer">
        <div class="wrapper-left-risk">
          <div class="wrapper-left-risk">
            <div class="risk-text">
              <div class="risk-title-tips risk-tips-weight">
                1. You have a high-risk order, please send a photo as specified
                below to
                <a class="footerLink" href="mailto:support@inst.money">
                  support@inst.money
                </a>
                within 48 hours.
              </div>
              <div class="risk-tips">
                &nbsp;&nbsp;(1) A photo of you holding your passport and a
                handwritten note.
              </div>
              <div class="risk-tips">
                &nbsp;&nbsp;(2) Content on the note: Received item purchased
                from INST, by [your name]
              </div>
              <div class="risk-tips">
                &nbsp;&nbsp;(3) Make sure your face on the photo, your photo on
                the passport and the content on the note are visible and clear.
              </div>
              <div class="risk-tips risk-tips-img">
                <img src="@/assets/passwordHoldImg.png" alt="" />
              </div>
              <div class="risk-tips risk-tips-weight">
                2. If you don’t provide the photo within the time limit, we will
                refund this order, and the processing fee will not be returned.
              </div>
              <div class="risk-bottom">
                <a-button
                  type="danger"
                  class="risk-button"
                  @click="setUserFeedback(1)"
                  >Refund</a-button
                >
                <a-button
                  type="primary"
                  class="risk-button"
                  @click="setUserFeedback(99)"
                >
                  <a href="mailto:support@inst.money"> Get Support </a>
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getUserStatusController, userFeedback } from '@/utils/api';
import CryptoBuy from './components/CryptoBuy.vue';
import FastBuyHistory from './components/FastBuyHistory.vue';
import BindCard from '../BindCard/index.vue';

export default {
  name: 'Crypto',
  props: {
    changeDefaultMenu: {
      type: Function,
      require: false,
    },
  },
  data() {
    return {
      activeKey: 'cryptoBuy',
      blankListVisible: false,
      reviewListVisible: false,
    };
  },
  components: {
    CryptoBuy,
    FastBuyHistory,
    BindCard,
  },
  created() {
    getUserStatusController().then((res) => {
      if (res.code === 310001) {
        if (!sessionStorage.getItem('isClickedRefund')) {
          this.reviewListVisible = true;
        }
      } else if (res.code === 310002) {
        this.blankListVisible = true;
      }
    });
  },
  methods: {
    changeTab(e) {
      this.activeKey = e;
      if (e === 'history') {
        this.$refs.FastBuyHistory.searchHistory();
      } else if (e === 'bindCard') {
        this.$refs.bindCard.reSetDefaultMenu();
      }
    },
    changeDefaultATab(val) {
      this.activeKey = val;
    },
    handleBlankListModalCancel() {
      this.blankListVisible = false;
      this.changeDefaultMenu('identification');
    },
    handleReviewListModalCancel() {
      this.reviewListVisible = false;
    },
    changeReviewListVisible() {
      this.reviewListVisible = true;
    },
    setUserFeedback(value) {
      const params = {
        status: value,
      };
      userFeedback(params).then((res) => {
        if (res.code === 0) {
          this.$message.success('success');
          this.reviewListVisible = false;
          sessionStorage.setItem('isClickedRefund', true);
        } else {
          this.$message.error(`${res.msg}`);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper_container {
  display: flex;
  flex: 1;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  min-height: 800px;
  background: #fff;
  padding: 60px;
  @media (max-width: 960px) {
    height: auto;
    padding: 24px;
  }
}
.topContainer {
  .tipsContact {
    font-weight: 500;
    color: #000;
    font-size: 14px;
    line-height: 16px;
  }
  .wrapper-left-risk {
    display: flex;
    align-items: flex-start;
    text-align: left;
    .tips_img {
      margin-right: 8px;
    }
    .risk-text {
      flex: 1;
      .risk-title {
        font-size: 16px;
        font-weight: 500;
      }
      .risk-tips {
        margin-top: 8px;
        img {
          width: 240px;
        }
      }
      .risk-tips-img {
        text-align: center;
      }
      .risk-tips-weight {
        color: #000;
      }
    }
  }
}
.risk-bottom {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
  .risk-button {
    width: 120px;
    height: 48px;
    font-weight: bold;
  }
}
</style>
