<template>
  <div class="wrapper_send_funds">
    <a-spin :spinning="loading">
      <div class="tips_container" v-if="status !== 2 || !gaStatus">
        <div class="tips">
          {{ amount }}
          In order to ensure the safety of your assets, to minimize the risk of
          the account, you need to complete the following conditions before you
          can carry out legal currency business operations：
        </div>
        <div class="tips_btn_container" v-if="status !== 2">
          <div class="tips_btn">
            <img src="@/assets/tips_svg.svg" alt="" />
            <div class="tips_w">Complete Authentication</div>
          </div>
          <div class="tips_btn tips_btn_right" @click="gotoVerify">
            <div class="tips_g">Verify</div>
            <img src="@/assets/tips_right.svg" alt="" />
          </div>
        </div>
        <div class="tips_btn_container" v-if="!gaStatus">
          <div class="tips_btn">
            <img src="@/assets/tips_svg.svg" alt="" />
            <div class="tips_w">Open at least 2FA authentication</div>
          </div>
          <div class="tips_btn tips_btn_right" @click="goAuth">
            <div class="tips_g">Enable</div>
            <img src="@/assets/tips_right.svg" alt="" />
          </div>
        </div>
      </div>
    </a-spin>
    <div class="title_top" v-if="step === 1">
      No fees for transactions between Inst accounts
    </div>
    <div v-show="step === 1">
      <a-form :form="formSelect">
        <div class="send_container">
          <div class="select-container">
            <div class="title">Choose wallet</div>
            <div class="currency_wallet">
              <a-form-item>
                <a-select
                  class="spendClass"
                  :value="wallet_value"
                  @change="walletValueChange"
                  placeholder="Please select your wallet"
                >
                  <a-select-option
                    v-for="item of walletOption"
                    :value="item.value"
                    :key="item.value"
                  >
                    <img :src="item.logo" class="selectIcon" /> {{ item.label }}
                  </a-select-option>
                  <div slot="suffixIcon">
                    <div class="suffixIconSty">
                      <span class="balance">{{ balance }}</span>
                      <span class="currency">{{ currency }}</span>
                      <img src="@/assets/down_jian.svg" alt="" />
                    </div>
                  </div>
                </a-select>
              </a-form-item>
            </div>
          </div>
          <div class="select-container">
            <div class="title">Amount of transfer</div>
            <div class="currency_wallet">
              <a-form-item>
                <a-input
                  placeholder="00.00"
                  size="large"
                  type="number"
                  @input="amountChange"
                  v-decorator="[
                    'amount',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter transfer amount',
                        },
                        { validator: checkAmount },
                      ],
                    },
                  ]"
                >
                  <div slot="suffix" class="inputSuffixSty">{{ currency }}</div>
                </a-input>
              </a-form-item>
            </div>
          </div>
          <div class="select-container"  >
            <div class="title" >
              Specify the recipient's email
            </div>
            <div class="currency_wallet">
              <a-radio-group
                :options="methodOption"
                :default-value="method"
                @change="onRadioChange"
                v-show="false"
              />
              <a-form-item class="formItemLast">
                <a-input
                  :disabled="method === 'card'"
                  size="large"
                  @input="emailChange"
                  :placeholder="
                    method === 'card'
                      ? 'please enter card number'
                      : 'please enter email'
                  "
                  class="currency_amount"
                  v-decorator="[
                    'email',
                    {
                      rules: [
                        {
                          required: true,
                          message:
                            'Please enter the specify the recipients wallet or email transfer',
                        },
                        {
                          max: 32,
                          message: 'The length cannot exceed 32 characters',
                        },
                      ],
                    },
                  ]"
                />
                <div
                  class="link"
                  v-if="!cardNo && method === 'card'"
                  @click="goMyCard"
                >
                  You do not have an Inst card, please submit your card
                  application first.
                </div>
              </a-form-item>
            </div>
          </div>
        </div>
        <div class="note_container">
          <div class="title">Note</div>
          <div class="not_input">
            <a-form-item>
              <a-input
                size="large"
                @change="noteChange"
                v-decorator="[
                  'note',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter note',
                      },
                    ],
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </div>
        </div>
        <!-- <div class="note_bottom_link">
          <a-form-item>
            <a-checkbox
              v-decorator="[
                'checkbox',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please agree to the terms of service first',
                    },
                  ],
                },
              ]"
            />
            <span class="link">Save Recipient's Details </span>
          </a-form-item>
        </div> -->
      </a-form>
      <div class="bottom_container">
        <a-button
          class="deposit-btn"
          type="primary"
          :disabled="status !== 2 || !gaStatus"
          @click="submit"
          >Continue</a-button
        >
      </div>
    </div>
    <div v-show="step === 2">
      <a-form :form="verifyGoogleAuthForm" @submit="handleVerifyGoogleSubmit">
        <div class="step_confirm_container">
          <div class="details_column">
            <div class="details_column_val">
              <div class="title">From Wallet</div>
              <div class="value">{{ address }}</div>
            </div>
            <div class="details_column_val">
              <div class="title">Amount of transfer</div>
              <div class="value">{{ amount }} {{ currency }}</div>
            </div>
          </div>
          <div class="details_column">
            <div class="details_column_val">
              <div class="title">
                To Email
              </div>
              <div class="value">{{ email }}</div>
            </div>
            <div class="details_column_val details_column_val_last">
              <div class="title">Amount to be credited</div>
              <div class="value">{{ amount }} {{ currency }}</div>
            </div>
          </div>
        </div>
        <div class="verify_container">
          <div class="google_input_title">Email Verification Code</div>
          <a-form-item label="">
            <a-row :gutter="8">
              <a-col :span="20">
                <a-input
                  size="large"
                  v-decorator="[
                    'email-code',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter the captcha you got',
                        },
                      ],
                    },
                  ]"
                />
              </a-col>
              <a-col :span="4">
                <a-button
                  class="goToBtn sendMail"
                  type="primary"
                  @click="getCaptcha"
                  :loading="sendEmailLoading"
                  :disabled="sendEmailDisabled"
                  >{{ sendEmailText }}</a-button
                >
              </a-col>
            </a-row>
          </a-form-item>
          <div class="google_input_title" v-if="gaStatus">
            Google Authenticator Verification Code
          </div>
          <a-form-item label="" v-if="gaStatus">
            <a-input
              class="google_input"
              placeholder="Please enter the six-digit code from Google Authenticator"
              size="large"
              v-decorator="[
                'google_code',
                {
                  rules: [
                    {
                      required: true,
                      len: 6,
                      message:
                        'Please enter the six-digit code from Google Authenticator',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <div class="verify_tips">Note: {{ note }}</div>
          <a-form-item>
            <div class="confirmContainer">
              <div class="back_container" @click="goBackStep">
                <img src="@/assets/back_g.svg" alt="" />
                <span>Change details</span>
              </div>
              <a-button
                class="goToBtn"
                html-type="submit"
                type="primary"
                :loading="submitLoading"
              >
                Confirm
              </a-button>
            </div>
          </a-form-item>
        </div>
      </a-form>
    </div>
    <div v-show="step === 3">
      <div class="success_result_container">
        <img src="@/assets/success_icon.svg" alt="" />
        <div class="success_result">The funds have successfully sent</div>
      </div>
      <div class="step_confirm_container">
        <div class="details_column">
          <div class="details_column_val">
            <div class="title">From Wallet</div>
            <div class="value">{{ address }}</div>
          </div>
          <div class="details_column_val details_column_val_last">
            <div class="title">Amount of transfer</div>
            <div class="value">{{ amount }} {{ currency }}</div>
          </div>
        </div>
        <div class="details_column">
          <div class="details_column_val">
            <div class="title">
              To Email
            </div>
            <div class="value">{{ email }}</div>
          </div>
          <div class="details_column_val details_column_val_last">
            <div class="title">Amount to be credited</div>
            <div class="value">{{ amount }} {{ currency }}</div>
          </div>
        </div>
      </div>
      <div class="verify_tips">Note: {{ note }}</div>
      <div class="confirmContainer confirmContainer_step3">
        <a-button class="goToBtn" @click="goBackStep" type="primary">
          Confirm
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getGaStatus,
  transferWallet,
  getCardList,
  sendEmail,
} from '@/utils/api';
import { regTwoPoint } from '@/utils/reg';
import USD from '@/assets/USD.svg';
import EUR from '@/assets/EUR.svg';

export default {
  name: 'SendFunds',
  components: {},
  props: {
    changeDefaultMenu: {
      type: Function,
      require: false,
    },
    wallets: {
      type: Array,
      require: true,
    },
    status: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      formSelect: this.$form.createForm(this, { name: 'coordinated' }),
      verifyGoogleAuthForm: this.$form.createForm(this, {
        name: 'verifyGoogleAuthFormName',
      }),
      step: 1,
      loading: true,
      gaStatus: 0,
      amount: '',
      email: '',
      cardNo: '',
      note: '',
      address: '',
      wallet_value: 'USD',
      balance: '0',
      currency: 'USD',
      walletOption: [],
      method: 'email',
      methodOption: [
        { label: 'Email', value: 'email' },
        // { label: 'Card', value: 'card' },
      ],
      submitLoading: false,
      optionsTest: { force: true },
      sendEmailLoading: false,
      sendEmailDisabled: false,
      mail_code_token: '',
      sendEmailText: 'Send',
      timer: null,
      defaultTime: 60,
    };
  },
  created() {
    const mail = localStorage.getItem('inst_mail');
    getGaStatus(mail).then((res) => {
      this.loading = false;
      if (res.code === 0) {
        this.gaStatus = res.result.ga_enable;
      }
    });
    this.wallets.forEach((item) => {
      if (item.fiat === 'Dollar') {
        this.walletOption.push({
          value: 'USD',
          label: 'USD',
          logo: USD,
          balance: item.balance,
          address: item.address,
        });
        this.balance = item.balance;
        this.address = item.address;
      } else if (item.fiat === 'Euro') {
        this.walletOption.push({
          value: 'Euro',
          label: 'EUR',
          logo: EUR,
          balance: item.balance,
          address: item.address,
        });
      } else {
        this.walletOption.push({
          value: item,
          label: item,
          logo: '',
          balance: item.balance,
          address: item.address,
        });
      }
    });
    getCardList().then((res) => {
      if (res.code === 0) {
        res.result.forEach((item) => {
          if (item.card_type === 0) {
            this.email = item.card_no;
            this.cardNo = item.card_no;
            // this.formSelect.setFieldsValue({
            //   email: item.card_no,
            // });
          }
        });
      }
    });
  },
  methods: {
    gotoVerify() {
      this.changeDefaultMenu('identification');
    },
    goAuth() {
      this.changeDefaultMenu('security');
    },
    goMyCard() {
      this.changeDefaultMenu('myCard');
    },
    methodChange(value) {
      this.method = value;
    },
    walletValueChange(value) {
      this.wallet_value = value;
      if (value === 'Euro') {
        this.currency = 'EUR';
      } else if (value === 'USD') {
        this.currency = 'USD';
      }
      this.walletOption.forEach((item) => {
        if (item.value === value) {
          this.address = item.address;
          this.balance = item.balance;
        }
      });
      this.formSelect.validateFields(['amount'], this.optionsTest);
    },
    amountChange(value) {
      if (regTwoPoint.test(value.target.value)) {
        this.amount = value.target.value;
        this.formSelect.setFieldsValue({
          amount: value.target.value,
        });
      } else if (value.target.value === '') {
        // fixed bug: when input is empty, the amount should be empty
        this.amount = '';
        this.formSelect.setFieldsValue({
          amount: '',
        });
      } else {
        console.log(value.target.value, Number(value.target.value).toFixed(2));
        this.amount = Number(value.target.value).toFixed(2);
        this.formSelect.setFieldsValue({
          amount: Number(value.target.value).toFixed(2),
        });
      }
      this.formSelect.validateFields(['amount'], this.optionsTest);
    },
    checkAmount(rule, value, callback) {
      if (Number(value) > Number(this.balance) || Number(value) === 0) {
        callback(`please enter an amount between 0 and ${this.balance}`);
      } else {
        callback();
      }
    },
    onRadioChange(e) {
      if (e.target.value === 'card') {
        this.email = this.cardNo;
        this.formSelect.setFieldsValue({
          email: this.cardNo,
        });
      } else {
        this.email = '';
        this.formSelect.setFieldsValue({
          email: '',
        });
      }
      this.method = e.target.value;
    },
    emailChange(value) {
      this.formSelect.validateFields(
        ['email'],
        this.optionsTest,
        (err) => {
          if (!err) {
            const isValidEmailCharRegx = /[^a-zA-Z0-9.@_]/g;
            const wallet = value.target.value.replace(isValidEmailCharRegx, '');
            console.log(value.target.value, wallet);
            this.formSelect.setFieldsValue({
              email: wallet,
            });
            this.email = wallet;
          }
        },
      );
    },
    noteChange(value) {
      this.note = value.target.value;
    },
    submit() {
      this.formSelect.validateFields((err) => {
        if (!err) {
          this.step = 2;
        }
      });
    },
    goBackStep() {
      this.step = 1;
    },
    getCaptcha() {
      this.sendEmailLoading = true;
      this.sendEmailDisabled = true;
      const mail = localStorage.getItem('inst_mail');
      const params = {
        mail,
      };
      sendEmail(params)
        .then((res) => {
          this.mail_code_token = res.result;
          this.sendEmailLoading = false;
          this.$message.success('send email success');
          this.timer = setInterval(this.CountDown, 1000);
        })
        .catch(
          () => {
            this.sendEmailLoading = false;
            this.sendEmailDisabled = false;
          },
        );
    },
    CountDown() {
      if (this.defaultTime > 0) {
        this.defaultTime = this.defaultTime - 1;
        this.sendEmailText = this.defaultTime;
      } else {
        clearInterval(this.timer);
        this.defaultTime = 60;
        this.sendEmailDisabled = false;
        this.sendEmailText = 'Send';
      }
    },
    handleVerifyGoogleSubmit(e) {
      e.preventDefault();
      this.verifyGoogleAuthForm.validateFields((err, values) => {
        if (!err) {
          this.submitLoading = true;
          const params = {
            currency: this.currency,
            amount: this.amount,
            note: this.note,
            'ga-code': values.google_code,
            mail_code_token: this.mail_code_token,
          };
          if (this.method === 'email') {
            params.mail = this.email;
          } else {
            params.wallet_address = this.email;
          }
          transferWallet(params, values['email-code'])
            .then((res) => {
              this.submitLoading = false;
              if (res.code === 0) {
                this.$message.success('Transfer success');
                this.step = 3;
                this.$emit('refresh');
              }
            })
            .catch(() => {
              this.submitLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper_send_funds {
  @media (max-width: 960px) {
    width: 100%;
  }
  .tips_container {
    width: 100%;
    background: rgba(250, 179, 30, 0.1);
    border-radius: 20px;
    padding: 30px 30px 5px;
    margin-bottom: 30px;
    .tips {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #ff8e00;
      margin-bottom: 30px;
    }
    .tips_btn_container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      .tips_btn {
        display: flex;
      }
      .tips_btn_right {
        cursor: pointer;
      }
      .tips_w {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin-left: 12px;
      }
      .tips_g {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #ff8e00;
        margin-right: 10px;
      }
    }
  }
  .title_top {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff8e00;
  }
  .send_container {
    background: rgba(0, 69, 255, 0.03);
    margin: 20px 0;
    padding: 30px;
    .select-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-bottom: 6px;
      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        mix-blend-mode: normal;
        opacity: 0.6;
        margin-bottom: 10px;
      }
      .currency_wallet {
        width: 420px;
        @media (max-width: 960px) {
          width: 100%;
        }
        .currencySelect {
          width: 100px;
        }
        .spendClass {
          width: 420px;
          @media (max-width: 960px) {
            width: 100%;
          }
        }
        /deep/ .ant-input-lg {
          height: 48px;
          line-height: 48px;
          border-radius: 15px;
        }
        /deep/ .ant-input-group-addon {
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
        }
        .currency_amount {
          // margin-top: 12px;
          /deep/ .ant-input-lg {
            height: 48px;
            line-height: 48px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
          .formItemLast {
            margin-bottom: 0;
          }
        }
        .link {
          color: #0045ff;
          cursor: pointer;
          font-weight: 400;
          text-decoration: underline;
          font-size: 12px;
        }
      }
    }
  }
  .step_confirm_container {
    padding: 30px;
    background: rgba(0, 69, 255, 0.03);
    border-radius: 8px;
    @media (max-width: 960px) {
      padding: 12px;
    }
    .details_column {
      display: flex;
      flex-direction: row;
      .details_column_val {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 20px;
        @media (max-width: 960px) {
          margin: 5px;
        }
        .title {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.6);
          margin-bottom: 5px;
        }
        .value {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #000000;
        }
      }
    }
  }
  .verify_container {
    margin-top: 20px;
    .google_input_title {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      opacity: 0.6;
      color: #000;
    }
  }
  .note_container {
    margin-top: 20px;
    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      opacity: 0.6;
      margin-bottom: 10px;
    }
  }
  .note_bottom_link {
    margin-top: 20px;
    .link {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-left: 10px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  /deep/.ant-select-selection-selected-value {
    font-weight: bold;
  }
  /deep/ .ant-select-selection--single {
    height: 48px;
    border-radius: 15px;
  }
  /deep/ .ant-select-selection--single .ant-select-selection__rendered {
    height: 48px;
    line-height: 48px;
  }
  /deep/ .ant-select-arrow {
    margin-top: 0px;
    top: 14px;
    height: 20px;
    align-items: center;
    display: flex;
  }
  /deep/ .ant-input-lg {
    height: 48px;
    line-height: 48px;
    border-radius: 15px;
  }
  /deep/ .ant-input-suffix {
    height: 46px;
    right: 1px;
    background: #d8d8d8;
    background: #d8d8d8;
    border-radius: 15px;
    width: 100px;
    text-align: center;
    .inputSuffixSty {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }
  .bottom_container {
    margin-top: 40px;
    text-align: center;
    .deposit-btn {
      border-radius: 8px;
      height: 48px;
      width: 400px;
      @media (max-width: 960px) {
        width: 100%;
      }
    }
  }
}

.selectIcon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 10px;
}
.suffixIconSty {
  display: flex;
  align-items: center;
  .balance {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #0045ff;
    margin-right: 10px;
  }
  .currency {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-right: 10px;
  }
}
.google_input {
  height: 60px;
}
.confirmContainer {
  display: flex;
  justify-content: space-between;
  .back_container {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 48px;
    color: #0045ff;
    img {
      margin-right: 8px;
    }
  }
}
.verify_tips {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 33px;
}
.success_result_container {
  width: 100%;
  text-align: center;
  .success_result {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 40px;
  }
}
.confirmContainer_step3 {
  justify-content: center;
}
// /deep/.ant-input {
//   font-weight: bold;
// }
.goToBtn {
  border-radius: 8px;
  height: 48px;
  width: 200px;
}
.sendMail {
  width: 100%;
}
.formItemLast {
  margin-bottom: 0;
  /deep/ .ant-input-disabled:hover {
    background-color: #f5f5f5 !important;
  }
}
</style>
