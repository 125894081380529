<template>
  <div class="wrapper_card">
    <div class="card_container">
      <div class="CardHeader">
        <div class="title">Payment Method</div>
      </div>
      <a-spin tip="Loading..." :spinning="loading">
        <div v-if="status === 1" class="resultContent">
          <div class="resultContainer">
            <a-icon
              class="topImg"
              type="check-circle"
              theme="twoTone"
              :style="{ 'font-size': '48px' }"
            />
            <h2>Your card is verified.</h2>
          </div>
        </div>
        <div v-else-if="status === 4" class="resultContent">
          <div class="resultContainer">
            <a-icon
              type="close-circle"
              theme="twoTone"
              style="font-size: 48px"
            />
            <h2>Sorry, your application was rejected.</h2>
            <h4>Reason: {{ reason }}</h4>
            <a-button class="goToBtn" @click="reApply()">Reapply</a-button>
          </div>
        </div>
        <div v-else class="resultContent">
          <div class="resultContainer">
            <a-icon
              class="topImg"
              type="check-circle"
              theme="twoTone"
              :style="{ 'font-size': '48px' }"
            />
            <h2>Your card is verified.</h2>
            <!-- <a-icon
              class="topImg"
              type="clock-circle"
              theme="twoTone"
              :style="{ 'font-size': '48px' }"
            />
            <h2>Your identity verification is under review.</h2> -->
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
// import { getBindCardInfo } from "@/utils/api";

export default {
  name: 'Card',
  props: {
    changeDefaultMenu: {
      type: Function,
      require: false,
    },
    id: {
      type: Number,
      require: false,
    },
    status: {
      type: Number,
      require: false,
    },
    reason: {
      type: String,
      require: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    // getBindCardInfo(this.id).then((res) => {
    //   this.loading = false;
    //   if (res.code === 0) {
    //     this.status = res.result.status;
    //   }
    // });
  },
  methods: {
    reApply() {
      this.changeDefaultMenu('addNewCard', this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper_card {
  margin: auto;
  min-height: 800px;
  padding: 60px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: #fff;
  @media (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    padding: 12px;
  }
  .card_container {
    max-width: 900px;
    margin: auto;
    .CardHeader {
      display: flex;
      flex-direction: column;
      .title {
        font-family: SamsungSharpSans-Bold;
        font-weight: bold;
        font-size: 40px;
        line-height: 50px;
        text-align: center;
        opacity: 0.8;
        color: #000;
      }
    }
  }
  .resultContent {
    margin-top: 48px;
    .resultContainer {
      text-align: center;
      margin-top: 24px;
      h2 {
        margin: 24px auto;
      }
    }
  }
  .goToBtn {
    background: #0045ff;
    border-radius: 8px;
    height: 48px;
    width: 120px;
    color: #fff;
    margin-top: 24px;
  }
}
</style>
