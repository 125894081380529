<template>
  <div class="wrapper_wallet">
    <a-spin :spinning="loading">
      <div class="wallet_top" v-if="depositMenu === 'showTab'">
        <h2>Wallets</h2>
        <div class="wallet_container">
          <div class="wallet wallet_left" v-for="item in CardDataLeft" :key="item.currency">
            <div class="wallet_top">
              <div class="wallet_v">
                <img :src="item.logo" alt="" />
                {{ item.fiat }}
              </div>
              <div class="wallet_v wallet_v_r">{{ item.balance }}</div>
            </div>
            <div class="wallet_bottom">
              <div class="wallet_v">{{ item.address }}</div>
              <div class="wallet_v wallet_v_r">{{ item.currency }}</div>
            </div>
          </div>
          <div class="wallet wallet_right" v-for="item in CardDataRight" :key="item.currency">
            <div class="wallet_top">
              <div class="wallet_v">
                <img :src="item.logo" alt="" />
                {{ item.fiat }}
              </div>
              <div class="wallet_v wallet_v_r">{{ item.balance }}</div>
            </div>
            <div class="wallet_bottom">
              <div class="wallet_v">{{ item.address }}</div>
              <div class="wallet_v wallet_v_r">{{ item.currency }}</div>
            </div>
          </div>
        </div>
      </div>
      <a-tabs v-if="depositMenu === 'showTab'">
        <a-tab-pane key="1" tab="Deposit Cash">
          <Deposit
            :status="status"
            :changeDepositVal="changeDepositVal"
            :changeDepositDefaultMenu="changeDepositDefaultMenu"
            :changeDefaultMenu="changeDefaultMenu"
            :changeDepositBankTransferMsg="changeDepositBankTransferMsg"
            :settingData="settingData"
          />
        </a-tab-pane>
        <a-tab-pane key="3" tab="Buy Crypto">
          <BuyCrypto :changeDefaultMenu="changeDefaultMenu" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="Send Funds">
          <Send
            :wallets="wallets"
            :status="status"
            :changeDefaultMenu="changeDefaultMenu"
            @refresh="getWallet"
          />
        </a-tab-pane>
        <a-tab-pane key="4" tab="Send to Card">
          <SendCard
            :wallets="wallets"
            :status="status"
            :changeDefaultMenu="changeDefaultMenu"
            @refresh="getWallet"
          />
        </a-tab-pane>
        <!-- <a-tab-pane key="3" tab="Currency exchange"> Coming Soon </a-tab-pane> -->
        <a
          slot="tabBarExtraContent"
          href="javascript:void(0);"
          @click="goHistory"
        >
          History
        </a>
      </a-tabs>
      <DepositCreditCard
        v-if="depositMenu === 'depositCreditCard'"
        :changeDepositDefaultMenu="changeDepositDefaultMenu"
        :changeDefaultMenu="changeDefaultMenu"
        :CardData="CardData"
        :from_currency="from_currency"
        :amount="amount"
        :settingData="settingData"
      />
      <DepositBankTransfer
        v-if="depositMenu === 'depositBankTransfer'"
        :changeDepositDefaultMenu="changeDepositDefaultMenu"
        :changeDefaultMenu="changeDefaultMenu"
        :bankTransferNote="bankTransferNote"
        :bankTransferData="bankTransferData"
        :CardData="CardData"
        :from_currency="from_currency"
        :amount="amount"
        :depositBankName="depositBankName"
      />
      <History
        v-if="depositMenu === 'history'"
        :changeDepositDefaultMenu="changeDepositDefaultMenu"
      />
    </a-spin>
    <a-modal
      v-model="blankListVisible"
      centered
      :footer="null"
      :maskClosable="false"
      width="450px"
      title="The account is disabled"
      @cancel="handleBlankListModalCancel"
    >
      <div class="topContainer">
        <div class="tipsContact">
          Your account has been disabled due to violation of terms and
          conditions. Please contact us at
          <a href="mailto:support@inst.money">support@inst.money</a> if you have
          any questions.
        </div>
      </div>
    </a-modal>
    <a-modal
      v-model="reviewListVisible"
      centered
      :footer="null"
      :maskClosable="false"
      width="450px"
      title="Risk warning"
      @cancel="handleReviewListModalCancel"
    >
      <div class="topContainer">
        <div class="wrapper-left-risk">
          <div class="risk-text">
            <div class="risk-title-tips risk-tips-weight">
              1. You have a high-risk order, please send a photo as specified
              below to
              <a class="footerLink" href="mailto:support@inst.money">
                support@inst.money
              </a>
              within 48 hours.
            </div>
            <div class="risk-tips">
              &nbsp;&nbsp;(1) A photo of you holding your passport and a
              handwritten note.
            </div>
            <div class="risk-tips">
              &nbsp;&nbsp;(2) Content on the note: Received item purchased from
              INST, by [your name]
            </div>
            <div class="risk-tips">
              &nbsp;&nbsp;(3) Make sure your face on the photo, your photo on
              the passport and the content on the note are visible and clear.
            </div>
            <div class="risk-tips risk-tips-img">
              <img src="@/assets/passwordHoldImg.png" alt="" />
            </div>
            <div class="risk-tips risk-tips-weight">
              2. If you don’t provide the photo within the time limit, we will
              refund this order, and the processing fee will not be returned.
            </div>
            <div class="risk-bottom">
              <a-button
                class="risk-button"
                type="danger"
                @click="setUserFeedback(1)"
                >Refund</a-button
              >
              <a-button
                type="primary"
                class="risk-button"
                @click="setUserFeedback(99)"
              >
                <a href="mailto:support@inst.money"> Get Support </a>
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  getUserWallet,
  getUserStatusController,
  userFeedback,
} from '@/utils/api';
import USD from '@/assets/USD.svg';
import EUR from '@/assets/EUR.svg';

import DepositCreditCard from './Deposit/DepositCreditCard.vue';
import DepositBankTransfer from './Deposit/DepositBankTransfer.vue';
import Send from './Send/index.vue';
import SendCard from './SendCard/index.vue';
import History from './History/index.vue';
import BuyCrypto from './BuyCrypto/index.vue';
import Deposit from './Deposit/index.vue';

export default {
  name: 'Wallet',
  props: {
    changeDefaultMenu: {
      type: Function,
      require: false,
    },
  },
  components: {
    Deposit,
    DepositCreditCard,
    DepositBankTransfer,
    History,
    Send,
    BuyCrypto,
    SendCard,
  },
  data() {
    return {
      CardData: [],
      CardDataLeft: [],
      CardDataRight: [],
      status: 0,
      loading: true,
      depositMenu: 'showTab',
      from_currency: '',
      amount: '',
      bankTransferNote: null,
      bankTransferData: null,
      settingData: {},
      wallets: [],
      blankListVisible: false,
      reviewListVisible: false,
      depositBankName: '',
    };
  },
  created() {
    this.getWallet();
    getUserStatusController().then((res) => {
      if (res.code === 310001) {
        if (!sessionStorage.getItem('isClickedRefund')) {
          this.reviewListVisible = true;
        }
      } else if (res.code === 310002) {
        this.blankListVisible = true;
      }
    });
  },
  methods: {
    getWallet() {
      this.wallets = [];
      this.CardDataLeft = [];
      this.CardDataRight = [];
      getUserWallet().then((res) => {
        this.loading = false;
        if (res.code === 0) {
          this.CardData = res.result.wallets;
          this.settingData = res.result.settings;
          this.status = res.result.status;
          this.wallets = res.result.wallets;
          res.result.wallets.forEach((item, index) => {
            const obj = item;
            if (item.currency === 'USD') {
              obj.fiat = 'Dollar';
              obj.logo = USD;
            } else if (item.currency === 'EUR') {
              obj.fiat = 'Euro';
              obj.logo = EUR;
            }
            if (index % 2 === 0) {
              this.CardDataLeft.push(obj);
            } else {
              this.CardDataRight.push(obj);
            }
          });
        }
      });
    },
    changeDepositDefaultMenu(val) {
      this.depositMenu = val;
    },
    changeDepositVal(amount, fromCurrency, depositBankName) {
      this.amount = amount;
      this.from_currency = fromCurrency;
      this.depositBankName = depositBankName;
    },
    changeDepositBankTransferMsg(note, bankTransferData) {
      this.bankTransferNote = note;
      this.bankTransferData = bankTransferData;
    },
    goHistory(e) {
      e.preventDefault();
      this.depositMenu = 'history';
    },
    handleBlankListModalCancel() {
      this.blankListVisible = false;
      this.changeDefaultMenu('identification');
    },
    handleReviewListModalCancel() {
      this.reviewListVisible = false;
    },
    setUserFeedback(value) {
      const params = {
        status: value,
      };
      userFeedback(params).then((res) => {
        if (res.code === 0) {
          this.$message.success('success');
          this.reviewListVisible = false;
          sessionStorage.setItem('isClickedRefund', true);
        } else {
          this.$message.error(`${res.msg}`);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper_wallet {
  margin: auto;
  min-height: 800px;
  padding: 60px;
  // padding-bottom: 0;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: #fff;
  @media (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    padding: 12px;
  }
  .wallet_top {
    margin-bottom: 40px;
    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: #000000;
      mix-blend-mode: normal;
      opacity: 0.8;
      margin-bottom: 20px;
    }
    .wallet_container {
      display: flex;
      justify-content: space-between;
      @media (max-width: 960px) {
        flex-direction: column;
      }
      .wallet {
        flex: 1;
        height: 110px;
        left: 640px;
        top: 250px;
        background: rgba(0, 69, 255, 0.03);
        border-radius: 20px;
        padding: 30px;
        .wallet_top {
          display: flex;
          justify-content: space-around;
          margin-bottom: 15px;
          .wallet_v {
            display: flex;
            flex: 1;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            img {
              width: 24px;
              margin-right: 4px;
            }
          }
          .wallet_v_r {
            justify-content: flex-end;
          }
        }
        .wallet_bottom {
          display: flex;
          justify-content: space-around;
          .wallet_v {
            flex: 1;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.3);
          }
          .wallet_v_r {
            text-align: end;
          }
        }
      }
      .wallet_left {
        margin-right: 30px;
        @media (max-width: 960px) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
      .wallet_right {
        margin-left: 30px;
        @media (max-width: 960px) {
          margin-left: 0;
        }
      }
    }
  }
}
.topContainer {
  .tipsContact {
    font-weight: 500;
    color: #000;
    font-size: 14px;
    line-height: 16px;
  }
  .wrapper-left-risk {
    display: flex;
    align-items: flex-start;
    text-align: left;
    .tips_img {
      margin-right: 8px;
    }
    .risk-text {
      flex: 1;
      .risk-title {
        font-size: 16px;
        font-weight: 500;
      }
      .risk-tips {
        margin-top: 8px;
        img {
          width: 240px;
        }
      }
      .risk-tips-img {
        text-align: center;
      }
      .risk-tips-weight {
        color: #000;
      }
    }
  }
}
.risk-bottom {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
  .risk-button {
    width: 120px;
    height: 48px;
    font-weight: bold;
  }
}
</style>
