var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"changePassword-wrapper"},[_c('div',{staticClass:"backContainer",on:{"click":_vm.backBtn}},[_c('a-icon',{attrs:{"type":"arrow-left"}})],1),_c('h2',[_vm._v("Change Password")]),_c('div',{staticClass:"register-container"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Current Password","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'old_password',
            {
              rules: [
                {
                  required: true,
                  message: 'Please enter your current password!',
                },
              ],
            },
          ]),expression:"[\n            'old_password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Please enter your current password!',\n                },\n              ],\n            },\n          ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{attrs:{"label":"New Password","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: 'Please enter your new password!',
                },
                {
                  validator: _vm.validateToNextPassword,
                },
              ],
            },
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Please enter your new password!',\n                },\n                {\n                  validator: validateToNextPassword,\n                },\n              ],\n            },\n          ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{attrs:{"label":"Confirm Password","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirm',
            {
              rules: [
                {
                  required: true,
                  message: 'Please confirm your new password!',
                },
                {
                  validator: _vm.compareToFirstPassword,
                },
              ],
            },
          ]),expression:"[\n            'confirm',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Please confirm your new password!',\n                },\n                {\n                  validator: compareToFirstPassword,\n                },\n              ],\n            },\n          ]"}],attrs:{"type":"password"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',{staticClass:"btnContainer"},[_c('a-button',{staticClass:"goToBtn",attrs:{"html-type":"submit"}},[_vm._v(" Confirm ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }