var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"deposit_container"},[(_vm.status !== 2)?_c('div',{staticClass:"tips_container"},[_c('div',{staticClass:"tips"},[_vm._v(" In order to ensure the safety of your assets, to minimize the risk of the account, you need to complete the following conditions before you can carry out legal currency business operations： ")]),_c('div',{staticClass:"tips_btn_container"},[_vm._m(0),_c('div',{staticClass:"tips_btn tips_btn_right",on:{"click":_vm.gotoVerify}},[_c('div',{staticClass:"tips_g"},[_vm._v("Verify")]),_c('img',{attrs:{"src":require("@/assets/tips_right.svg"),"alt":""}})])])]):_vm._e(),_c('div',{staticClass:"select-container"},[_c('div',{staticClass:"select-container_flex select-container_flex_left"},[_c('div',{staticClass:"title"},[_vm._v("Currency")]),_c('div',{staticClass:"currency_country"},[_c('a-form',{attrs:{"form":_vm.formSelect}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'amount',
                {
                  rules: [{ validator: _vm.checkAmount }],
                },
              ]),expression:"[\n                'amount',\n                {\n                  rules: [{ validator: checkAmount }],\n                },\n              ]"}],attrs:{"placeholder":"00.00","value":_vm.form.amount,"size":"large","type":"number"},on:{"change":_vm.amountChange}},[_c('a-select',{staticClass:"spendClass",attrs:{"slot":"addonAfter","value":_vm.form.from_currency},on:{"change":_vm.fromCurrencyChange},slot:"addonAfter"},_vm._l((_vm.fromCurrencyOption),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_c('img',{staticClass:"selectIcon",attrs:{"src":item.logo}}),_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1)],1)],1)]),_c('div',{staticClass:"select-container_flex"})]),_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"title"},[_vm._v("Credit/debit cards")]),_c('a-table',{attrs:{"columns":_vm.CreditCardsColumns,"data-source":_vm.creditData,"rowKey":"completion","pagination":false},scopedSlots:_vm._u([{key:"logo",fn:function(text){return _c('span',{},[_c('img',{attrs:{"src":text,"alt":""}}),_vm._v(" Mastercard ")])}}])},[_c('a-button',{staticClass:"deposit-btn",attrs:{"slot":"deposit","disabled":_vm.status !== 2,"type":"primary"},on:{"click":_vm.goDepositCredit},slot:"deposit"},[_vm._v("Deposit")])],1)],1),_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"title"},[_vm._v("Bank transfers")]),_c('a-table',{attrs:{"columns":_vm.BankCardsColumns,"data-source":_vm.BankCardsData,"rowKey":"completion","pagination":false,"loading":_vm.bankTransferLoading},scopedSlots:_vm._u([{key:"logo",fn:function(text, record){return _c('span',{},[_c('img',{style:({ width: '36px' }),attrs:{"src":text,"alt":""}}),_vm._v(" "+_vm._s(record.deposit)+" ")])}},{key:"deposit",fn:function(text){return _c('a-button',{staticClass:"deposit-btn",attrs:{"disabled":_vm.status !== 2 ||
          (text === 'ACH' && _vm.form.from_currency === 'EUR') ||
          (text === 'SEPA' && _vm.form.from_currency === 'USD'),"type":"primary"},on:{"click":function($event){return _vm.goDepositBankTransfer(text)}}},[_vm._v("Deposit")])}}])})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tips_btn"},[_c('img',{attrs:{"src":require("@/assets/tips_svg.svg"),"alt":""}}),_c('div',{staticClass:"tips_w"},[_vm._v("Complete LV2 Authentication")])])
}]

export { render, staticRenderFns }