<template>
  <div class="security" id="why-init-container">
    <div class="wrapper right">
      <div class="row right maRight">
        <div class="info">
          <div class="info_filter"></div>
          <div class="info__title g-font-mid">
            <span> One-stop shop for cryptos </span>
          </div>
          <div class="info__content">
            <div class="shop-tips">
              Use any major bank debit card and crypto card or third-party
              payment apps to purchase cryptos. We provide fast exchange between
              fiat currencies and all popular cryptos including BTC, ETH, USDT
              and BNB Start your journey into the crypto world now.
            </div>
          </div>
          <div class="circle-img-container">
            <div class="circle-img">
              <img class="circle-visa" src="@/assets/visa.png" alt="" />
              <img
                class="circle-visa"
                src="@/assets/MasterCard.png"
                alt=""
              />
              <img
                class="circle-visa"
                src="@/assets/ApplePay.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <a-spin :spinning="loading">
          <div class="info--img__wrapper">
            <div class="wrapper-left" v-if="showStep1 === true">
              <div class="wrapper-left-item">
                <div class="select-label-top">Buy</div>
                <div class="select-label">I want to spend</div>
                <div class="select-container-top">
                  <a-input
                    placeholder="00.00"
                    :value="form.amount"
                    size="large"
                    type="number"
                    @change="amountChange"
                    :disabled="!selectDisabled"
                  >
                    <a-select
                      slot="addonAfter"
                      :value="form.from_currency"
                      @change="fromCurrencyChange"
                      class="currencySelect"
                    >
                      <a-select-option
                        v-for="item of fromCurrencyOption"
                        :value="item.value"
                        :key="item.value"
                      >
                        <img :src="item.logo" class="selectIcon" />
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                  </a-input>
                </div>
              </div>
              <div class="errorTipsContent">
                <div v-if="showMaxErrorTips">
                  <div class="triangle" />
                  <div class="maxTips">
                    Maximum allowed: {{ numeral(maxAmount).format("0,0") }}
                    {{ form.from_currency }}
                  </div>
                </div>
                <div v-if="showMinErrorTips">
                  <div class="triangle" />
                  <div class="maxTips">
                    Minimum allowed: {{ numeral(minAmount).format("0,0") }}
                    {{ form.from_currency }}
                  </div>
                </div>
              </div>
              <div class="wrapper-left-item">
                <div class="select-label">I want to buy</div>
                <div class="select-container-top">
                  <a-input
                    :placeholder="buyCurrencyPlaceholder"
                    :value="form.approximatelyAmount"
                    type="number"
                    size="large"
                    :disabled="selectDisabled"
                    @change="toCurrencyAmountChange"
                  >
                    <a-select
                      slot="addonAfter"
                      :value="form.to_currency"
                      @change="toCurrencyChange"
                      class="currencySelect"
                    >
                      <a-select-option
                        v-for="item of toCurrencyOption"
                        :value="item.value"
                        :key="item.value"
                      >
                        <img :src="item.logo" class="selectIcon" />
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                  </a-input>
                </div>
              </div>
              <div class="rateTipsLeft" v-if="form.to_currency === 'NEO'">
                <img src="@/assets/jinggao.svg" alt="jinggao" />
                <span class="rateTipsTitle rateTipsTitleLeft"
                  >NEO is an indivisible currency. It means that it should
                  represent a whole number.</span
                >
              </div>
              <div class="rateTips">
                <div class="rateTipsList">
                  <div class="rateTipsListLeft">Purchase limit:</div>
                  <div class="rateTipsListRight">
                    {{ limit_amount }} {{ form.from_currency }} limit
                  </div>
                </div>
                <div class="rateTipsList">
                  <div class="rateTipsListLeft">Final exchange rate:</div>
                  <div class="rateTipsListRight">
                    1 {{ form.to_currency }} ≈ {{ Number(rate).toFixed(4) }}
                    {{ form.from_currency }}
                  </div>
                </div>
                <div class="rateTipsList">
                  <div class="rateTipsListLeft">
                    Processing fee:
                    <a-tooltip>
                      <template slot="title">
                        If the orders is refunded,the processing fee will not be
                        returned
                      </template>
                      <a-icon type="info-circle" />
                    </a-tooltip>
                  </div>
                  <div class="rateTipsListRight">
                    ~ {{ processing_fee_amount }}
                    {{ form.from_currency }}
                  </div>
                </div>
                <div class="rateTipsList">
                  <div class="rateTipsListLeft">Network fee:</div>
                  <div class="rateTipsListRight">
                    ~ {{ network_fee_amount }} {{ form.from_currency }}
                  </div>
                </div>
                <div class="rateTipsList">
                  <div class="rateTipsListLeft">Delivery time:</div>
                  <div class="rateTipsListRight">0.25 - 8 hours</div>
                </div>
              </div>

              <div class="wrapper-left-item wrapper-left-item-last">
                <a-button class="goToBtn step1Btn" @click="submitStep1()"
                  >{{ PayTitle }}
                </a-button>
              </div>
            </div>
            <div class="wrapper-left" v-if="showStep2 === true">
              <div class="wrapper-left-item">
                <div class="select-label-top">
                  <img
                    src="@/assets/back.svg"
                    alt=""
                    @click="
                      () => {
                        this.showStep1 = true;
                        this.showStep2 = false;
                      }
                    "
                  />
                  Enter Wallet Address
                </div>
              </div>
              <div class="wrapper-left-item">
                <div class="select-label select-label-option">
                  If your payment is successful, the cryptos will be sent to
                  your wallet between 15 minutes to 2 hours. Kindly direct any
                  inquiries to support@inst.money.
                </div>
              </div>
              <div class="wrapper-left-item">
                <div class="select-label">
                  {{ form.to_currency }} Wallet Address
                </div>
                <div class="select-container select-container-address">
                  <a-input
                    placeholder="Wallet Address"
                    :value="form.address"
                    size="large"
                    @change="toAddressChange"
                  />
                  <p class="addressTips" v-if="showAddressTips">
                    Please enter correct wallet address
                  </p>
                </div>
              </div>
              <div class="buyWarning">
                <div class="warningTop">
                  <img src="@/assets/warningImg.svg" alt="warning" />
                  Attention! Beware of scams!
                </div>
                <div class="warningBottom">
                  Please make sure you have the credentials of the wallet you
                  entered, and you have control over the funds in it. Do not
                  enter an address that is given to you.
                </div>
              </div>
              <div class="wrapper-left-item wrapper-left-item-last">
                <a-button
                  :class="goToBtnDisabled ? 'goToBtnDisabled' : 'goToBtn'"
                  @click="submitGetOrder()"
                  >Continue
                </a-button>
              </div>
            </div>
            <div class="wrapper-left" v-if="showStep3 === true">
              <div class="wrapper-left-item">
                <div class="select-label-top">
                  <img
                    src="@/assets/back.svg"
                    alt=""
                    @click="
                      () => {
                        this.showStep2 = true;
                        this.showStep3 = false;
                        this.cardNumber = '';
                      }
                    "
                  />
                  Payment Details
                </div>
              </div>
              <div class="container">
                <div class="topTips">
                  <div class="left">
                    <div>
                      <img
                        v-if="form.from_currency === 'USD'"
                        :src="USD"
                        alt=""
                      />
                      <img
                        v-else-if="form.from_currency === 'EUR'"
                        :src="EUR"
                        alt=""
                      />
                      <img
                        v-else-if="form.from_currency === 'GBP'"
                        :src="GBP"
                        alt=""
                      />
                      {{ form.from_currency }}
                    </div>
                    <div>{{ form.amount }}</div>
                  </div>
                  <div class="center">
                    <img src="@/assets/rightIcon.svg" alt="" />
                  </div>
                  <div class="right">
                    <div>
                      <img
                        v-if="form.to_currency === 'NEO'"
                        :src="NEO"
                        alt=""
                      />
                      <img
                        v-if="form.to_currency === 'ETH'"
                        :src="ETH"
                        alt=""
                      />
                      <img
                        v-else-if="form.to_currency === 'GAS'"
                        :src="GAS"
                        alt=""
                      />
                      <img
                        v-else-if="form.to_currency === 'USDT-TRC20'"
                        :src="USDT"
                        alt=""
                      />
                      <img
                        v-else-if="form.to_currency === 'bNEO'"
                        :src="BNEO"
                        alt=""
                      />
                      <img
                        v-else-if="form.to_currency === 'FLM'"
                        :src="FLM"
                        alt=""
                      />
                      <img
                        v-else-if="form.to_currency === 'fUSDT'"
                        :src="USDT"
                        alt=""
                      />
                      {{ form.to_currency }}
                    </div>
                    <div>{{ form.detailApproximatelyAmount }}</div>
                  </div>
                </div>
                <div class="bottomCardContainer">
                  <div class="top_tips">
                    <img src="@/assets/tips_svg.svg" alt="jinggao" />
                    <div class="top_tips_text">
                      If the order is refunded,the processing fee will not be
                      returned
                    </div>
                  </div>
                  <div class="title">Pay With</div>
                  <div class="payWithSty">
                    <a-radio-group @change="selectChange">
                      <a-list
                        class="demo-loadmore-list"
                        :loading="cardListLoading"
                        item-layout="horizontal"
                        :data-source="wallets"
                      >
                        <a-list-item
                          class="renderItem renderItem_wallet"
                          slot="renderItem"
                          slot-scope=""
                        >
                          <a-list-item-meta>
                            <div class="renderItemTitle" slot="title">
                              <img
                                src="@/assets/walletCircle.svg"
                                alt=""
                              />
                              Wallet
                            </div>
                          </a-list-item-meta>
                          <a-radio value="wallet">
                            <a slot="actions"></a>
                          </a-radio>
                        </a-list-item>
                      </a-list>
                      <div class="wallet_bottom" v-if="chooseWallet">
                        <div class="wallet_left">{{ wallets[0].address }}</div>
                        <div class="wallet_right">
                          <div class="wallet_right_balance">
                            {{ wallets[0].balance }} {{ form.from_currency }}
                          </div>
                          <div class="wallet_right_deposit" @click="goWallet">
                            Deposit
                          </div>
                        </div>
                      </div>

                      <a-menu
                        mode="inline"
                        class="menuSty"
                        @openChange="menuChange"
                        :openKeys="openKeys"
                      >
                        <a-sub-menu class="menuSubSty" key="sub1">
                          <span slot="title"
                            ><img
                              class="menuSubIcon"
                              src="@/assets/masterCardCircle.svg"
                              alt=""
                            />
                            MasterCard</span
                          >
                          <span slot="icon"
                            ><img
                              class="down_icon"
                              src="@/assets/down_icon.svg"
                              alt=""
                          /></span>
                          <a-list
                            class="demo-loadmore-list"
                            :loading="cardListLoading"
                            item-layout="horizontal"
                            :data-source="cardList"
                            v-if="cardList.length > 0"
                          >
                            <a-list-item
                              class="renderItem"
                              slot="renderItem"
                              slot-scope="item"
                            >
                              <a-list-item-meta>
                                <div
                                  class="renderItemTitle renderItemTitleOpacity"
                                  slot="title"
                                >
                                  **** **** **** ****
                                  {{
                                    item?.card_number?.substring(
                                      item?.card_number?.length - 4,
                                      item?.card_number?.length
                                    )
                                  }}
                                </div>
                              </a-list-item-meta>
                              <a-radio :value="item.card_number">
                                <a slot="actions"></a>
                              </a-radio>
                            </a-list-item>
                          </a-list>
                        </a-sub-menu>
                      </a-menu>
                      <div
                        class="bind_list"
                        v-if="masterCardMenuOpen || openKeys.length > 0"
                      >
                        <div class="bind_list_title">Pay with a new card</div>
                        <img
                          src="@/assets/rightIconB.svg"
                          alt=""
                          @click="payNewMethod"
                        />
                      </div>
                      <div
                        class="bind_list_tips"
                        v-if="masterCardMenuOpen || openKeys.length > 0"
                      >
                        <img src="@/assets/tipsIcon.svg" alt="" />
                        <div class="bind_list_title">
                          You could deposit funds in the wallet with your
                          selected method before purchasing.
                        </div>
                      </div>
                    </a-radio-group>
                  </div>
                  <div class="deposit_bank_bottom_link">
                    <a-checkbox @change="checkBoxChange" :checked="checked" />
                    <span class="link">
                      I accept the term of the
                      <router-link to="/conditions">
                        GENERAL TEAMS AND CONDITIONS
                      </router-link>
                    </span>
                  </div>
                  <a-button
                    :class="
                      goToBtnDisabled
                        ? 'goToBtnDepositDisabled'
                        : 'goToBtnDeposit'
                    "
                    @click="submitStep3()"
                    >{{ submitTitle }}
                  </a-button>
                  <!-- <div class="chooseCardTips" @click="payNewMethod">
                    Pay with a new card
                  </div> -->
                </div>
              </div>
            </div>
            <div class="wrapper-left" v-if="showStep4 === true">
              <img
                class="successIcon"
                src="@/assets/Success.png"
                alt="img"
              />
              <h3 class="successTitle">Payment Successful</h3>
              <div class="successTips">
                Awesome. Your payment was successful.
              </div>
              <a-button class="goToBtnGot" type="primary" @click="changeSteps">
                Got it
              </a-button>
            </div>
            <div class="wrapper-left" v-if="showStep5 === true">
              <img
                class="successIcon"
                src="@/assets/WaittingIcon.svg"
                alt="img"
              />
              <h3 class="waitTips successTitle">Payment processing</h3>
              <div class="successTips" @click="openSepaA">
                Please click order
                <span class="g-order">{{ speaData.order_no }}</span> to view the
                payment information
              </div>
              <a-button class="goToBtnGot" type="primary" @click="changeSteps">
                Got it
              </a-button>
            </div>
          </div>
        </a-spin>
      </div>
    </div>
    <form method="post" :action="this.form.actionUrl" ref="formRef">
      <input type="hidden" name="ac_ps" value="MASTERCARD" />
      <input type="hidden" name="ac_account_email" :value="this.form.email" />
      <input type="hidden" name="ac_sci_name" :value="this.form.ac_sci_name" />
      <input
        type="hidden"
        name="ac_amount"
        :value="this.form.amount"
        className="input"
      />
      <input
        type="hidden"
        name="ac_currency"
        :value="this.form.currency"
        className="input"
      />
      <input
        type="hidden"
        name="ac_order_id"
        :value="this.form.ac_order_id"
        className="input"
      />
      <input
        type="hidden"
        name="ac_sign"
        :value="this.form.ac_sign"
        className="input"
      />
      <input
        type="hidden"
        name="ac_success_url"
        :value="this.form.url"
        :disabled="true"
      />
      <input
        type="hidden"
        name="ac_fail_url"
        :value="this.form.url"
        :disabled="true"
      />
      <input
        type="hidden"
        name="ac_status_url"
        :value="this.form.url"
        :disabled="true"
      />
    </form>
    <a-modal
      v-model="buyModelShowing"
      centered
      :footer="null"
      width="450"
      :maskClosable="false"
    >
      <iframe
        :src="form.redirect_url"
        id="iframe_moonPay"
        height="800"
        width="400"
        class="iframeStyle"
        frameBorder="no"
      >
        <p>您的浏览器暂不支持 iframe 标签。</p>
      </iframe>
    </a-modal>
    <a-modal
      v-model="buyModelIframeMer"
      centered
      :footer="null"
      :forceRender="true"
      width="450"
      :maskClosable="false"
    >
      <div id="mercuryo-widget" style="width: 400px; height: 800px" />
    </a-modal>
    <a-modal
      class="buyCryptoModal"
      v-model="buyNEOModelShowing"
      centered
      :footer="null"
      width="450"
      :maskClosable="false"
      @cancel="buyNEOModelCancel"
    >
      <iframe
        :src="NEOUrl"
        width="335"
        height="560"
        class="iframeStyle"
        frameBorder="no"
      >
        <p>您的浏览器暂不支持 iframe 标签。</p>
      </iframe>
    </a-modal>
    <a-modal
      v-model="speaModalVisible"
      title="Funding Your Account with Bank Transfer(SPEA)"
      centered
      :footer="null"
      width="450"
      :maskClosable="false"
    >
      <div class="info_title">Order information</div>
      <div class="deposit_bank_msg_container">
        <div class="deposit_bank_msg_container_left">
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Order No.</div>
            <div class="line_right">{{ speaData.order_no }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Wallet Transaction ID</div>
            <div class="line_right">{{ speaData.external_tx_id }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Wallet ID</div>
            <div class="line_right">{{ speaData.address }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Amount</div>
            <div class="line_right">
              {{ speaData.amount }} {{ speaData.currency }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Reference Code</div>
            <div class="line_right">
              {{ speaData.postscript }}
              <span
                class="copyBtn"
                v-clipboard:copy="speaData.postscript"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="attention_tips">
        Please transfer the fund within 4 hours. Send the order reference code
        with the transaction so we can know it’s from you.
      </div>
      <div class="attention_tips_tips">
        Make a payment to Inst using the following bank account details .
      </div>
      <div class="info_title">Bank information</div>
      <div class="deposit_bank_msg_container">
        <div class="deposit_bank_msg_container_left">
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Account Name</div>
            <div class="line_right">
              {{ speaData.bank.account_name
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.account_name"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">IBAN</div>
            <div class="line_right">
              {{ speaData.bank.account_id
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.account_id"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Bank Name</div>
            <div class="line_right">
              {{ speaData.bank.bank_name
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.bank_name"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">BIC(SWIFT Code)</div>
            <div class="line_right">
              {{ speaData.bank.bic
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.bic"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Bank Address</div>
            <div class="line_right">
              {{ speaData.bank.bank_address
              }}<span
                class="copyBtn"
                v-clipboard:copy="speaData.bank.bank_address"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="verifyGoogleVisible"
      title="Verify"
      :footer="null"
      :maskClosable="false"
      centered
    >
      <a-form :form="verifyGoogleAuthForm" @submit="handleVerifyGoogleSubmit">
        <a-form-item label="Verify Google Authenticator">
          <a-input
            class="google_input"
            placeholder="Google Authenticator Code"
            size="large"
            v-decorator="[
              'google_code',
              {
                rules: [
                  {
                    required: true,
                    len: 6,
                    message:
                      'Please enter the six-digit code from Google Authenticator!',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item class="confirmContainer">
          <a-button class="goToBtn" html-type="submit"> Confirm </a-button>
        </a-form-item>
      </a-form>
    </a-modal>

    <LoginModal
      :drawerVisible="drawerVisible"
      :closeModal="closeModal"
      routerUrl="go0"
    />
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import numeral from 'numeral';
import LoginModal from '@/components/LoginModal.vue';
// eslint-disable-next-line import/extensions
import { RegCoin, regNumber } from '@/utils/reg.js';
import { authAfter, neoAuthBefore } from '@/utils/config';
// eslint-disable-next-line import/extensions
import {
  getRates,
  getCustCrypto,
  getBindCardList,
  getUserWallet,
  fastBuyCurrencyNew,
  getUserStatusController,
  getWalletRate,
} from '@/utils/api';
// eslint-disable-next-line import/extensions
import { fromCurrencyNEOOption } from '@/utils/coinImg.js';
import { bindCardLength } from '@/utils/enum';
import NEO from '@/assets/NEO.png';
import GAS from '@/assets/gasImg.svg';
import USDT from '@/assets/USDT.png';
import FLM from '@/assets/FLM.png';
import BNEO from '@/assets/BNEO.png';
import USD from '@/assets/USD.svg';
import EUR from '@/assets/EUR.svg';
import GBP from '@/assets/GBP.svg';
import ETH from '@/assets/ETH.svg';
import BTC from '@/assets/BTC.svg';

export default {
  name: 'CryptoBuy',
  components: {
    LoginModal,
  },
  props: {
    changeDefaultMenu: {
      type: Function,
      require: false,
    },
    changeDefaultATab: {
      type: Function,
      require: false,
    },
    changeReviewListVisible: {
      type: Function,
      require: false,
    },
  },
  data() {
    return {
      verifyGoogleAuthForm: this.$form.createForm(this, {
        name: 'verifyGoogleAuthFormName',
      }),
      form: {
        amount: '',
        approximatelyAmount: '',
        detailApproximatelyAmount: '',
        from_currency: 'USD',
        to_currency: 'NEO',
        address: '',
        channel_name: '',
        url: window.location.href,
        actionUrl: 'https://wallet.advcash.com/sci/',
        email: '',
        ac_sci_name: '',
        currency: '',
        ac_order_id: '',
        ac_sign: '',
        redirect_url: '',
      },
      submitTitle: 'Deposit and Pay',
      numeral,
      NEO,
      ETH,
      GAS,
      USDT,
      FLM,
      BNEO,
      USD,
      EUR,
      GBP,
      loading: false,
      limit_amount: '',
      PayTitle: '',
      buyCurrencyPlaceholder: '00.00',
      selectDisabled: true,
      getSignSuccess: false,
      showAddressTips: false,
      buyModelShowing: false,
      buyModelIframeMer: false,
      goToBtnDisabled: true,
      network_fee: '',
      network_fee_amount: '',
      wallet_rate: '',
      sepa_rate: '',
      rate: '',
      processing_fee: '',
      processing_fee_amount: '',
      minAmount: '',
      maxAmount: '',
      channer_name: '',
      toCurrencyOption: [],
      fromCurrencyOption: [],
      drawerVisible: false,
      accountNumber: '',
      password: '',
      buyNEOModelShowing: false,
      NEOUrl: '',
      timer: null,
      showMaxErrorTips: false,
      showMinErrorTips: false,
      showStep1: true,
      showStep2: false,
      showStep3: false,
      showStep4: false,
      showStep5: false,
      cardList: [],
      cardListLoading: false,
      cardNumber: '',
      wallets: [],
      status: 0,
      settingData: {},
      sepaData: [{ label: 'SEPA Bank Transfer', value: 'sepa' }],
      speaData: { bank: {} },
      speaModalVisible: false,
      masterCardMenuOpen: false,
      chooseSepa: false,
      chooseWallet: false,
      verifyGoogleVisible: false,
      openKeys: ['sub1'],
      checked: false,
    };
  },
  created() {
    this.limit_amount = localStorage.getItem('limit_amount') || '--';
    if (localStorage.getItem('inst_mail')) {
      this.PayTitle = 'Continue';
    } else {
      this.PayTitle = 'Login';
    }
    this.timer = setInterval(this.initRate, 60000);
    this.fromCurrencyOption = fromCurrencyNEOOption;
    const authorization = `${neoAuthBefore}${Date.now()}${authAfter}`;
    const toOption = [];
    getCustCrypto(authorization).then((res) => {
      if (res.code === 0) {
        res.result.forEach((item) => {
          if (item === 'NEO') {
            toOption.push({ value: item, label: 'NEO(N3)', logo: NEO });
          } else if (item === 'GAS') {
            toOption.push({ value: item, label: 'GAS(N3)', logo: GAS });
          } else if (item === 'USDT-TRC20') {
            toOption.push({ value: item, label: 'USDT-TRC20', logo: USDT });
          } else if (item === 'bNEO') {
            toOption.push({ value: item, label: 'bNEO', logo: BNEO });
          } else if (item === 'FLM') {
            toOption.push({ value: item, label: 'FLM', logo: FLM });
          } else if (item === 'fUSDT') {
            toOption.push({ value: item, label: 'fUSDT', logo: USDT });
          } else if (item === 'BTC') {
            toOption.push({ value: item, label: 'BTC', logo: BTC });
          } else if (item === 'ETH') {
            toOption.push({ value: item, label: 'ETH', logo: ETH });
          } else {
            toOption.push({ value: item, label: item, logo: '' });
          }
        });
        this.toCurrencyOption = toOption;
        this.initParams(res.result);
      }
    });
  },
  methods: {
    onCopy() {
      this.$message.success('Copy Success');
    },
    onError() {
      this.$message.error('Copy Failed');
    },
    getParams(url) {
      const params = {};
      const search = `?${url.split('?')[1]}`;
      if (search.indexOf('?') === 0 && search.indexOf('=') > 1) {
        const paramArray = unescape(search)
          .substring(1, search.length)
          .split('&');
        if (paramArray.length > 0) {
          paramArray.forEach((currentValue) => {
            // eslint-disable-next-line prefer-destructuring
            params[currentValue.split('=')[0]] = currentValue.split('=')[1];
          });
        }
      }
      return params;
    },
    initParams(cryptoParams) {
      if (cryptoParams.indexOf('NEO') !== -1) {
        this.selectDisabled = false;
        this.form.approximatelyAmount = '10';
        this.buyCurrencyPlaceholder = '0';
        this.detailApproximatelyAmount = '10';
      } else {
        [this.form.to_currency] = cryptoParams;
        this.selectDisabled = true;
        this.buyCurrencyPlaceholder = '00.00';
        this.form.amount = '100.00';
        this.form.approximatelyAmount = '00.00';
        this.form.detailApproximatelyAmount = '00.00';
      }
      this.initRate();
    },
    initRate() {
      const params = {
        from_currency: this.form.from_currency,
        to_crypto: this.form.to_currency,
      };
      const authorization = `${neoAuthBefore}${Date.now()}${authAfter}`;
      this.loading = true;
      getRates(params, authorization)
        .then((response) => {
          this.loading = false;
          const res = response.data;
          if (res.code === 0) {
            if (res.result.rates.length) {
              let indexOfMax = 0;
              res.result.rates.reduce(
                // eslint-disable-next-line no-return-assign
                (a, c, i) => (c.priority > a ? ((indexOfMax = i), c.priority) : a),
                0,
              );
              // 网络费率
              this.network_fee = res.result.network_fee;
              // 网络费
              this.network_fee_amount = new BigNumber(
                res.result.rates[indexOfMax].rate,
              )
                .multipliedBy(BigNumber(res.result.network_fee))
                .toFixed(2);
              // 币种汇率
              this.rate = res.result.rates[indexOfMax].rate;
              // processing_fee
              this.processing_fee = res.result.processing_fee_rate;
              // // 钱包币种汇率
              // this.wallet_rate = res.result.wallet_rate;
              // // sepa币种汇率
              // this.sepa_rate = res.result.sepa_rate;
              // 币种最大值
              this.maxAmount = res.result.rates[indexOfMax].max_amount;
              // 币种最小值
              this.minAmount = res.result.rates[indexOfMax].min_amount;
              // 通道名称
              this.channel_name = res.result.rates[indexOfMax].channel_name;
              if (this.form.to_currency === 'NEO') {
                const amounts = BigNumber(res.result.rates[indexOfMax].rate)
                  .multipliedBy(this.form.approximatelyAmount)
                  .plus(
                    BigNumber(res.result.rates[indexOfMax].rate).multipliedBy(
                      res.result.network_fee,
                    ),
                  )
                  .toFixed(2);
                this.form.amount = amounts;
                if (amounts > res.result.rates[indexOfMax].max_amount) {
                  this.showMaxErrorTips = true;
                } else {
                  this.showMaxErrorTips = false;
                }
                if (amounts < res.result.rates[indexOfMax].min_amount) {
                  this.showMinErrorTips = true;
                } else {
                  this.showMinErrorTips = false;
                }
              } else {
                const approAmounts = this.form.amount
                  ? `${BigNumber(this.form.amount)
                    .minus(
                      BigNumber(
                        res.result.rates[indexOfMax].rate,
                      ).multipliedBy(res.result.network_fee),
                    )
                    .dividedBy(BigNumber(res.result.rates[indexOfMax].rate))
                    .toFixed(4)}`
                  : '';
                this.form.approximatelyAmount = approAmounts;
                this.form.detailApproximatelyAmount = approAmounts;
                if (
                  this.form.amount > res.result.rates[indexOfMax].max_amount
                ) {
                  this.showMaxErrorTips = true;
                } else {
                  this.showMaxErrorTips = false;
                }
                if (
                  this.form.amount < res.result.rates[indexOfMax].min_amount
                ) {
                  this.showMinErrorTips = true;
                } else {
                  this.showMinErrorTips = false;
                }
              }
            } else {
              this.$message.error('currency not support');
            }
            this.processing_fee_amount = new BigNumber(this.form.amount)
              .multipliedBy(BigNumber(res.result.processing_fee_rate))
              .toFixed(2);
          } else {
            this.$message.error(`${res.msg}`);
          }
        })
        .catch((err) => {
          this.$message.error(`${err}`);
          this.loading = false;
        });
      getWalletRate(params).then((response) => {
        const res = response.data;
        if (res.code === 0) {
          this.wallet_rate = res.result.wallet_rate;
          this.sepa_rate = res.result.sepa_rate;
        }
      });
    },
    // I want to Spend USD
    amountChange(value) {
      this.form.amount = value.target.value;
      this.form.approximatelyAmount = value.target.value
        ? `${BigNumber(value.target.value)
          .dividedBy(BigNumber(this.rate))
          .toFixed(4)}`
        : '';
      this.form.detailApproximatelyAmount = value.target.value
        ? `${BigNumber(value.target.value)
          .dividedBy(BigNumber(this.rate))
          .toFixed(4)}`
        : '';
      this.processing_fee_amount = new BigNumber(value.target.value)
        .multipliedBy(BigNumber(this.processing_fee))
        .toFixed(2);
      if (new BigNumber(value.target.value) > this.maxAmount) {
        this.showMaxErrorTips = true;
      } else {
        this.showMaxErrorTips = false;
      }
      if (new BigNumber(value.target.value) < this.minAmount) {
        this.showMinErrorTips = true;
      } else {
        this.showMinErrorTips = false;
      }
    },
    fromCurrencyChange(value) {
      this.form.from_currency = value;
      this.initRate();
    },
    toCurrencyChange(value) {
      this.form.to_currency = value;
      if (value === 'NEO') {
        this.selectDisabled = false;
      } else {
        this.selectDisabled = true;
      }
      if (
        RegCoin[this.form.to_currency].test(this.form.address)
        || !this.form.address
      ) {
        this.showAddressTips = false;
        this.goToBtnDisabled = false;
      } else {
        this.showAddressTips = true;
        this.goToBtnDisabled = true;
      }
      this.form.approximatelyAmount = 10;
      this.form.detailApproximatelyAmount = 10;
      this.initRate();
    },
    // I want to buy NEO
    toCurrencyAmountChange(value) {
      if (
        this.form.to_currency === 'NEO'
        && !regNumber.test(value.target.value)
      ) {
        this.goToBtnDisabled = true;
      } else {
        this.form.approximatelyAmount = value.target.value;
        this.form.detailApproximatelyAmount = value.target.value;
        if (value.target.value) {
          this.form.amount = BigNumber(value.target.value)
            .multipliedBy(this.rate)
            .toFixed(2);
          this.processing_fee_amount = new BigNumber(value.target.value)
            .multipliedBy(this.rate)
            .multipliedBy(BigNumber(this.processing_fee))
            .toFixed(2);
        } else {
          this.form.amount = '0';
        }
      }
      if (
        new BigNumber(value.target.value).multipliedBy(this.rate).toFixed(2)
        > this.maxAmount
      ) {
        this.showMaxErrorTips = true;
      } else {
        this.showMaxErrorTips = false;
      }
      if (
        new BigNumber(value.target.value).multipliedBy(this.rate).toFixed(2)
        < this.minAmount
      ) {
        this.showMinErrorTips = true;
      } else {
        this.showMinErrorTips = false;
      }
    },
    toAddressChange(value) {
      this.form.address = value.target.value;
      if (
        RegCoin[this.form.to_currency].test(value.target.value)
        || !value.target.value
      ) {
        this.showAddressTips = false;
        this.goToBtnDisabled = false;
      } else {
        this.showAddressTips = true;
        this.goToBtnDisabled = true;
      }
    },
    closeModal() {
      this.drawerVisible = false;
    },
    selectChange(e) {
      this.cardNumber = e.target.value;
      this.submitTitle = 'Deposit and Pay';
      if (e.target.value === 'sepa') {
        this.openKeys = [];
        this.masterCardMenuOpen = false;
        this.chooseSepa = true;
        this.chooseWallet = false;
        this.form.detailApproximatelyAmount = BigNumber(this.form.amount)
          .dividedBy(BigNumber(this.sepa_rate))
          .toFixed(4);
      } else if (e.target.value === 'wallet') {
        this.openKeys = [];
        this.submitTitle = 'Payment';
        this.masterCardMenuOpen = false;
        this.chooseSepa = false;
        this.chooseWallet = true;
        console.log('wallet_rate', this.wallet_rate);
        this.form.detailApproximatelyAmount = BigNumber(this.form.amount)
          .dividedBy(BigNumber(this.wallet_rate))
          .toFixed(4);
      } else {
        this.chooseSepa = false;
        this.chooseWallet = false;
        this.form.detailApproximatelyAmount = BigNumber(this.form.amount)
          .dividedBy(BigNumber(this.rate))
          .toFixed(4);
      }
    },
    submitStep1() {
      if (!localStorage.getItem('inst_mail')) {
        this.drawerVisible = true;
      } else if (this.showMaxErrorTips) {
        this.$message.error(
          `Maximum allowed: ${numeral(this.maxAmount).format('0,0')} USD`,
        );
      } else if (this.showMinErrorTips) {
        this.$message.error(
          `Minimum allowed: ${numeral(this.minAmount).format('0,0')} USD`,
        );
      } else if (!this.form.amount) {
        this.$message.error('Please enter amount');
      } else {
        this.showStep1 = false;
        this.showStep2 = true;
      }
    },
    payNewMethod() {
      if (this.cardList.length < bindCardLength) {
        // this.submitStep3();
        if (!this.checked) {
          this.$message.error(
            'Please accept the term of the GENERAL TEAMS AND CONDITIONS',
          );
        } else {
          const authorization = `${neoAuthBefore}${Date.now()}${authAfter}`;
          const params = {
            cust_order_id: Date.now(),
            from_currency: this.form.from_currency,
            to_crypto: this.form.to_currency,
            amount: BigNumber(this.form.amount).toFixed(2),
            channel_name: this.channel_name,
            address: this.form.address,
            rate: this.rate,
            customer: {
              card_number: this.cardNumber,
            },
            pay_with: 'card',
          };
          this.submitOrder(params, authorization);
        }
      } else {
        this.changeDefaultATab('bindCard');
      }
    },
    async submitGetOrder() {
      if (!localStorage.getItem('inst_mail')) {
        this.drawerVisible = true;
      } else if (this.goToBtnDisabled === true) {
        //
      } else if (!this.form.address) {
        this.$message.error('Please enter correct wallet address');
      } else if (!this.form.amount) {
        this.$message.error('Please enter amount');
      } else {
        this.goToBtnDisabled = true;
        const [res1, res2] = await Promise.all([
          getBindCardList(),
          getUserWallet(),
        ]);
        this.goToBtnDisabled = false;
        if (res1.code === 0 && res2.code === 0) {
          this.cardList = res1.result;
          this.showStep2 = false;
          this.showStep3 = true;
          this.settingData = res2.result.settings;
          this.status = res2.result.status;
          res2.result.wallets.forEach((item) => {
            if (item.currency === this.form.from_currency) {
              this.wallets = [item];
            }
          });
        } else {
          this.$message.error(res1.msg);
        }
      }
    },
    submitStep3() {
      const authorization = `${neoAuthBefore}${Date.now()}${authAfter}`;
      if (!this.checked) {
        this.$message.error(
          'Please accept the term of the GENERAL TEAMS AND CONDITIONS',
        );
      } else if (this.cardNumber === 'wallet') {
        // 钱包支付
        if (Number(this.wallets[0].balance) < Number(this.form.amount)) {
          this.$message.error('Insufficient wallet balance');
        } else {
          this.verifyGoogleVisible = true;
        }
      } else if (this.cardNumber === 'sepa') {
        // sepa支付
        const params = {
          from_currency: this.form.from_currency,
          amount: BigNumber(this.form.amount).toFixed(2),
          pay_with: 'sepa',
          to_crypto: this.form.to_currency,
          address: this.form.address,
          rate: this.sepa_rate,
        };
        this.submitOrder(params, authorization);
      } else if (this.cardList.length > 0 && !this.cardNumber) {
        this.$message.error('Please select your card');
      } else {
        const params = {
          cust_order_id: Date.now(),
          from_currency: this.form.from_currency,
          to_crypto: this.form.to_currency,
          amount: BigNumber(this.form.amount).toFixed(2),
          channel_name: this.channel_name,
          address: this.form.address,
          rate: this.rate,
          customer: {
            card_number: this.cardNumber,
          },
          pay_with: 'card',
        };
        this.submitOrder(params, authorization);
      }
    },
    submitOrder(params, authorization) {
      this.goToBtnDisabled = true;
      fastBuyCurrencyNew(params, authorization)
        .then((res) => {
          this.goToBtnDisabled = false;
          if (res.code === 0) {
            if (params.pay_with === 'card') {
              this.NEOUrl = res.result.data.redirect_url;
              this.buyNEOModelShowing = true;
            } else if (params.pay_with === 'wallet') {
              this.showStep3 = false;
              this.showStep4 = true;
              this.verifyGoogleVisible = false;
            } else if (params.pay_with === 'sepa') {
              this.speaData = res.result;
              this.speaModalVisible = true;
              this.showStep3 = false;
              this.showStep5 = true;
            }
          } else {
            this.$message.error(`${res.msg}`);
          }
        })
        .catch((err) => {
          this.goToBtnDisabled = false;
          if (err.code === 119010) {
            this.changeDefaultMenu('identification');
          }
        });
    },
    buyNEOModelCancel() {
      this.buyNEOModelShowing = false;
      getUserStatusController().then((res) => {
        if (res.code === 310001) {
          this.changeReviewListVisible();
        }
      });
    },
    handleVerifyGoogleSubmit(e) {
      e.preventDefault();
      this.verifyGoogleAuthForm.validateFields((err, values) => {
        if (!err) {
          const authorization = `${neoAuthBefore}${Date.now()}${authAfter}`;
          const params = {
            pay_with: 'wallet',
            wallet_address: this.wallets[0]?.address,
            from_currency: this.form.from_currency,
            amount: BigNumber(this.form.amount).toFixed(2),
            rate: this.wallet_rate,
            to_crypto: this.form.to_currency,
            address: this.form.address,
            'ga-code': values.google_code,
          };
          this.submitOrder(params, authorization);
        }
      });
    },
    checkBoxChange() {
      this.checked = !this.checked;
    },
    changeSteps() {
      this.showStep1 = true;
      this.showStep4 = false;
      this.showStep5 = false;
    },
    openSepaA() {
      this.speaModalVisible = true;
    },
    goWallet() {
      this.changeDefaultMenu('wallet');
    },
    goBind() {
      this.changeDefaultMenu('bindCard');
    },
    menuChange(openKeys) {
      this.openKeys = openKeys;
      if (openKeys.length > 0) {
        this.masterCardMenuOpen = true;
      } else {
        this.masterCardMenuOpen = false;
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
.security {
  width: 900px;
  text-align: center;
  @media (max-width: 960px) {
    width: 100%;
    margin-top: 0px;
  }
}
.wrapper-top {
  max-width: 1020px;
  margin: auto;
  margin-top: 40px;
  padding-left: 20px;
  text-align: left;
  @media (max-width: 960px) {
    margin-top: 0px;
  }
}
.tip {
  font-size: 16px;
  line-height: 28px;
  color: var(--color-content);
}

.title {
  margin-top: 10px;
}

.top {
  margin-top: 100px;
  padding-bottom: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  justify-items: center;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }
}

.info {
  text-align: left;
  position: relative;
  width: 360px;
  @media (max-width: 960px) {
    margin-right: 0px;
    width: 100%;
  }
  // .info_filter {
  //   position: absolute;
  //   width: 683px;
  //   height: 750px;
  //   left: 50px;
  //   background: #0078fa;
  //   opacity: 0.08;
  //   filter: blur(44px);
  //   @media (max-width: 960px) {
  //     width: 100%;
  //     left: 0px;
  //   }
  // }
}

.circle-img-container {
  display: flex;
  flex: 1;
  margin-bottom: 20px;
  @media (max-width: 960px) {
    margin-bottom: 0;
  }
}

.circle-img1 {
  max-width: 120px;
}

.circle-img2 {
  width: 25%;
  margin-left: 65%;
  max-width: 120px;
}
.circle-img4 {
  flex: 1;
  text-align: end;
}
.circle-img {
  flex: 1;
  margin-top: 40px;
}
.circle-img13 {
  flex: 1;
  margin-top: 120px;
  text-align: left;
}
.circle-img14 {
  flex: 1;
  margin-top: 40px;
  text-align: right;
}
.shop-tips {
  font-size: 18px;
  font-weight: 400;
  opacity: 0.4;
}
.circle-visa {
  height: 32px;
  margin-right: 20px;
}
.info__title {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #000000;
  @media (max-width: 960px) {
    margin-top: 20px;
    font-size: 30px;
    line-height: 35px;
  }
}

.info__content {
  margin-top: 30px;
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.4;
}

.info--img__wrapper {
  width: 480px;
  @media (max-width: 960px) {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    padding: 0;
  }
}

.info--img {
  max-width: 100%;
}

.wrapper {
  .maRight {
    display: flex;
    justify-content: space-between;
    @media (max-width: 960px) {
      flex-direction: column;
    }
  }
  &.right {
    padding: 60px 0px;
    @media (max-width: 960px) {
      padding: 0;
      flex-direction: column;
    }
  }

  &.left {
    padding-bottom: 200px;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
    @media (max-width: 960px) {
      background: none;
    }
  }
}

.row {
  display: flex;
  // grid-template-columns: 1fr 1fr;
  // grid-column-gap: 20px;

  // & > *:nth-child(2) {
  //   justify-self: right;
  // }

  @media (max-width: 960px) {
    display: inline-block;
    grid-template-columns: 1fr;
    justify-items: center;
    grid-row-gap: 48px;
    & > *:nth-child(2) {
      justify-self: center;
    }
    &.right {
      & > *:nth-child(1) {
        grid-row: 1;
      }
      & > *:nth-child(2) {
        grid-row: 2;
      }
    }
    .circle-img5 {
      max-width: calc(100%);
    }
  }
}
.wrapper-left {
  max-width: calc(94%);
  padding: 32px;
  background: var(--background-common);
  box-shadow: 0px 20px 40px rgba(0, 69, 255, 0.1);
  border-radius: 4px;
  min-height: 447px;
  @media (max-width: 960px) {
    margin: auto;
    margin-bottom: 12px;
  }
  .successIcon {
    margin: 36px;
  }
  .successTitle {
    font-size: 20px;
    font-weight: 500;
    margin: 24px 0;
  }
  .successTips {
    color: #888;
  }
  .goToBtnGot {
    margin-top: 100px;
    height: 48px;
    width: 200px;
  }
}
.wrapper-left-risk {
  display: flex;
  align-items: flex-start;
  text-align: left;
  .tips_img {
    margin-right: 8px;
  }
  .risk-text {
    flex: 1;
    .risk-title {
      font-size: 16px;
      font-weight: 500;
    }
    .risk-title-tips {
      margin-top: 12px;
    }
    .risk-tips {
      padding-left: 8px;
      margin-top: 8px;
      img {
        width: 240px;
      }
    }
    .risk-tips-img {
      border: 1px solid #e9eaf0;
      text-align: center;
    }
  }
}
.wrapper-left-item-last {
  margin-bottom: 5px;
}

.rateTipsLeft {
  color: #000;
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  background: rgba(230, 154, 58, 0.12);
  display: flex;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  img {
    width: 24px;
    height: 24px;
  }
  .rateTipsTitleLeft {
    opacity: 0.8;
    margin-left: 4px;
  }
}
.rateTips {
  margin-top: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  .rateTipsList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .rateTipsListLeft {
      opacity: 0.3;
    }
    .rateTipsListRight {
      opacity: 0.6;
    }
  }
}

.select-container-top {
  /deep/.ant-input-group {
    border: 1px solid #d9d9d9;
    border-radius: 15px;
    .ant-input {
      border: 0;
      border-radius: 15px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-weight: bold;
    }
  }
  //input去除加减号样式
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  /deep/ input[type="number"] {
    -moz-appearance: textfield;
  }
  /deep/.ant-input-group:hover {
    border: 1px solid #0045ff;
    box-shadow: 0 0 0 2px rgba(0, 69, 255, 0.06);
    transition: all 0.3s;
  }
  /deep/.ant-input-group:focus-within {
    border: 1px solid #0045ff;
    box-shadow: 0 0 0 2px rgba(0, 69, 255, 0.06);
    transition: all 0.3s;
  }
  /deep/.ant-input:hover {
    border: none;
  }
  /deep/.ant-input:focus {
    border: none;
    box-shadow: none;
    // box-shadow: 0 0 0 2px rgba(0, 69, 255, 0.06);
  }
  /deep/.ant-input-group-addon {
    border: none;
    border-radius: 15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: #fff;
  }
  /deep/.ant-select-selection-selected-value {
    font-weight: bold;
  }
}
.select-container-address {
  input {
    border-radius: 15px;
  }
}
.currencySelect {
  min-width: 160px;
}
.select-label-top {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  color: #000;
  img {
    width: 14px;
    cursor: pointer;
    margin-right: 10px;
  }
}
.select-label {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: left;
}
.select-label-option {
  font-size: 12px;
  opacity: 0.6;
}
.selectIcon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  margin-top: -3px;
}
.addressTips {
  color: #c91919;
  font-size: 12px;
  margin-top: 10px;
  text-align: left;
}
.goToBtn {
  background: #0045ff;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  color: #fff;
  margin-top: 40px;
}
.goToBtnDisabled {
  background: #80a2ff;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  color: #fff;
  margin-top: 40px;
}
.goToBtnDeposit {
  background: #0045ff;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  color: #fff;
  margin-top: 12px;
}
.goToBtnDepositDisabled {
  background: #80a2ff;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  color: #fff;
  margin-top: 12px;
}
.register {
  cursor: pointer;
  color: #0045ff;
}

.triangle {
  border-style: solid;
  border-width: 5px 5px 5px 5px;
  border-color: transparent transparent rgba(230, 154, 58, 0.12) transparent;
  width: 0px;
  height: 0px;
  margin-left: 10px;
}

.maxTips {
  background: rgba(230, 154, 58, 0.12);
  padding: 5px;
  border-radius: 5px;
  text-align: left;
}

.buyWarning {
  background-color: #fef3f2;
  border-radius: 15px;
  padding: 12px;
  margin-top: 20px;
}

.buyWarning .warningTop {
  font-size: 20px;
  color: #bc2d1a;
  font-weight: 500;
  margin-bottom: 20px;
  vertical-align: middle;
  text-align: left;
}

.buyWarning .warningTop img {
  width: 22px;
  vertical-align: middle;
  margin-right: 12px;
}

.buyWarning .warningBottom {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
.buyCryptoModal {
  max-width: 360px;
  /deep/.ant-modal-body {
    padding: 0;
  }
}
.iframeStyle {
  margin-top: 20px;
}
.step1Btn {
  margin-bottom: 0;
}
.container {
  .topTips {
    background: #fafafa;
    padding: 12px 0;
    color: #000;
    font-weight: 700;
    display: flex;
    font-size: 16px;
    border-radius: 4px;
    .left {
      flex: 1;
      text-align: center;
      div {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 8px;
          height: 20px;
        }
      }
    }
    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 24px;
      }
    }
    .right {
      flex: 1;
      text-align: center;
      div {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 8px;
          height: 20px;
        }
      }
    }
  }
  .bottomCardContainer {
    margin-top: 12px;
    font-size: 16px;
    font-weight: bold;
    .top_tips {
      display: flex;
      font-size: 12px;
      opacity: 0.6;
      font-weight: 400;
      img {
        width: 16px;
      }
      .top_tips_text {
        margin-left: 2px;
        height: 24px;
        line-height: 24px;
      }
    }
    .title {
      color: #000;
      text-align: left;
      margin-bottom: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      opacity: 0.6;
    }
    .renderItem {
      text-align: left;
      border-bottom: none;
      cursor: pointer;
      padding: 6px 12px 0;
      .renderItemImg {
        height: 36px;
      }
      .renderItemTitle {
        font-size: 14px;
        height: 36px;
        line-height: 36px;
        font-weight: bold;
        img {
          width: 32px;
          margin-right: 10px;
        }
      }
      .renderItemTitleOpacity {
        opacity: 0.4;
        font-weight: 400;
        font-size: 12px;
        padding-left: 48px;
      }
      .ant-list-item-meta-title {
        margin-bottom: 0;
      }
    }
    .renderItem_wallet {
      margin-bottom: 0;
    }
    .wallet_bottom {
      display: flex;
      justify-content: space-between;
      padding: 0 20px 20px 56px;
      border-bottom: 1px solid #fafafa;
      .wallet_left {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        opacity: 0.4;
      }
      .wallet_right {
        display: flex;
        .wallet_right_balance {
          margin-right: 20px;
          font-weight: 500;
          font-size: 12px;
          color: #000;
        }
        .wallet_right_deposit {
          color: #0045ff;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }
    .bind_list {
      display: flex;
      justify-content: space-between;
      padding-left: 60px;
      padding-right: 20px;
      margin-top: 8px;
      margin-bottom: 16px;
      .bind_list_title {
        font-weight: 400;
        font-size: 12px;
      }
      img {
        cursor: pointer;
      }
    }
    .bind_list_tips {
      display: flex;
      justify-content: space-between;
      img {
        width: 10px;
      }
      .bind_list_title {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #ff8e00;
        margin-left: 8px;
        text-align: left;
      }
    }
    .menuSty {
      text-align: left;
      border: none;
      .menuSubSty {
        padding-left: 0;
        /deep/ .ant-menu-submenu-title {
          padding-left: 12px !important;
        }
        /deep/ .ant-menu-submenu-arrow {
          right: 20px;
        }
        .menuSubIcon {
          margin-right: 10px;
        }
      }
    }
    .payWithSty {
      width: 100%;
      height: 240px;
      overflow: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      overflow-x: hidden;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
    }
    ::-webkit-scrollbar {
      display: none;
    }
    .ant-radio-group {
      width: 100%;
    }
    .chooseCardTips {
      font-size: 12px;
      color: #0045ff;
      margin-top: 12px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.demo-loadmore-list-sepa {
  border-top: 1px solid #fafafa;
}
.info_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 10px;
}
.deposit_bank_msg_container {
  display: flex;
  width: 100%;
  border-radius: 8px;
  padding: 20px 30px;
  margin: 0px 0 20px;
  background: rgba(0, 69, 255, 0.03);
  .deposit_bank_msg_container_left {
    display: flex;
    flex: 1;
    flex-direction: column;
    .deposit_bank_msg_container_line {
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding: 10px 0;
      .line_left {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
      .line_right {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        .copyBtn {
          margin-left: 10px;
        }
      }
    }
  }
}
.deposit_bank_bottom_link {
  text-align: left;
  margin-top: 40px;
  .link {
    font-size: 12px;
    line-height: 16px;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.6);
  }
}
.attention_tips {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ff8e00;
  margin: 12px 0 40px;
}
.attention_tips_tips {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;
}
.waitTips {
  margin-top: 48px;
  margin-bottom: 48px;
  color: #0045ff;
}
.g-order {
  cursor: pointer;
  color: #0045ff;
}
</style>
