<template>
  <div class="wrapper">
    <div class="my_right">
      <PaymentMethod
        v-if="defaultMenu === 'paymentMethod'"
        :changeDefaultMenu="changeDefaultMenu"
      />
      <AddNewCard
        v-if="defaultMenu === 'addNewCard'"
        :changeDefaultMenu="changeDefaultMenu"
        :id="id"
      />
      <BindCardResult
        v-if="defaultMenu === 'bindCardResult'"
        :changeDefaultMenu="changeDefaultMenu"
        :id="id"
        :status="status"
        :reason="reason"
      />
    </div>
  </div>
</template>

<script>
import PaymentMethod from './PaymentMethod.vue';
import AddNewCard from './AddNewCard.vue';
import BindCardResult from './BindCardResult.vue';

export default {
  name: 'My',
  components: {
    PaymentMethod,
    AddNewCard,
    BindCardResult,
  },
  data() {
    return {
      defaultMenu: 'paymentMethod',
      id: null,
      status: null,
      reason: '',
    };
  },
  created() {},
  methods: {
    reSetDefaultMenu() {
      this.defaultMenu = 'paymentMethod';
    },
    changeDefaultMenu(item, id, status, reason) {
      this.defaultMenu = item;
      if (id) {
        this.id = id;
      }
      if (status) {
        this.status = status;
      }
      if (reason) {
        this.reason = reason;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #f8f8f8;
  .my_right {
    width: 900px;
    @media (max-width: 960px) {
      width: 100%;
      margin-left: 0;
    }
  }
}
</style>
