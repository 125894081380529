<template>
  <div class="cardHolderContainer">
    <div class="cardContainer">
      <div class="container">
        <div class="historyContainer">
          <a-table
            :columns="columns"
            :data-source="data"
            rowKey="time"
            :loading="dataLoading"
          >
            <!-- <span slot="kyc_status" slot-scope="text">
              <span v-if="text === 0">Pending</span>
              <span v-else-if="text === 2" class="success">Succeed</span>
              <span v-else-if="text === 7" class="fail">Failed</span>
              <span v-else>{{ text }}</span>
            </span>
            <span slot="kyc_driver_status" slot-scope="text">
              <span v-if="text === 0">Pending</span>
              <span v-else-if="text === 2" class="success">Succeed</span>
              <span v-else-if="text === 7" class="fail">Failed</span>
              <span v-else>{{ text }}</span>
            </span>
            <span slot="kyc_level" slot-scope="text">
              <span v-if="text === '1'">Level-1</span>
              <span v-else-if="text === '2'">Level-2</span>
              <span v-else>-</span>
            </span> -->
            <span slot="create_time" slot-scope="text">
              {{ $moment(text).format("YYYY-MM-DD HH:mm:ss") }}
            </span>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'History',
  props: {
    data: {
      type: Array,
      require: false,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'Email',
          dataIndex: 'mail',
          key: 'mail',
          scopedSlots: { customRender: 'mail' },
        },
        // {
        //   title: "kyc_status",
        //   dataIndex: "kyc_status",
        //   key: "kyc_status",
        //   scopedSlots: { customRender: "kyc_status" },
        // },
        // {
        //   title: "kyc_driver_status",
        //   dataIndex: "kyc_driver_status",
        //   key: "kyc_driver_status",
        //   scopedSlots: { customRender: "kyc_driver_status" },
        // },
        // {
        //   title: "transaction_status",
        //   dataIndex: "transaction_status",
        //   key: "transaction_status",
        //   scopedSlots: { customRender: "transaction_status" },
        // },
        // {
        //   title: "kyc_level",
        //   dataIndex: "kyc_level",
        //   key: "kyc_level",
        //   scopedSlots: { customRender: "kyc_level" },
        // },
        {
          title: 'Time Created',
          dataIndex: 'time',
          key: 'create_time',
          scopedSlots: { customRender: 'create_time' },
        },
      ],
      dataLoading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.cardHolderContainer {
  width: 924px;
  @media (max-width: 750px) {
    width: 100%;
  }
  .cardContainer {
    width: 100%;
    .container {
      max-width: 924px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 48px;
      // box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
      @media (max-width: 750px) {
        margin-top: 10px;
      }
      .historyContainer {
        h2 {
          margin-left: 16px;
        }
        margin-top: 24px;
      }
    }
  }
}
.searchContent {
  margin: 16px;
  margin-top: 0;
  padding-top: 16px;
  .txIdInput {
    display: inline-block;
    width: 100px;
    margin-right: 20px;
  }
  .searchHistory {
    background: #0045ff;
    color: #fff;
    margin-left: 20px;
  }
  @media (max-width: 750px) {
    display: flex;
    /deep/ .ant-calendar-picker {
      flex: 3;
    }
    .searchHistory {
      flex: 1;
    }
  }
}
/deep/ .ant-table-wrapper {
  width: 100%;
  overflow-x: auto;
  // display: -webkit-box;
  // -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}
.success {
  color: #52c41a;
}
.fail {
  color: red;
}
.orderNo {
  cursor: pointer;
  color: #0045ff;
}
.address {
  cursor: pointer;
  color: #0045ff;
}
.transaction_hash {
  cursor: pointer;
}
.buyCryptoHistoryModal {
  /deep/.ant-modal-body {
    padding: 0;
  }
}
.success {
  color: #52c41a;
}
.fail {
  color: red;
}
</style>
