<template>
  <div class="wrapper">
    <NavHeader />
    <NavHeaderMobile />
    <div class="my_tips_container">
      <div class="my_tips">
        <img src="@/assets/tipsIcon.svg" alt="" />
        <div class="tips_text">
          {{ topTips }}
        </div>
      </div>
    </div>
    <div class="my_wrapper">
      <div class="my_left">
        <div class="avatar">
          <img src="@/assets/ellipse5.svg" alt="avatar" />
        </div>
        <div class="name">{{ mail }}</div>
        <div class="menu_container">
          <div
            :class="['menu', { menuLan: item.key === defaultMenu }]"
            v-for="item in menuOption"
            :key="item.key"
            @click="clickMenu(item)"
          >
            <img
              v-if="item.key === defaultMenu"
              :src="item.imgLan"
              :alt="item.label"
            />
            <img v-else :src="item.img" :alt="item.label" />
            <div class="title">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <div class="my_right">
        <CryptoBuy
          v-if="defaultMenu === 'fastBuy'"
          :changeDefaultMenu="changeDefaultMenu"
        />
        <Wallet
          v-if="defaultMenu === 'wallet'"
          :changeDefaultMenu="changeDefaultMenu"
        />
        <Card
          v-if="defaultMenu === 'myCard'"
          :changeDefaultMenu="changeDefaultMenu"
        />
        <BindCard
          v-if="defaultMenu === 'bindCard'"
          :changeDefaultMenu="changeDefaultMenu"
        />
        <AuthenticationCenter v-if="defaultMenu === 'identification'" />
        <Security
          v-if="defaultMenu === 'security'"
          :changeDefaultMenu="changeDefaultMenu"
        />
        <Invite v-if="defaultMenu === 'invite'" />
      </div>
    </div>
    <NewFooter />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import NewFooter from '@/components/NewFooter.vue';
import fastBuyImgHei from '@/assets/icon-fastBuyImg.svg';
import WalletImgHei from '@/assets/icon-wallet-hei.svg';
import myCardImgHei from '@/assets/icon-myCardImg.svg';
import identificationImgHei from '@/assets/icon-identificationImg.svg';
import kycImgHei from '@/assets/icon-kycImg.svg';
import kycImgLan from '@/assets/icon-kycImg-lan.svg';
import fastBuyImgLan from '@/assets/icon-fastBuyImg-lan.svg';
import WalletImgLan from '@/assets/icon-wallet-lan.svg';
import myCardImgLan from '@/assets/icon-myCardImg-lan.svg';
import identificationImgLan from '@/assets/icon-identificationImg-lan.svg';
import inviteIconHei from '@/assets/inviteIconHei.svg';
import inviteIconLan from '@/assets/inviteIconLan.svg';
import { getUserWallet } from '@/utils/api';
import Security from '../Security/index.vue';
import Invite from '../Invite/index.vue';
import AuthenticationCenter from '../AuthenticationCenter/index.vue';
import Card from '../Card/index.vue';
import Wallet from '../Wallet/index.vue';
import BindCard from '../BindCard/index.vue';
import CryptoBuy from '../CryptoBuy/index.vue';

export default {
  name: 'My',
  components: {
    NavHeader,
    NavHeaderMobile,
    NewFooter,
    CryptoBuy,
    Card,
    Wallet,
    BindCard,
    AuthenticationCenter,
    Security,
    Invite,
  },
  data() {
    return {
      defaultMenu: 'wallet',
      defaultSecondMenu: 'dollar',
      mail: '',
      topTips:
        'ATTENTION! We handles KYC and orders Monday-Friday 10:00-18:00 GMT+08:00',
      menuOption: [
        {
          label: 'Wallets',
          img: WalletImgHei,
          imgLan: WalletImgLan,
          key: 'wallet',
        },
        {
          label: 'Inst Cards',
          img: myCardImgHei,
          imgLan: myCardImgLan,
          key: 'myCard',
        },
        {
          label: 'Fast Buy Crypto',
          img: fastBuyImgHei,
          imgLan: fastBuyImgLan,
          key: 'fastBuy',
        },
        {
          label: 'Identification',
          img: kycImgHei,
          imgLan: kycImgLan,
          key: 'identification',
        },
        {
          label: 'Security',
          img: identificationImgHei,
          imgLan: identificationImgLan,
          key: 'security',
        },
        {
          label: 'Referral',
          img: inviteIconHei,
          imgLan: inviteIconLan,
          key: 'invite',
        },
      ],
      openKeys: ['sub1'],
    };
  },
  created() {
    this.mail = localStorage.getItem('inst_mail');
    if (this.$route.query.tab) {
      this.defaultMenu = this.$route.query.tab;
    }
    this.getTips();
  },
  watch: {
    $route() {
      this.defaultMenu = this.$route.query.tab;
    },
  },
  methods: {
    getTips() {
      getUserWallet().then((res) => {
        if (res.code === 0) {
          this.topTips = res.result?.settings?.announcement;
        }
      });
    },
    clickMenu(item) {
      this.defaultMenu = item.key;
      this.getTips();
    },
    changeDefaultMenu(item) {
      this.defaultMenu = item;
    },
    clickSecondMenu(item) {
      this.defaultSecondMenu = item.key;
    },
    handleClick() {
      this.defaultMenu = 'wallet';
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #f8f8f8;
  .my_tips_container {
    padding-top: 80px;
    background: rgba(250, 179, 30, 0.1);
    display: flex;
    .my_tips {
      display: flex;
      width: 1280px;
      margin: auto;
      padding: 10px;
      @media (max-width: 960px) {
        width: 100%;
      }
      img {
        width: 18px;
        margin-right: 14px;
      }
      .tips_text {
        font-family: "Poppins-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #ff8e00;
      }
    }
  }
  .my_wrapper {
    width: 1280px;
    display: flex;
    margin: auto;
    min-height: calc(100vh - 295px);
    display: flex;
    margin-top: 30px;
    margin-bottom: 24px;
    @media (max-width: 960px) {
      width: 100%;
      flex-direction: column;
      margin-bottom: 0px;
    }
    .my_left {
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      width: 240px;
      height: 800px;
      @media (max-width: 960px) {
        display: none;
      }
      .avatar {
        margin: 40px 0 10px;
        text-align: center;
        img {
          width: 60px;
        }
      }
      .name {
        font-family: "Poppins-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        text-align: center;
        margin-bottom: 30px;
      }
      .menu_container {
        .menu {
          display: flex;
          align-items: center;
          height: 60px;
          padding: 0 34px;
          cursor: pointer;
          &:hover {
            background: rgba(0, 69, 255, 0.05);
            .title {
              color: #0045ff;
            }
          }
          img {
            width: 24px;
          }
          .title {
            font-family: "Poppins-Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
            margin-left: 12px;
          }
        }
        .second_menu {
          /deep/.ant-menu-submenu-title {
            padding: 0 34px !important;
          }
          /deep/.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
            height: 60px;
            line-height: 60px;
          }
          /deep/.ant-menu-item {
            margin: 0;
          }
          /deep/.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: rgba(0, 69, 255, 0.05);
          }
          &:hover {
            background: rgba(0, 69, 255, 0.05);
            .title {
              color: #0045ff;
            }
          }
          .title {
            font-family: "Poppins-Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
            margin-left: 12px;
          }
          .second_menu_container {
            display: flex;
            justify-content: space-between;
          }
        }
        .menuLan {
          background: rgba(0, 69, 255, 0.05);
          .title {
            color: #0045ff;
          }
        }
      }
    }
    .my_right {
      margin-left: 20px;
      width: 1020px;
      @media (max-width: 960px) {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
</style>
