var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"security-wrapper"},[_c('h2',[_vm._v("Security")]),_c('div',{staticClass:"security-container"},[_c('div',{staticClass:"title_tips"},[_vm._v("2FA authentication")]),_c('div',{staticClass:"auth_container"},[_vm._m(0),_c('div',{staticClass:"auth_container_right"},[_c('a-button',{staticClass:"go_btn_dis",attrs:{"type":"primary","disabled":true}},[_vm._v("Enabled")])],1)]),_c('div',{staticClass:"auth_container"},[_vm._m(1),_c('div',{staticClass:"auth_container_right"},[_c('a-button',{staticClass:"go_btn",attrs:{"type":"primary"},on:{"click":_vm.goGoogleVerify}},[_vm._v(_vm._s(_vm.gaEnable && _vm.gaStatus ? "Disable" : "Enable"))])],1)]),_c('div',{staticClass:"title_tips"},[_vm._v("Password")]),_c('div',{staticClass:"auth_container"},[_vm._m(2),_c('div',{staticClass:"auth_container_right"},[_c('a-button',{staticClass:"go_btn",attrs:{"type":"primary"},on:{"click":_vm.goPassword}},[_vm._v("Change")])],1)])]),_c('a-modal',{attrs:{"title":"Verify","footer":null,"maskClosable":false,"centered":""},model:{value:(_vm.verifyGoogleVisible),callback:function ($$v) {_vm.verifyGoogleVisible=$$v},expression:"verifyGoogleVisible"}},[_c('a-form',{attrs:{"form":_vm.verifyGoogleAuthForm},on:{"submit":_vm.handleVerifyGoogleSubmit}},[_c('a-form-item',{attrs:{"label":"Verify Google Authenticator"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'google_code',
            {
              rules: [
                {
                  required: true,
                  len: 6,
                  message:
                    'Please enter the six-digit code from Google Authenticator',
                },
              ],
            },
          ]),expression:"[\n            'google_code',\n            {\n              rules: [\n                {\n                  required: true,\n                  len: 6,\n                  message:\n                    'Please enter the six-digit code from Google Authenticator',\n                },\n              ],\n            },\n          ]"}],staticClass:"google_input",attrs:{"placeholder":"Google Authenticator Code","size":"large"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"goToBtn",attrs:{"html-type":"submit","type":"primary","loading":_vm.enAbleLoading}},[_vm._v(" Confirm ")])],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth_container_left auth_container_left_three"},[_c('div',{staticClass:"auth_container_left_left"},[_c('img',{attrs:{"src":require("@/assets/email_cir.svg"),"alt":""}})]),_c('div',{staticClass:"auth_container_left_right"},[_c('h3',[_vm._v("Email")]),_c('div',{staticClass:"auth_left_tips"},[_vm._v(" For logging in,saving,retrieving passwords,security verification ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth_container_left auth_container_left_three"},[_c('div',{staticClass:"auth_container_left_left"},[_c('img',{attrs:{"src":require("@/assets/google_cat.svg"),"alt":""}})]),_c('div',{staticClass:"auth_container_left_right"},[_c('h3',[_vm._v("Google Authentication")]),_c('div',{staticClass:"auth_left_tips"},[_vm._v(" For login, password management and security verification ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth_container_left auth_container_left_three"},[_c('div',{staticClass:"auth_container_left_left"},[_c('img',{attrs:{"src":require("@/assets/password_cir.svg"),"alt":""}})]),_c('div',{staticClass:"auth_container_left_right"},[_c('h3',[_vm._v("Password")]),_c('div',{staticClass:"auth_left_tips"},[_vm._v("Used to protect account security")])])])
}]

export { render, staticRenderFns }