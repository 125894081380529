import Card1 from '@/assets/images/card_type/Card1.png';
import Card2 from '@/assets/images/card_type/Card2.png';
import Card3 from '@/assets/images/card_type/Card3.png';
import Card4 from '@/assets/images/card_type/Card4.jpg';
import Card5 from '@/assets/images/card_type/Card5.jpg';
import Card6 from '@/assets/images/card_type/Card6.jpg';
import Card7 from '@/assets/images/card_type/Card7.jpg';
import Card8 from '@/assets/images/card_type/Card8.jpg';
import Card10 from '@/assets/images/card_type/Card10.jpg';

import Card9 from '@/assets/images/card_type/Card9.jpg';
import Card11 from '@/assets/images/card_type/Card11.jpg';
import Card12 from '@/assets/images/card_type/Card12.jpg';
import Card13 from '@/assets/images/card_type/Card13.jpg';
import Card14 from '@/assets/images/card_type/Card14.jpg';
import Card15 from '@/assets/images/card_type/Card15.jpg';
import Card16 from '@/assets/images/card_type/Card16.jpg';
import Card17 from '@/assets/images/card_type/Card17.jpg';
import Card18 from '@/assets/images/card_type/Card18.jpg';

import card1 from '@/assets/card1.png';
import cardNew3 from '@/assets/card_new3.png';
import card1New from '@/assets/card1_new.svg';


const cardTypeA = [Card1, Card2, Card3, Card4];
const cardTypeB = [Card5, Card6, Card7, Card8, Card10];
const cardTypeC = [Card9, Card11, Card12, Card13, Card14];
const cardTypeD = [Card15, Card16, Card17, Card18];

export const getCardImageByInfo = (card) => {
  const {
    cardTypeId,
  } = card;
  const cardImgEnum = JSON.parse(localStorage.getItem('cardImgEnum') || '{}');

  // TODO: 直接获取接口返回的图片
  if (cardImgEnum && cardImgEnum[cardTypeId]) {
    return cardImgEnum[cardTypeId];
  }

  return '';
};

export const getRandomCardImage = () => {
  const randomCards = [
    ...cardTypeA,
    ...cardTypeB,
    ...cardTypeC,
    ...cardTypeD,
  ];

  const getRandom = (min, max) => {
    const index = Math.floor(Math.random() * (max - min + 1)) + min;
    return index;
  };
  const randomIndex = getRandom(0, randomCards.length - 1);
  return randomCards[randomIndex];
};

export const makeStorageOfCardList = (list) => {
  const cardImg = {
  };

  list.forEach((item) => {
    let cardImgPic = '';
    try {
      const json = JSON.parse(item.transaction_rules);
      cardImgPic = json.card_img;
    } catch (e) {
      console.log(e);
    }
    cardImg[item.card_type_id] = cardImgPic;
  });
  localStorage.setItem('cardImgEnum', JSON.stringify(cardImg));
};

export const getCurrentCardImg = (item) => {
  const configImage = getCardImageByInfo({
    cardTypeId: item.card_type_id,
  });
  if (configImage) {
    return configImage;
  }
  if (item.card_type === 0 && item.card_type_id !== '60000004') {
    return card1;
  }
  if (item.card_type === 0 && item.card_type_id === '60000004') {
    return cardNew3;
  }
  if (item.card_type === 1) {
    return card1New;
  }
  return getRandomCardImage();
};

export const name = '';
