<template>
  <div class="wrapper_card">
    <div class="card_container">
      <div class="CardHeader">
        <div class="title">Payment Method</div>
        <div class="titleTips">
          Complete your card information for a higher purchase limit.
        </div>
      </div>
      <a-spin :spinning="loading">
        <div class="assetDataContainer" v-if="CardData.length">
          <a-space>
            <div class="assetCard" v-for="item in CardData" :key="item.id">
              <div class="coinType">
                <div class="coinTypeLeft">
                  <img src="@/assets/MasterCard.png" alt="" />
                  <span class="coinName">MasterCard</span>
                </div>
                <div class="coinTypeRight">
                  <img
                    @click="goToEdit(item.status, item.id, item.memo)"
                    src="@/assets/editImg.svg"
                    alt=""
                  />
                </div>
              </div>
              <div class="coinMsg">
                <div>****</div>
                <div>****</div>
                <div>****</div>
                <div>****</div>
                <div>
                  {{
                    item?.card_number?.substring(
                      item?.card_number?.length - 4,
                      item?.card_number?.length
                    )
                  }}
                </div>
              </div>
            </div>
          </a-space>
        </div>
        <div class="noDataContainer" v-else>
          <img src="@/assets/noDataIcon.svg" alt="" />
          <div>No Card</div>
        </div>
        <div class="stepsButtonContainer">
          <a-button class="stepsButton" type="primary" @click="goToNew()">
            Add new card
          </a-button>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { getBindCardList } from '@/utils/api';
import { bindCardLength } from '@/utils/enum';

export default {
  name: 'Card',
  props: {
    changeDefaultMenu: {
      type: Function,
      require: false,
    },
  },
  data() {
    return {
      CardData: [],
      loading: true,
      bindCardLength,
    };
  },
  created() {
    const params = {
      status: '',
    };
    getBindCardList(params).then((res) => {
      this.loading = false;
      if (res.code === 0) {
        this.CardData = res.result;
      }
    });
  },
  methods: {
    goToEdit(status, id, reason) {
      if (status === 4 || status === 1 || status === 3) {
        this.changeDefaultMenu('bindCardResult', id, status, reason);
      } else {
        this.changeDefaultMenu('addNewCard', id);
      }
    },
    goToNew() {
      this.changeDefaultMenu('addNewCard');
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper_card {
  margin: auto;
  min-height: 800px;
  padding: 60px 0;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: #fff;
  @media (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    padding: 12px;
  }
  .card_container {
    max-width: 900px;
    margin: auto;
    .CardHeader {
      display: flex;
      flex-direction: column;
      .title {
        font-family: SamsungSharpSans-Bold;
        font-weight: bold;
        font-size: 40px;
        line-height: 50px;
        text-align: center;
        opacity: 0.8;
        color: #000;
      }
      .titleTips {
        font-family: Roboto;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        opacity: 0.3;
        margin-top: 30px;
        margin-bottom: 60px;
      }
    }
    .assetDataContainer {
      width: 100%;
      display: flex;
      @media (max-width: 750px) {
        height: auto;
      }
      .ant-space-align-center {
        flex-flow: row wrap;
        align-content: flex-start;
      }
      .ant-space {
        @media (max-width: 750px) {
          display: flex;
          flex-direction: column;
          width: 100%;
          .ant-space-item {
            margin-right: 0 !important;
            margin-bottom: 24px;
            width: 100%;
          }
        }
      }
      .assetCard {
        width: 390px;
        height: 120px;
        border-radius: 8px;
        padding: 20px;
        background: linear-gradient(113.75deg, #eaf0ff 1.3%, #f8faff 99.74%);
        margin: 0 15px;
        margin-bottom: 24px;
        cursor: pointer;
        @media (max-width: 750px) {
          width: 100%;
        }
        .coinType {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          justify-content: space-between;
          .coinTypeLeft {
            img {
              width: 64px;
              margin-right: 20px;
            }
            .coinName {
              height: 24px;
              line-height: 24px;
              font-family: "Poppins-Regular";
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
            }
          }
          .coinTypeRight {
            img {
              width: 24px;
            }
          }
        }
        .coinMsg {
          display: flex;
          font-size: 16px;
          div {
            margin-right: 4px;
          }
        }
      }
    }
    .noDataContainer {
      text-align: center;
      img {
        width: 48px;
      }
      div {
        font-size: 16px;
        opacity: 0.6;
      }
    }
  }
  .stepsButtonContainer {
    margin-top: 24px;
    text-align: center;
    .stepsButton {
      width: 300px;
      height: 60px;
      border-radius: 8px;
      margin: 0 8px 20px;
      @media (max-width: 960px) {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}
</style>
