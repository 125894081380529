<template>
  <div class="wrapper_wallet_history">
    <div class="deposit_bank_card_container_content">
      <div class="back_container" @click="backBtn">
        <a-icon type="arrow-left" />
      </div>
      <div class="title">Transaction History</div>
    </div>
    <a-tabs class="tabs_container">
      <a-tab-pane key="2" tab="History">
        <a-table :columns="withdrawColumns" :data-source="withdrawData" :loading="withdrawLoading"
          @change="onDepositTableChange" :pagination="{
            pageSize: 10,
            showTotal: (total) => `Total ${total} items`,
            total,
          }">
          <span slot="external_tx_id" slot-scope="text, record">
            <span
              :class="record.status === 0 ? 'orderNo' : null"
              @click="openOrderLink(record)"
            >{{ text }}</span>
          </span>
          <span slot="tx_type" slot-scope="text">
            {{ text || '-' }}
          </span>
          <span slot="create_time" slot-scope="text">
            {{ $moment(text).format("YYYY-MM-DD HH:mm:ss") }}
          </span>
          <span slot="update_time" slot-scope="text">
            {{ $moment(text).format("YYYY-MM-DD HH:mm:ss") }}
          </span>
          <span slot="status" slot-scope="text">
            <span v-if="text === 0" class="g-pending">Pending</span>
            <span v-else-if="text === 1" class="g-success">Succeed</span>
            <span v-else-if="text === 2" class="g-process">Confirming</span>
            <span v-else-if="text === 3" class="fail">Abnormal</span>
            <span v-else-if="text === 4" class="fail">Failed</span>
            <span v-else-if="text === 5" class="fail">Canceled</span>
            <span v-else-if="text === 6" class="g-expire">Expire</span>
            <span v-else-if="text === 7" class="g-pending">Refund in progress</span>
            <span v-else-if="text === 8" class="g-success">Refund Succeed</span>
            <span v-else-if="text === 9" class="fail">Refund failed</span>
            <span v-else-if="text === 99" class="fail">Channel unmatched</span>
          </span>
          <span slot="note" slot-scope="text">
            {{ text || '-' }}
          </span>
          <a
            slot="details"
            slot-scope="text, record" class="details-btn" @click="orderDetails(record)">Details</a>
        </a-table>
      </a-tab-pane>
      <!-- <a-tab-pane key="3" tab="Exchange"> Coming Soon </a-tab-pane> -->
    </a-tabs>
    <a-modal v-model="orderModalVisible" centered :footer="null" width="450" :maskClosable="false">
      <iframe :src="orderUrl" height="800" width="400" frameBorder="no">
      </iframe>
    </a-modal>
    <a-modal
      v-model="speaModalVisible"
      title="Funding Your Account with Bank Transfer(SPEA)"
      centered
      :footer="null"
      width="450"
      :maskClosable="false"
    >
      <div class="info_title">Order information</div>
      <div class="deposit_bank_msg_container">
        <div class="deposit_bank_msg_container_left">
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Order ID</div>
            <div class="line_right">{{ speaData.external_tx_id }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Wallet ID</div>
            <div class="line_right">{{ speaData.address }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Amount</div>
            <div class="line_right">
              {{ speaData.amount }} {{ speaData.currency }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Reference Code</div>
            <div class="line_right">
              {{ speaData.postscript }}
              <span
              class="copyBtn"
              v-clipboard:copy="speaData.postscript"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError">
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="attention_tips">
        Please transfer the fund within 4 hours. Send the order reference code
        with the transaction so we can know it’s from you.
      </div>
      <div class="attention_tips_tips">
        Make a payment to Inst using the following bank account details .
      </div>
      <div class="info_title">Bank information</div>
      <div class="deposit_bank_msg_container">
        <div class="deposit_bank_msg_container_left">
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Account Name</div>
            <div class="line_right">
              {{ speaData.bank.account_name}}
              <span
              class="copyBtn"
              v-clipboard:copy="speaData.bank.account_name"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError">
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">IBAN</div>
            <div class="line_right">
              {{ speaData.bank.account_id}}
              <span class="copyBtn"
              v-clipboard:copy="speaData.bank.account_id"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError">
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Bank Name</div>
            <div class="line_right">
              {{ speaData.bank.bank_name}}
              <span class="copyBtn"
              v-clipboard:copy="speaData.bank.bank_name"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError">
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">BIC(SWIFT Code)</div>
            <div class="line_right">
              {{ speaData.bank.bic}}
              <span class="copyBtn"
                v-clipboard:copy="speaData.bank.bic"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError">
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Bank Address</div>
            <div class="line_right">
              {{ speaData.bank.bank_address}}
              <span
                  class="copyBtn"
                  v-clipboard:copy="speaData.bank.bank_address"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError">
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal
    v-model="transferModalVisible"
    title="Details"
    centered
    :footer="null"
    :maskClosable="false">
      <div class="deposit_bank_msg_container">
        <div class="deposit_bank_msg_container_left">
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">From User</div>
            <div class="line_right">{{ transferData.from_name }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">User's Wallet</div>
            <div class="line_right">{{ transferData.from_address }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">To User</div>
            <div class="line_right">
              {{ transferData.to_name }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">User's Wallet</div>
            <div class="line_right">
              {{ transferData.to_address }}
            </div>
          </div>

          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Currency</div>
            <div class="line_right">
              {{ transferData.currency }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Amount</div>
            <div class="line_right">
              {{ transferData.amount }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Note</div>
            <div class="line_right">
              {{ transferData.note }}
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal
    v-model="walletModalVisible"
    title="Details"
    centered
    :footer="null"
    :maskClosable="false"
    width="720px">
      <div class="details_tips_title">Order Details:</div>
      <div class="deposit_bank_msg_container">
        <div class="deposit_bank_msg_container_left">
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Order No.</div>
            <div class="line_right">
              {{ walletData.order_no }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Amount</div>
            <div class="line_right">
              {{ walletData.amount }} {{ walletData.currency }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Type</div>
            <div class="line_right">
              {{ walletData.tx_type }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Note</div>
            <div class="line_right">
              {{ walletData.note || '-' }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Payment Status</div>
            <div class="line_right">
              <span v-if="walletData.status === 0" class="g-pending">Pending</span>
              <span v-else-if="walletData.status === 1" class="g-success">Succeed</span>
              <span v-else-if="walletData.status === 2" class="g-process">Confirming</span>
              <span v-else-if="walletData.status === 3" class="fail">Abnormal</span>
              <span v-else-if="walletData.status === 4" class="fail">Failed</span>
              <span v-else-if="walletData.status === 5">Canceled</span>
              <span v-else-if="walletData.status === 6" class="g-expire">Expire</span>
              <span v-else-if="walletData.status === 7" class="g-pending">Refund in progress</span>
              <span v-else-if="walletData.status === 8" class="g-success">Refund Succeed</span>
              <span v-else-if="walletData.status === 9" class="fail">Refund failed</span>
              <span v-else-if="walletData.status === 99" class="fail">Channel unmatched</span>
            </div>
          </div>
          <div v-if="walletData.details">
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Purchase</div>
              <div class="line_right">
                {{ walletData.details.amount }}
                {{ walletData.details.coin_type }}
              </div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Destination Address</div>
              <div class="line_right">
                {{ walletData.details.address }}
              </div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Transaction Hash</div>
              <div class="line_right">
                {{ walletData.details.tx_hash }}
              </div>
            </div>
            <div class="deposit_bank_msg_container_line">
              <div class="line_left">Transfer Status</div>
              <div class="line_right">
                <span v-if="walletData.details.status === 0">Pending</span>
                <span v-else-if="walletData.details.status === 1" class="success">Succeed</span>
                <span v-else-if="walletData.details.status === 2" class="fail">Failed</span>
                <span v-else-if="walletData.details.status === 3">Cancelled</span>
                <span v-else-if="walletData.details.status === 4">Pending</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="details_tips_title">Wallet Details:</div>
      <div class="deposit_bank_msg_container">
        <div class="deposit_bank_msg_container_left">
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Wallet Transaction ID</div>
            <div class="line_right">{{ walletData.external_tx_id }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Wallet</div>
            <div class="line_right">{{ walletData.address }}</div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Amount</div>
            <div class="line_right">
              {{ walletData.amount }} {{ walletData.currency }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Balance</div>
            <div class="line_right">
              {{ walletData.balance || "-" }} {{ walletData.currency }}
            </div>
          </div>
          <div class="deposit_bank_msg_container_line">
            <div class="line_left">Time Updated</div>
            <div class="line_right">
              {{
                $moment(walletData.update_time).format("YYYY-MM-DD HH:mm:ss")
              }}
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getWithdrawHistory } from '@/utils/api';
import { basePayonePayUrl } from '@/utils/baseUrl';

export default {
  name: 'Wallet',
  props: {
    changeDepositDefaultMenu: {
      type: Function,
      require: true,
    },
  },
  components: {},
  data() {
    return {
      withdrawLoading: true,
      withdrawData: [],
      withdrawColumns: [
        {
          title: 'Transaction ID',
          dataIndex: 'external_tx_id',
          key: 'external_tx_id',
          scopedSlots: { customRender: 'external_tx_id' },
        },
        { title: 'Currency', dataIndex: 'currency', key: 'currency' },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
        },
        {
          title: 'Type',
          dataIndex: 'tx_type',
          key: 'tx_type',
          scopedSlots: { customRender: 'tx_type' },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Time Created',
          dataIndex: 'create_time',
          key: 'create_time',
          scopedSlots: { customRender: 'create_time' },
        },
        {
          title: 'Note',
          dataIndex: 'note',
          key: 'note',
          scopedSlots: { customRender: 'note' },
        },
        {
          title: 'Operation',
          dataIndex: 'details',
          key: 'details',
          scopedSlots: { customRender: 'details' },
        },
      ],
      current: 1,
      total: 0,
      page_size: 10,
      orderModalVisible: false,
      orderUrl: '',
      speaModalVisible: false,
      speaData: { bank: {} },
      transferModalVisible: false,
      transferData: {},
      walletModalVisible: false,
      walletData: {},
    };
  },
  created() {
    const params = {
      page_num: this.current,
      page_size: this.page_size,
    };
    this.getHistory(params);
  },
  methods: {
    onCopy() {
      this.$message.success('Copy Success');
    },
    onError() {
      this.$message.error('Copy Failed');
    },
    backBtn() {
      this.changeDepositDefaultMenu('showTab');
    },
    getHistory(params) {
      getWithdrawHistory(params).then((res) => {
        this.withdrawLoading = false;
        if (res.code === 0) {
          this.withdrawData = res.result.records.map((item) => {
            const txTypeEnum = {
              '01': 'Card Deposit',
              '02': 'Bank Deposit',
              '03': 'Transfer Out',
              '04': 'Transfer In',
              '05': 'Buy Crypto',
              '06': 'Refund In',
              '07': 'Refund Out',
              '08': 'Deduction In',
              '09': 'Deduction Out',
              10: 'Burn',
              11: 'Pay Out',
              12: 'Wallet FX',
              13: 'Manual Deposit',
              14: 'SEPA Transfer out',
              15: 'Platform Deposit',
              16: 'Send to card',
            };

            return {
              ...item,
              tx_type: txTypeEnum[item.tx_type] || item.tx_type,
            };
          });
          this.total = res.result.total;
        }
      });
    },
    onDepositTableChange(pagination) {
      const params = {
        page_num: pagination.current,
        page_size: this.page_size,
      };
      this.current = pagination.current;
      this.withdrawLoading = true;
      this.getHistory(params);
    },
    orderDetails(item) {
      this.walletModalVisible = true;
      this.walletData = item;
    },
    openOrderLink(item) {
      console.log('item', item);
      if (item.status === 0) {
        if (item.tx_type === '01') {
          this.orderUrl = `${basePayonePayUrl}/?id=${item.tx_id}`;
          this.orderModalVisible = true;
        } else if (item.tx_type === '02') {
          this.speaModalVisible = true;
          this.speaData = item;
        } else if (item.tx_type === '03' || item.tx_type === '04') {
          this.transferModalVisible = true;
          this.transferData = item;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper_wallet_history {
  @media (max-width: 960px) {
    width: 100%;
  }

  .deposit_bank_card_container_content {
    background: #fff;
    border-radius: 4px;
    position: relative;

    .back_container {
      position: absolute;
      font-size: 18px;
      cursor: pointer;
      top: 12px;
    }

    .title {
      font-family: SamsungSharpSans-Bold;
      font-weight: bold;
      font-size: 24px;
      line-height: 50px;
      opacity: 0.8;
      color: #000;
      margin-left: 28px;
    }
  }

  .tabs_container {
    margin-left: 20px;

    /deep/ .ant-table-wrapper {
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
    }
  }
}

.g-success {
  color: #52c41a;
}

.g-fail {
  color: #e40520;
}

.g-pending {
  color: #ff8e00;
}

.g-process {
  color: #0045ff;
}

.g-expire {
  color: #cccccc;
}

.details-btn {
  border-radius: 8px;
  height: 40px;
  width: 120px;
}

.info_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 10px;
}

.info_tips_icon {
  width: 8px;
  margin: 0px 0 4px;
}

.deposit_bank_msg_container {
  display: flex;
  width: 100%;
  border-radius: 8px;
  padding: 20px 30px;
  margin: 0px 0 20px;
  background: rgba(0, 69, 255, 0.03);

  .deposit_bank_msg_container_left {
    display: flex;
    flex: 1;
    flex-direction: column;

    .deposit_bank_msg_container_line {
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding: 10px 0;

      .line_left {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }

      .line_right {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: right;

        .copyBtn {
          margin-left: 10px;
        }
      }
    }
  }
}

.attention_tips {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ff8e00;
  margin: 12px 0 40px;
}

.attention_tips_tips {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;
}

.deposit_bank_steps {
  margin: 40px 0 20px;
  padding: 20px 30px;
  background: rgba(0, 69, 255, 0.03);
  border-radius: 8px;

  .deposit_bank_steps_line {
    margin: 10px 0;

    @media (max-width: 960px) {
      display: flex;
    }

    img {
      width: 32px;
    }

    padding: 10px 0;

    span {
      margin-left: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }
}

.orderNo {
  cursor: pointer;
  color: #0045ff;
}

.details_tips_title {
  margin-bottom: 8px;
}
</style>
