<template>
  <div class="kycContainer">
    <div class="kycContainerContent">
      <div class="backContainer" @click="backBtn">
        <a-icon type="arrow-left" />
      </div>
      <div class="title">Identification</div>
      <a-spin :spinning="loading">
        <div v-if="kycLevel === 3 && level_three_status === null">
          <div class="stepContainer">
            <a-space class="inputContainer" :size="20">
              <div class="inputContent" :size="20" direction="vertical">
                <div class="inputLabel">
                  <span>Selfie With passport</span>
                  <a-upload
                    name="file"
                    :showUploadList="false"
                    :action="uploadUrl"
                    :headers="headers"
                    maxCount="1"
                    :before-upload="beforeUpload"
                    @change="handleMixChange"
                  >
                    <a-button class="uploadBtn" type="primary">
                      <span>
                        upload
                        <img src="@/assets/cloudUpload.svg" alt="upload" />
                      </span>
                    </a-button>
                  </a-upload>
                </div>
                <div>
                  <div class="idCardContainer">
                    <a-spin :spinning="frontLoading">
                      <img
                        v-if="mixHttpImgUrl || mixImgUrl"
                        :src="mixHttpImgUrl || mixImgUrl"
                        alt="mix card"
                      />
                      <img
                        v-if="!mixHttpImgUrl && !mixImgUrl"
                        src="@/assets/selfieCard.svg"
                        alt="front card"
                      />
                    </a-spin>
                  </div>
                  <span class="uploadDescribe"
                    >*Make sure the text on the passport is clear(please do not
                    use selfie mode or mirror the photo);your entire face is
                    visible,and your shoulders and arms are included in the
                    photo.
                  </span>
                </div>
              </div>
            </a-space>
          </div>
          <div class="stepsButtonContainer">
            <a-button class="stepsButton" type="primary" @click="submitKyc">
              Submit
            </a-button>
          </div>
        </div>
        <div
          v-else-if="
            (kycLevel === 2 && status === null) ||
            (kycLevel === 1 && driver_status === null)
          "
        >
          <div class="stepContainer">
            <a-space class="inputContainer" :size="20">
              <div class="inputContent">
                <div class="inputLabel">First name</div>
                <a-input
                  class="stepInput"
                  :value="firstName"
                  placeholder="Please enter your legal first name"
                  @change="
                    (e) => {
                      this.firstName = e.target.value;
                    }
                  "
                />
              </div>
              <div class="inputContent">
                <div class="inputLabel">Last name</div>
                <a-input
                  class="stepInput"
                  :value="lastName"
                  placeholder="Please enter your legal last name"
                  @change="
                    (e) => {
                      this.lastName = e.target.value;
                    }
                  "
                />
              </div>
            </a-space>
            <a-space class="inputContainer" :size="20">
              <div class="inputContent">
                <div class="inputLabel">Nationality</div>
                <a-select
                  v-if="!!nationality"
                  :value="nationality"
                  :options="countryOption"
                  placeholder="Please select your nationality"
                  @change="(value) => (this.nationality = value)"
                />
                <a-select
                  v-else
                  :options="countryOption"
                  placeholder="Please select your nationality"
                  @change="(value) => (this.nationality = value)"
                />
              </div>
              <div class="inputContent">
                <div class="inputLabel">ID type</div>
                <a-select
                  :defaultValue="idType"
                  :options="idTypeOption"
                  @change="idTypeChange"
                  :disabled="true"
                />
              </div>
            </a-space>
            <a-space class="inputContainer" :size="20">
              <div class="inputContent">
                <div class="inputLabel" v-if="idType === 'passport'">
                  Passport Number
                </div>
                <div v-else class="inputLabel">Driver's License Number</div>
                <a-input
                  class="stepInput"
                  v-if="idType === 'passport'"
                  :value="idNumber"
                  :placeholder="defaultPassportTips"
                  @change="(e) => (this.idNumber = e.target.value)"
                />
                <a-input
                  class="stepInput"
                  v-else
                  :value="driver_doc_no"
                  :placeholder="defaultPassportTips"
                  @change="(e) => (this.driver_doc_no = e.target.value)"
                />
              </div>
              <div class="inputContent">
                <div class="inputLabel">Date of birth</div>
                <a-date-picker
                  :value="dateString"
                  @change="dateChange"
                  placeholder="Please select your birthday"
                />
              </div>
            </a-space>
            <a-space class="inputContainer" :size="20">
              <div class="inputContent" :size="20" direction="vertical">
                <div class="inputLabel">
                  <span v-if="idType === 'passport'">Passport Photo</span>
                  <span v-else>Driver's License Photo</span>
                  <a-upload
                    name="file"
                    :showUploadList="false"
                    :action="uploadUrl"
                    :headers="headers"
                    maxCount="1"
                    :before-upload="beforeUpload"
                    @change="handleChange"
                  >
                    <a-button class="uploadBtn" type="primary">
                      <span>
                        upload
                        <img src="@/assets/cloudUpload.svg" alt="upload" />
                      </span>
                    </a-button>
                  </a-upload>
                </div>
                <div>
                  <div class="idCardContainer">
                    <a-spin :spinning="frontLoading">
                      <img
                        v-if="
                          idType === 'passport' &&
                          (frontHttpImgUrl || frontImgUrl)
                        "
                        :src="frontHttpImgUrl || frontImgUrl"
                        alt="front card"
                      />
                      <img
                        v-if="
                          idType === 'driving' &&
                          (driver_front_doc || driverFrontImgUrl)
                        "
                        :src="driver_front_doc || driverFrontImgUrl"
                        alt="front card"
                      />
                      <img
                        v-if="
                          !frontHttpImgUrl &&
                          !frontImgUrl &&
                          idType === 'passport'
                        "
                        src="@/assets/passwordImg.svg"
                        alt="front card"
                      />
                      <img
                        v-if="
                          !driver_front_doc &&
                          !driverFrontImgUrl &&
                          idType === 'driving'
                        "
                        src="@/assets/drivingImg.svg"
                        alt="front card"
                      />
                    </a-spin>
                  </div>
                  <span class="uploadDescribe"
                    >*Your photo must be clear and colored. It can be a scanned
                    copy or a digital photo.
                  </span>
                  <span class="uploadDescribe"
                    >*Supported formats include jpg, png and jpeg.
                  </span>
                  <span class="uploadDescribe"
                    >*Image size must not exceed 4MB. JPG, JPEG and PNG formats
                    are supported</span
                  >
                </div>
              </div>
            </a-space>
          </div>
          <div class="stepsButtonContainer">
            <a-button class="stepsButton" type="primary" @click="submitKyc">
              Submit
            </a-button>
          </div>
        </div>
        <div
          v-else-if="
            (kycLevel === 2 && status === 0) ||
            (kycLevel === 1 && driver_status === 0) ||
            (kycLevel === 3 && level_three_status === 0)
          "
          class="resultContent"
        >
          <div class="resultContainer">
            <a-icon
              class="topImg"
              type="clock-circle"
              theme="twoTone"
              :style="{ 'font-size': '48px' }"
            />
            <h2>Your identity verification is under review.</h2>
          </div>
        </div>
        <div
          v-else-if="
            (kycLevel === 2 && status === 7) ||
            (kycLevel === 1 && driver_status === 7) ||
            (kycLevel === 3 && level_three_status === 7)
          "
          class="resultContent"
        >
          <div class="resultContainer">
            <a-icon
              type="close-circle"
              theme="twoTone"
              style="font-size: 48px"
            />
            <h2>Sorry, your application was rejected.</h2>
            <h4>Reason: {{ reason }}</h4>
            <a-button class="goToBtn" @click="reApply()">Reapply</a-button>
          </div>
        </div>
        <div v-else class="resultContent">
          <div class="resultContainer">
            <a-icon
              class="topImg"
              type="check-circle"
              theme="twoTone"
              :style="{ 'font-size': '48px' }"
            />
            <h2>Your identity is verified.</h2>
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>
<!-- eslint-disable camelcase -->
<script>

import moment from 'moment';
import countryList from '@/utils/Country.json';
// import NewFooter from '@/components/NewFooter.vue';
import { getAccountKyc, submitAccountKyc } from '@/utils/api';
import { reasonOptions } from '@/utils/enum';
import { baseAdminUrl } from '@/utils/baseUrl';

export default {
  name: 'Kyc',
  props: {
    kycLevel: {
      type: Number,
      require: true,
    },
    changeStep: {
      type: Function,
      require: false,
    },
  },
  components: {
    // NewFooter,
  },
  data() {
    return {
      loading: true,
      dateFormat: 'YYYY-MM-DD',
      countryOption: [],
      idTypeOption: [
        { label: 'Passport', value: 'passport' },
        { label: "Driver's License", value: 'driving' },
      ],
      kycMsg: {},
      dateString: null,
      nationality: null,
      idType: null,
      firstName: '',
      lastName: '',
      idNumber: '',
      driver_doc_no: '',
      status: null,
      driver_status: null,
      level_three_status: null,
      reason: '',
      headers: {
        authorization: localStorage.getItem('inst_money_access_token') || '',
      },
      frontLoading: false,
      frontHttpImgUrl: '',
      frontImgUrl: '',
      driver_front_doc: '',
      driverFrontImgUrl: '',
      uploadUrl: `${baseAdminUrl}/v1/common/file/public`,
      defaultPassportTips: 'Please enter Passport Number',
      mixHttpImgUrl: '',
      mixImgUrl: '',
    };
  },
  created() {
    const initCountry = [];
    countryList.forEach((item) => {
      initCountry.push({
        value: item.countryCode,
        label: item.name_en,
        code: item.code,
        key: item.name_cn,
      });
    });
    this.countryOption = initCountry;
    this.getKycDetail();
  },
  methods: {
    getKycDetail() {
      if (this.kycLevel === 1) {
        this.idType = 'driving';
      } else {
        this.idType = 'passport';
      }
      getAccountKyc().then((res) => {
        this.loading = false;
        if (res.result) {
          this.kycMsg = res.result;
          const {
            nationality,
            birthday,
            doc_no,
            doc_type,
            first_name,
            last_name,
            front_doc,
            driver_front_doc,
            mix_doc,
            status,
            driver_status,
            level_three_status,
            driver_doc_no,
            reason,
          } = res.result;
          this.firstName = first_name;
          this.lastName = last_name;
          this.dateString = moment(birthday).format('YYYY-MM-DD');
          this.idNumber = doc_no;
          // this.idType = doc_type;
          this.nationality = nationality;
          this.status = status;
          this.driver_status = driver_status;
          this.level_three_status = level_three_status;
          this.driver_doc_no = driver_doc_no;
          if (doc_type === 'driving') {
            this.defaultPassportTips = "Please enter Driver's License Number";
          } else {
            this.defaultPassportTips = 'Please enter Passport Number';
          }
          reasonOptions.forEach((item) => {
            if (reason === item.value) {
              this.reason = item.en;
            }
          });
          this.frontHttpImgUrl = front_doc;
          this.driver_front_doc = driver_front_doc;
          this.mixHttpImgUrl = mix_doc;
        }
      });
    },
    backBtn() {
      this.changeStep('authentication');
    },
    reApply() {
      if (this.kycLevel === 1) {
        this.driver_status = null;
      } else if (this.kycLevel === 2) {
        this.status = null;
      } else if (this.kycLevel === 3) {
        this.level_three_status = null;
      }
    },
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG/PNG file!');
      }
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        this.$message.error('Image must smaller than 4MB!');
      }
      return isJpgOrPng && isLt4M;
    },
    idTypeChange(value) {
      if (value === 'driving') {
        this.defaultPassportTips = "Please enter Driver's License Number";
      } else {
        this.defaultPassportTips = 'Please enter Passport Number';
      }
      this.idType = value;
    },
    dateChange(date, dateString) {
      this.dateString = dateString;
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.frontLoading = true;
        return;
      }
      if (info.file.status === 'done') {
        this.frontLoading = false;
        if (info?.file?.response?.code === 0) {
          if (this.idType === 'passport') {
            this.frontHttpImgUrl = info?.file?.response?.result;
            this.getBase64(info.file.originFileObj, (imageUrl) => {
              this.frontImgUrl = imageUrl;
            });
          } else {
            this.driver_front_doc = info?.file?.response?.result;
            this.getBase64(info.file.originFileObj, (imageUrl) => {
              this.driverFrontImgUrl = imageUrl;
            });
          }
        }
      }
    },
    handleMixChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'done') {
        this.loading = false;
        if (info?.file?.response?.code === 0) {
          this.mixHttpImgUrl = info?.file?.response?.result;
          this.getBase64(info.file.originFileObj, (imageUrl) => {
            this.mixImgUrl = imageUrl;
          });
        }
      }
    },
    submitKyc() {
      if (this.kycLevel === 3) {
        const params = {
          ...this.kycMsg,
          kyc_level: this.kycLevel,
          level_three_status: 0,
          mix_doc: this.mixHttpImgUrl,
        };
        submitAccountKyc(params).then((res) => {
          this.$message.success(`${res.msg}`);
          this.level_three_status = 0;
        });
      } else if (!this.nationality) {
        this.$message.error('Please select nationality');
      } else if (!this.firstName) {
        this.$message.error('Please enter your first name');
      } else if (!this.lastName) {
        this.$message.error('Please enter your last name');
      } else if (!this.idType) {
        this.$message.error('Please select your ID type');
      } else if (this.idType === 'passport' && !this.idNumber) {
        this.$message.error('Please enter your Passport Number');
      } else if (this.idType === 'driving' && !this.driver_doc_no) {
        this.$message.error("Please enter your Driver's License Number");
      } else if (!this.dateString) {
        this.$message.error('Please select your birthday');
      } else if (
        (this.idType === 'passport' && !this.frontHttpImgUrl)
        || (this.idType === 'driving' && !this.driver_front_doc)
      ) {
        this.$message.error('Please upload passport photo');
      } else {
        const params = {
          first_name: this.firstName,
          last_name: this.lastName,
          birthday: moment(this.dateString).format('x'),
          nationality: this.nationality,
          kyc_level: this.kycLevel,
          doc_type: this.idType,
        };
        if (this.kycLevel === 1) {
          params.driver_doc_no = this.driver_doc_no;
          params.driver_status = 0;
          params.driver_front_doc = this.driver_front_doc;
        } else if (this.kycLevel === 2) {
          params.doc_no = this.idNumber;
          params.status = 0;
          params.front_doc = this.frontHttpImgUrl;
        }
        submitAccountKyc(params).then((res) => {
          this.$message.success(`${res.msg}`);
          if (this.kycLevel === 1) {
            this.driver_status = 0;
          } else {
            this.status = 0;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.kycContainer {
  @media (max-width: 960px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .kycContainerContent {
    background: #fff;
    border-radius: 4px;
    padding: 24px;
    min-height: 800px;
    position: relative;
    .backContainer {
      position: absolute;
      font-size: 18px;
      cursor: pointer;
      top: 60px;
      z-index: 999;
    }
    .title {
      font-family: SamsungSharpSans-Bold;
      font-weight: bold;
      font-size: 40px;
      line-height: 50px;
      text-align: center;
      opacity: 0.8;
      color: #000;
      margin-top: 24px;
    }
    .stepContainer {
      margin-top: 10px;
      .inputContainer {
        @media (max-width: 960px) {
          display: block;
          /deep/ .ant-space-item {
            width: 100%;
          }
        }
        width: 100%;
        /deep/ .ant-space-item {
          flex: 1;
        }
        .inputContent {
          margin-top: 10px;
          .inputLabel {
            margin-bottom: 10px;
            font-size: 16px;
          }
          .stepInput {
            height: 60px;
            border-radius: 8px;
            /deep/ input {
              height: 60px;
            }
          }
          .ant-select {
            width: 100%;
            height: 60px;
            /deep/ .ant-select-selection {
              height: 60px !important;
              border-radius: 8px;
              .ant-select-selection__rendered {
                height: 60px !important;
                line-height: 60px;
              }
            }
          }
          .ant-calendar-picker {
            height: 60px;
            width: 100%;
            border-radius: 8px;
            /deep/ .ant-calendar-picker-input {
              height: 60px;
            }
          }
          .uploadBtn {
            margin-left: 20px;
            border-radius: 4px;
            font-size: 0;
            span {
              font-size: 14px;
            }
            img {
              height: 24px;
              margin-left: 10px;
            }
          }
          .idCardContainer {
            width: 100%;
            height: 200px;
            border: 1px solid #d9d9d9;
            border-radius: 8px;
            text-align: center;
            margin-bottom: 10px;
            img {
              height: 180px;
              margin-top: 10px;
            }
          }
          .uploadDescribe {
            display: block;
            font-size: 14px;
            line-height: 24px;
            opacity: 0.3;
          }
          .amountTips {
            text-align: end;
            span {
              font-size: 14px;
              opacity: 0.5;
            }
          }
          .inputBottom {
            color: #0045ff;
            font-size: 20px;
          }
        }
        .stepInputContainer {
          display: flex;
          flex-direction: row;
          .ant-select {
            width: 300px;
            margin-right: 40px;
          }
          .sendBtn {
            margin-left: 40px;
            height: 60px;
            width: 300px;
            border-radius: 8px;
          }
        }
      }
    }
    .stepsButtonContainer {
      margin-top: 60px;
      text-align: center;
      .stepsButton {
        width: 300px;
        height: 60px;
        border-radius: 8px;
        margin: 0 8px 20px;
        @media (max-width: 960px) {
          width: 100%;
          margin-bottom: 20px;
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
    .resultContent {
      margin-top: 48px;
      .resultContainer {
        text-align: center;
        margin-top: 24px;
        h2 {
          margin: 24px auto;
        }
      }
    }
  }
}
.goToBtn {
  background: #0045ff;
  border-radius: 8px;
  height: 48px;
  width: 120px;
  color: #fff;
  margin-top: 24px;
}
</style>
