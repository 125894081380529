<template>
  <div class="google_auth_wrapper">
    <div class="backContainer" @click="backBtn">
      <a-icon type="arrow-left" />
    </div>
    <h2>Turn on Google two-step verification</h2>
    <div class="google-container">
      <div class="title">
        <div class="step">1</div>
        <div class="tips">
          <div>Download Google Authenticator</div>
          <div class="step_tips">
            iOS users download the app from the App Store. Android users
            download the app from Android app stores or mobile web browsers.
          </div>
        </div>
      </div>
      <div class="qrcode_container">
        <div class="qrcode_container_dis">
          <qrcode-vue
            value="https://google-authenticator.softonic.cn/iphone/download"
            size="160"
          ></qrcode-vue>
          <div class="down_tips">iOS APP Download</div>
        </div>
        <div class="qrcode_container_dis">
          <qrcode-vue
            value="https://google-authenticator.softonic.cn/android/download"
            size="160"
          ></qrcode-vue>
          <div class="down_tips">Android APP Download</div>
        </div>
      </div>
      <div class="title">
        <div class="step">2</div>
        <div class="tips">
          <div>Add your key to Google Authenticator and back up</div>
          <div class="step_tips">
            Use Google Authenticator to scan the following QR code or manually
            enter the key.
          </div>
        </div>
      </div>
      <a-spin :spinning="loading">
        <div class="add_key_container">
          <div class="auto_key_left">
            <qrcode-vue :value="qrCode" size="120"></qrcode-vue>
          </div>
          <div class="auth_key_right">
            <div class="auth_key_right_key">Key: {{ key }}</div>
            <div class="auth_key_right_copy">
              <span
                class="copyBtn"
                v-clipboard:copy="key"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
              >
                <a-icon class="copyAddress" type="copy" theme="twoTone" />
              </span>
              Copy
            </div>
          </div>
        </div>
      </a-spin>
      <div class="tips_container">
        <div class="title_tips">
          <img src="@/assets/tips-ts.svg" alt="tips" />
          <span>Attention:</span>
        </div>
        <div class="tips">
          The key is for recovering the access to Authenticator when you switch
          device or the device is lost. Please save a backup of the key before
          you add it to Authenticator.
        </div>
      </div>
      <a-form :form="authBindForm" @submit="handleSubmit">
        <div class="title">
          <div class="step">3</div>
          <div class="tips">
            <div>Enter the six-digit code from Google Authenticator</div>
            <div class="step_tips">
              <a-form-item>
                <a-input
                  class="google_input"
                  placeholder="Google Authenticator"
                  size="large"
                  v-decorator="[
                    'google_code',
                    {
                      rules: [
                        {
                          required: true,
                          len: 6,
                          message:
                            'Please enter the six-digit code from Google Authenticator!',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>
        </div>
        <div class="bottom_container">
          <a-button class="submit" type="primary" html-type="submit"
            >Submit</a-button
          >
        </div>
      </a-form>
    </div>
    <a-modal
      v-model="sendMailVisible"
      title="Security verification"
      :footer="null"
      :maskClosable="false"
      centered
    >
      <a-form :form="sendMailForm" @submit="handleBindSubmit">
        <a-form-item label="Verify your email">
          <a-row :gutter="8">
            <a-col :span="18">
              <a-input
                v-decorator="[
                  'email-code',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter the captcha you got',
                      },
                    ],
                  },
                ]"
              />
            </a-col>
            <a-col :span="6">
              <a-button
                class="sendMailBtn"
                type="primary"
                @click="getCaptcha"
                :loading="sendEmailLoading"
                :disabled="sendEmailDisabled"
                >{{ sendEmailText }}</a-button
              >
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item class="confirmContainer">
          <a-button class="goToBtn" html-type="submit"> Confirm </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import { gaAuth, sendEmail, bindGa } from '@/utils/api';

export default {
  name: 'ChangePassword',
  components: {
    QrcodeVue,
  },
  props: {
    changeShower: {
      type: Function,
      require: false,
    },
  },
  data() {
    return {
      authBindForm: this.$form.createForm(this, { name: 'authBindFormName' }),
      sendMailForm: this.$form.createForm(this, { name: 'sendMailFormName' }),
      loading: true,
      key: '123',
      qrCode: '123',
      google_code: '',
      sendEmailLoading: false,
      sendEmailDisabled: false,
      sendMailVisible: false,
      sendEmailText: 'Send',
      mail_code_token: '',
      mail: '',
      timer: null,
      defaultTime: 60,
    };
  },
  created() {
    this.mail = localStorage.getItem('inst_mail');
    gaAuth().then((res) => {
      this.loading = false;
      if (res.code === 0) {
        this.key = res.result.secret;
        this.qrCode = res.result.qrCode;
      }
    });
  },
  methods: {
    onCopy() {
      this.$message.success('Copy Success');
    },
    onError() {
      this.$message.error('Copy Failed');
    },
    backBtn() {
      this.changeShower('security');
    },
    getCaptcha() {
      this.sendEmailLoading = true;
      this.sendEmailDisabled = true;
      const params = {
        mail: this.mail,
      };
      sendEmail(params)
        .then((res) => {
          this.mail_code_token = res.result;
          this.sendEmailLoading = false;
          this.$message.success('send email success');
          this.timer = setInterval(this.CountDown, 1000);
        })
        .catch(
          () => {
            this.sendEmailLoading = false;
            this.sendEmailDisabled = false;
          },
        );
    },
    CountDown() {
      if (this.defaultTime > 0) {
        this.defaultTime = this.defaultTime - 1;
        this.sendEmailText = this.defaultTime;
      } else {
        clearInterval(this.timer);
        this.defaultTime = 60;
        this.sendEmailDisabled = false;
        this.sendEmailText = 'Send';
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.authBindForm.validateFields((err, values) => {
        if (!err) {
          this.sendMailVisible = true;
          this.google_code = values.google_code;
        }
      });
    },
    handleBindSubmit(e) {
      e.preventDefault();
      this.sendMailForm.validateFields((err, values) => {
        if (!err) {
          const params = {
            mailCodeToken: this.mail_code_token,
            bindingCode: this.google_code,
            bindingKey: this.key,
          };
          bindGa(params, values['email-code']).then((res) => {
            if (res.code === 0) {
              this.sendMailVisible = false;
              this.$message.success('Bind success');
              localStorage.clear();
              this.$router.push({
                path: '/',
                query: {
                  login: false,
                },
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.google_auth_wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
  min-height: 800px;
  background: #fff;
  position: relative;
  padding: 60px;
  @media (max-width: 960px) {
    height: auto;
    padding: 12px;
  }
  .backContainer {
    position: absolute;
    font-size: 18px;
    cursor: pointer;
    z-index: 999;
  }
  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.8;
    margin-left: 30px;
  }
  .google-container {
    margin-top: 40px;
    .title {
      display: flex;
      .step {
        background: #0045ff;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        color: #ffffff;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      .tips {
        flex: 1;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-left: 10px;
        .step_tips {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #000000;
          mix-blend-mode: normal;
          opacity: 0.6;
          margin-top: 10px;
          .google_input {
            height: 48px;
          }
        }
      }
    }
    .qrcode_container {
      display: flex;
      padding: 40px 130px;
      @media (max-width: 960px) {
        padding: 20px 0;
        flex-direction: column;
      }
      .qrcode_container_dis {
        display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        @media (max-width: 960px) {
          padding: 20px 0;
        }
        .down_tips {
          font-size: 18px;
          margin-top: 20px;
        }
      }
    }
    .add_key_container {
      display: flex;
      margin-top: 20px;
      background: rgba(0, 69, 255, 0.03);
      border-radius: 8px;
      padding: 20px 40px;
      align-items: center;
      .auto_key_left {
        margin-right: 20px;
      }
      .auto_key_right {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
      .auth_key_right_copy {
        font-size: 14px;
        line-height: 24px;
        color: #0045ff;
        margin-top: 10px;
      }
    }
    .tips_container {
      width: 100%;
      border-radius: 20px;
      padding: 30px;
      margin: 10px 0 40px;
      background: rgba(250, 179, 30, 0.1);
      color: #ff8e00;
      .title_tips {
        display: flex;
        margin-bottom: 20px;
        font-size: 0;
        span {
          margin-left: 10px;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #ff8e00;
        }
      }
      .tips {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin: 5px;
      }
    }
    .bottom_container {
      margin-top: 40px;
      text-align: center;
      .submit {
        width: 400px;
        height: 48px;
      }
    }
  }
}
.sendMailBtn {
  width: 100%;
}
.confirmContainer {
  text-align: center;
  .goToBtn {
    background: #0045ff;
    border-radius: 8px;
    height: 40px;
    width: 100px;
    color: #fff;
  }
}
</style>
