<template>
  <div class="wrapper_card">
    <div class="card_container">
      <div class="CardHeader">
        <div class="title">Add new card</div>
        <div class="titleTips">
          Complete your card information for a higher purchase limit.
        </div>
      </div>
      <a-spin tip="Loading..." :spinning="loading">
        <!-- <div class="stepsContainer">
          <a-steps :current="current" class="card-steps">
            <a-step
              v-for="item in steps"
              :key="item.title"
              :title="item.title"
            />
          </a-steps>
        </div> -->
        <div class="stepContainer" v-if="current === 0">
          <div class="inputContent">
            <div class="inputLabel">First name</div>
            <a-input
              class="stepInput"
              :value="first_name"
              placeholder="Please enter your legal first name"
              @change="
                (e) => {
                  this.first_name = e.target.value;
                }
              "
            />
          </div>
          <div class="inputContent">
            <div class="inputLabel">Last name</div>
            <a-input
              class="stepInput"
              :value="last_name"
              placeholder="Please enter your legal last name"
              @change="
                (e) => {
                  this.last_name = e.target.value;
                }
              "
            />
          </div>
          <div class="inputContent">
            <div class="inputLabel">Card Number</div>
            <a-input
              class="stepInput"
              :value="card_number"
              placeholder="Please enter your card number"
              @change="
                (e) => {
                  this.card_number = e.target.value;
                }
              "
            />
          </div>
        </div>
        <div class="stepContainer" v-if="current === 1">
          <a-space class="inputContainer" :size="20">
            <div class="inputContent">
              <div class="inputLabel">Country</div>
              <a-select
                :value="country"
                :options="countryOption"
                placeholder="Please select your nationality"
                @change="(value) => (this.country = value)"
              />
            </div>
            <div class="inputContent">
              <div class="inputLabel">City</div>
              <a-input
                class="stepInput"
                :value="city"
                placeholder="Please enter your city"
                @change="
                  (e) => {
                    this.city = e.target.value;
                  }
                "
              />
            </div>
          </a-space>
          <a-space class="inputContainer" :size="20">
            <div class="inputContent">
              <div class="inputLabel">Street</div>
              <a-input
                class="stepInput"
                :value="address"
                placeholder="Please enter your street"
                @change="
                  (e) => {
                    this.address = e.target.value;
                  }
                "
              />
            </div>
            <div class="inputContent">
              <div class="inputLabel">Postal code</div>
              <a-input
                class="stepInput"
                :value="zipcode"
                placeholder="Please enter your Postal code"
                @change="
                  (e) => {
                    this.zipcode = e.target.value;
                  }
                "
              />
            </div>
          </a-space>
          <div class="inputContent" :size="20" direction="vertical">
            <div class="inputLabel">
              <span>Selfie with passport and card</span>
              <a-upload
                name="file"
                :showUploadList="false"
                :action="uploadUrl"
                :headers="headers"
                maxCount="1"
                :before-upload="beforeUpload"
                @change="handleChange"
              >
                <a-button class="uploadBtn" type="primary">
                  <span>
                    upload
                    <img src="@/assets/cloudUpload.svg" alt="upload" />
                  </span>
                </a-button>
              </a-upload>
            </div>
            <div>
              <div class="idCardContainer">
                <a-spin :spinning="frontLoading">
                  <img
                    v-if="front_http_doc || front_doc"
                    :src="front_http_doc || front_doc"
                    alt="front card"
                  />
                  <img
                    v-else
                    src="@/assets/drivingImg.svg"
                    alt="front card"
                  />
                </a-spin>
              </div>
              <span class="uploadDescribe"
                >*You must provide a passport that belong to you. Make sure the
                text on the passport is clear.
              </span>
              <span class="uploadDescribe"
                >*Plastic card: Hold the passport and the card. Make sure your
                face, your passport and the card are clearly visible.
              </span>
              <span class="uploadDescribe"
                >*Virtual card: Hold the passport and handwritten virtual card
                number. Make sure your face, your passport and the card number
                are clearly visible.</span
              >
            </div>
          </div>
          <div class="checkboxContainer">
            <a-checkbox @change="checkBoxChange" :checked="checked" />
            <div class="tips">
              I confirm to be the person in the photo, and the owner of the
              passport and the card.
            </div>
          </div>
        </div>
        <div class="stepsButtonContainer" v-if="current === 0 || current === 1">
          <a-button class="stepsButton" type="primary" @click="submitKyc">
            {{ btnTitle }}
          </a-button>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { getBindCardInfo, bindCard } from '@/utils/api';
import { baseAdminUrl } from '@/utils/baseUrl';
import countryList from '@/utils/Country.json';

export default {
  name: 'Card',
  props: {
    changeDefaultMenu: {
      type: Function,
      require: false,
    },
    id: {
      type: Number,
      require: false,
    },
  },
  data() {
    return {
      uploadUrl: `${baseAdminUrl}/v1/common/file/public`,
      headers: {
        authorization: localStorage.getItem('inst_money_access_token') || '',
      },
      countryOption: [],
      step: 1,
      loading: false,
      first_name: null,
      last_name: null,
      card_number: null,
      country: null,
      address: null,
      city: null,
      front_http_doc: null,
      front_doc: null,
      frontLoading: false,
      cardInfo: {},
      status: null,
      current: 0,
      steps: [
        { title: 'Card Information', content: 'first-content' },
        // { title: "Billing Address", content: "second-content" },
      ],
      btnTitle: 'Submit',
      checked: false,
    };
  },
  created() {
    const initCountry = countryList.map((item) => {
      const tmp = {
        value: item.countryCode,
        label: item.name_en,
        code: item.code,
        key: item.name_cn,
      };
      return tmp;
    });
    this.countryOption = initCountry;
    if (this.id) {
      this.loading = true;
      getBindCardInfo(this.id).then((res) => {
        this.loading = false;
        if (res.code === 0) {
          this.first_name = res.result.first_name;
          this.last_name = res.result.last_name;
          this.card_number = res.result.card_number;
          this.country = res.result.country;
          this.address = res.result.address;
          this.city = res.result.city;
          this.zipcode = res.result.zipcode;
          this.front_http_doc = res.result.front_doc;
          this.cardInfo = res.result;
          this.status = res.result.status;
        }
      });
    }
  },
  methods: {
    checkBoxChange() {
      this.checked = !this.checked;
    },
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG/PNG file!');
      }
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        this.$message.error('Image must smaller than 4MB!');
      }
      return isJpgOrPng && isLt4M;
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.frontLoading = true;
        return;
      }
      if (info.file.status === 'done') {
        this.frontLoading = false;
        if (info?.file?.response?.code === 0) {
          this.front_http_doc = info?.file?.response?.result;
          this.getBase64(info.file.originFileObj, (imageUrl) => {
            this.front_doc = imageUrl;
          });
        }
      }
    },
    submitKyc() {
      const params = {
        ...this.cardInfo,
        first_name: this.first_name,
        last_name: this.last_name,
        card_number: this.card_number,
        status: 2,
      };
      if (this.current === 0) {
        if (!this.first_name) {
          this.$message.error('Please enter your first name', 3);
        } else if (!this.last_name) {
          this.$message.error('Please enter your last name', 3);
        } else if (this.card_number.length !== 16) {
          this.$message.error('Please enter your Correct card number', 3);
        } else {
          bindCard(params).then((res) => {
            if (res.code === 0) {
              // this.current = 1;
              // this.btnTitle = "Submit";
              this.changeDefaultMenu('bindCardResult', this.id, 3);
            }
          });
        }
      } else {
        params.country = this.country;
        params.city = this.city;
        params.address = this.address;
        params.zipcode = this.zipcode;
        params.front_doc = this.front_http_doc;
        params.status = 3;
        if (!this.country) {
          this.$message.error('Please select your country', 3);
        } else if (!this.city) {
          this.$message.error('Please enter your city', 3);
        } else if (!this.address) {
          this.$message.error('Please enter your address', 3);
        } else if (!this.zipcode) {
          this.$message.error('Please enter your zipcode', 3);
        } else if (!this.front_http_doc) {
          this.$message.error(
            'Please upload your selfie with passport and card',
            3,
          );
        } else if (!this.checked) {
          this.$message.error('Please agree to the terms of service first', 3);
        } else {
          bindCard(params).then((res) => {
            if (res.code === 0) {
              this.changeDefaultMenu('bindCardResult', this.id, 3);
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper_card {
  margin: auto;
  min-height: 800px;
  padding: 60px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: #fff;
  @media (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    padding: 12px;
  }
  .card_container {
    max-width: 900px;
    margin: auto;
    .CardHeader {
      display: flex;
      flex-direction: column;
      .title {
        font-family: SamsungSharpSans-Bold;
        font-weight: bold;
        font-size: 40px;
        line-height: 50px;
        text-align: center;
        opacity: 0.8;
        color: #000;
      }
      .titleTips {
        font-family: Roboto;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        opacity: 0.3;
        margin-top: 30px;
        margin-bottom: 60px;
      }
    }
    .stepsContainer {
      width: 100%;
      padding: 24px 48px;
      background-color: #fafafa;
      border-radius: 12px;
    }
    .stepContainer {
      margin-top: 10px;
      .inputContainer {
        @media (max-width: 960px) {
          display: block;
          /deep/ .ant-space-item {
            width: 100%;
          }
        }
        width: 100%;
        /deep/ .ant-space-item {
          flex: 1;
        }
      }
      .inputContent {
        margin-top: 10px;
        .inputLabel {
          margin-bottom: 10px;
          font-size: 16px;
        }
        .stepInput {
          height: 60px;
          border-radius: 8px;
          /deep/ input {
            height: 60px;
          }
        }
        .ant-select {
          width: 100%;
          height: 60px;
          /deep/ .ant-select-selection {
            height: 60px !important;
            border-radius: 8px;
            .ant-select-selection__rendered {
              height: 60px !important;
              line-height: 60px;
            }
          }
        }
        .uploadBtn {
          margin-left: 20px;
          border-radius: 4px;
          font-size: 0;
          span {
            font-size: 14px;
          }
          img {
            height: 24px;
            margin-left: 10px;
          }
        }
        .idCardContainer {
          width: 100%;
          height: 200px;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          text-align: center;
          margin-bottom: 10px;
          img {
            height: 180px;
            margin-top: 10px;
          }
        }
        .uploadDescribe {
          display: block;
          font-size: 14px;
          line-height: 24px;
          opacity: 0.3;
        }
      }
    }
    .checkboxContainer {
      display: flex;
      align-items: center;
      margin-top: 36px;
      .tips {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-left: 10px;
      }
    }
    .stepsButtonContainer {
      margin-top: 24px;
      text-align: center;
      .stepsButton {
        width: 300px;
        height: 60px;
        border-radius: 8px;
        margin: 0 8px 20px;
        @media (max-width: 960px) {
          width: 100%;
          margin-bottom: 20px;
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }
}
</style>
