
<template>
  <div class="kycContent">
    <div class="kycContainer" v-if="defaultShow === 'authentication'">
      <div class="kycContainerContent">
        <div class="title">
          <div class="titleLeft">
            <div class="leftTitle">Personal Identity Verification</div>
            <div class="leftTips">
              The information cannot be changed once verified. Please fill in
              authentic information.
            </div>
          </div>
          <div class="titleRight">
            <div class="rightTitle">Current Level</div>
            <div class="rightTips">
              <img class="starIcon" src="@/assets/starIcon.svg" alt="" />
              LV{{ kyc_level || 0 }}
            </div>
          </div>
        </div>
        <div class="my_tips_container">
          <div class="my_tips">
            <img src="@/assets/tipsIcon.svg" alt="" />
            <div class="tips_text">
              You may skip Lv.1 and complete the Lv2 verification directly to
              enjoy the maximum purchase limit or complete the Lv.1 verification
              first and then upgrade to Lv.2.
            </div>
          </div>
        </div>
        <a-spin :spinning="loading">
          <div class="verifyCardContainer" v-for="item in data" :key="item.level">
            <div class="verifyCardLeft">
              <div class="leftTop">
                <img class="starIcon" src="@/assets/starIcon.svg" alt="" />
                <span class="levelText">{{ item.level }}</span>
                {{ item.levelTips }}
              </div>
              <div class="leftMsg">
                <div class="leftMsgTitle">Required document:</div>
                <div class="leftMsgTips">{{ item.idType }}</div>
              </div>
              <div class="leftMsg leftMsgLast">
                <div class="leftMsgTitle">User Benefits:</div>
                <div class="leftMsgTips">{{ item.benefit }}</div>
              </div>
            </div>
            <div class="verifyCardRight">
              <a-button
                v-if="item.level === 'LV1'"
                class="verifyButton"
                type="primary"
                @click="changeLevel(1)"
                :disabled="
                  (kyc_level === '2' && status !== 0) ||
                  status === 2 ||
                  driver_status === 2 ||
                  kyc_level === '3' ||
                  kyc_level === '4'
                "
              >
                {{
                  driver_status === 2 || status === 2
                    ? "Verified"
                    : driver_status === 7
                    ? "Failed"
                    : !driver_status
                    ? "Verify"
                    : "Under Review"
                }}
              </a-button>
              <a-button
                v-if="item.level === 'LV2'"
                class="verifyButton"
                type="primary"
                @click="changeLevel(2)"
                :disabled="
                  (kyc_level === '1' && driver_status !== 2) || status === 2
                "
              >
                {{
                  status === 2
                    ? "Verified"
                    : status === 7
                    ? "Failed"
                    : !status
                    ? "Verify"
                    : "Under Review"
                }}
              </a-button>
            </div>
          </div>
          <div class="verifyCardContainer">
            <div class="verifyCardLeft">
              <div class="leftTop">
                <img class="starIcon" src="@/assets/starIcon.svg" alt="" />
                <span class="levelText">LV3</span>
                Advanced
              </div>
              <div class="leftMsg">
                <div class="leftMsgTitle">Required:</div>
                <div class="leftMsgTips">
                  1. Achieve Lv.2 before applying for Lv.3.
                </div>
                <div class="leftMsgTips">
                  2. The account must be registered more than one month
                </div>
                <div class="leftMsgTips">3. Selfie with passport</div>
              </div>
              <div class="leftMsg leftMsgLast">
                <div class="leftMsgTitle">User Benefits:</div>
                <div class="leftMsgTips">1000 USD daily of purchase limit</div>
              </div>
            </div>
            <div class="verifyCardRight">
              <a-button
                class="verifyButton"
                type="primary"
                @click="changeLevel(3)"
                :disabled="
                  (kyc_level === '3' && level_three_status === 0) ||
                  status !== 2 ||
                  level_three_status === 2
                "
              >
                {{
                  level_three_status === 2
                    ? "Verified"
                    : level_three_status === 7
                    ? "Failed"
                    : level_three_status === null
                    ? "Verify"
                    : "Under Review"
                }}
              </a-button>
            </div>
          </div>
          <div class="verifyCardContainer">
            <div class="verifyCardLeft">
              <div class="leftTop">
                <img class="starIcon" src="@/assets/starIcon.svg" alt="" />
                <span class="levelText">LV4</span>
                Top
              </div>
              <div class="leftMsg">
                <div class="leftMsgTitle">Required:</div>
                <div class="leftMsgTips">
                  <div>1.</div>
                  <div>Achieve Lv.3 before applying for Lv.4.</div>
                </div>
                <div class="leftMsgTips">
                  <div>2.</div>
                  <div>Send required documents by email.</div>
                </div>
              </div>
              <div class="leftMsg leftMsgLast">
                <div class="leftMsgTitle">User Benefits:</div>
                <div class="leftMsgTips">3000 USD daily of purchase limit</div>
              </div>
            </div>
            <div class="verifyCardRight">
              <a-button
                class="verifyButton"
                type="primary"
                @click="openApplyModal"
                :disabled="level_four_status === 2 || level_three_status !== 2"
              >
                {{ level_four_status === 2 ? "Verified" : "Verify" }}
              </a-button>
            </div>
          </div>
        </a-spin>
      </div>
    </div>
    <Kyc
      v-if="defaultShow === 'kyc'"
      :kycLevel="kycLevel"
      :changeStep="changeShow"
    />
    <a-modal
      v-model="applyModalVisible"
      centered
      :footer="null"
      :maskClosable="false"
      width="800px"
      title="About Lv.4 Verification"
      @cancel="handleApplyModalCancel"
    >
      <div class="topContainer">
        <h4>Process:</h4>
        <div class="stepContainer">
          <a-steps>
            <a-step status="finish" title="Send documents via email">
              <a-icon slot="icon" type="message" theme="twoTone" />
            </a-step>
            <a-step status="finish" title="Review (around 1 week)">
              <a-icon slot="icon" type="fund" theme="twoTone" />
            </a-step>
            <a-step status="finish" title="Get result via email">
              <a-icon slot="icon" type="mail" theme="twoTone" />
            </a-step>
          </a-steps>
        </div>
        <div class="tipsContainer">
          <div class="leftMsg">
            <div class="leftMsgTitle">Required document:</div>
            <div class="leftMsgTips leftMsgTipsBla">
              <div>1.&nbsp;</div>
              <div>
                A statement of how the purchased tokens will be used with photos
                or videos of the platform to use.
              </div>
            </div>
            <div class="leftMsgTips leftMsgTipsBla">
              <div>2.&nbsp;</div>
              <div>
                Proof of Address. Provide your complete address in English,
                along with a recent proof of address issued no more than 6
                months before the date you submit it. A proof of address can be
                a utility bill, phone bill, or bank statement that displays your
                name and current address.
              </div>
            </div>
          </div>
        </div>
        <div class="tipsContact">
          To keep your information safe, please send the required documents to
          <a href="mailto:support@inst.money">support@inst.money</a>
        </div>
        <div class="submitContainer">
          <a-button
            class="verifyButton"
            type="primary"
            @click="
              () => {
                this.applyModalVisible = false;
              }
            "
          >
            Confirm
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<!-- eslint-disable camelcase -->
<script>
import { getAccountKyc } from '@/utils/api';
import Kyc from '../Kyc/index.vue';

export default {
  name: 'AuthenticationCenter',
  components: { Kyc },
  data() {
    return {
      defaultShow: 'authentication',
      data: [
        {
          level: 'LV1',
          levelTips: 'Basic',
          idType: 'Driving license Or ID Card',
          benefit: '200 USD daily of purchase limit',
          status: null,
        },
        {
          level: 'LV2',
          levelTips: 'Intermediate',
          idType: 'Passport',
          benefit: '500 USD daily of purchase limit',
          status: null,
        },
      ],
      kycLevel: null,
      kyc_level: null,
      driver_status: null,
      status: null,
      level_three_status: null,
      level_four_status: null,
      loading: true,
      applyModalVisible: false,
    };
  },
  created() {
    this.getKycDetail();
  },
  methods: {
    changeShow(value) {
      this.defaultShow = value;
    },
    changeLevel(value) {
      this.kycLevel = value;
      this.defaultShow = 'kyc';
    },
    getKycDetail() {
      getAccountKyc().then((res) => {
        this.loading = false;
        if (res.result) {
          const {
            driver_status,
            status,
            kyc_level,
            level_three_status,
            level_four_status,
          } = res.result;
          this.driver_status = driver_status;
          this.status = status;
          this.level_three_status = level_three_status;
          this.level_four_status = level_four_status;
          this.kyc_level = kyc_level;
        }
      });
    },
    openApplyModal() {
      this.applyModalVisible = true;
    },
    handleApplyModalCancel() {
      this.applyModalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.kycContent {
  .kycContainer {
    @media (max-width: 960px) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
    .kycContainerContent {
      background: #fff;
      border-radius: 4px;
      padding: 60px;
      // min-height: 800px;
      @media (max-width: 960px) {
        padding: 24px;
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        @media (max-width: 960px) {
          flex-direction: column;
        }
        .titleLeft {
          .leftTitle {
            font-family: SamsungSharpSans-Bold;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 20px;
          }
          .leftTips {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            opacity: 0.4;
          }
        }
        .titleRight {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          @media (max-width: 960px) {
            flex-direction: row;
            display: flex;
            align-items: center;
            width: 100%;
          }
          .rightTitle {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            opacity: 0.6;
            margin-bottom: 16px;
          }
          .rightTips {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #faa72c;
            .starIcon {
              margin-right: 10px;
            }
          }
        }
      }
      .my_tips_container {
        background: rgba(250, 179, 30, 0.1);
        display: flex;
        padding: 15px 25px;
        margin-bottom: 40px;
        .my_tips {
          display: flex;
          margin: auto;
          @media (max-width: 960px) {
            width: 100%;
          }
          img {
            width: 18px;
            margin-right: 14px;
          }
          .tips_text {
            font-family: "Poppins-Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #ff8e00;
          }
        }
      }
      .verifyCardContainer {
        background: rgba(0, 69, 255, 0.03);
        border-radius: 20px;
        padding: 30px 40px;
        min-height: 230px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        @media (max-width: 960px) {
          flex-direction: column;
        }
        .verifyCardLeft {
          @media (max-width: 960px) {
            margin-bottom: 24px;
          }
          .leftTop {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 30px;
            .starIcon {
              width: 30px;
              height: 30px;
            }
            .levelText {
              font-weight: 600;
              font-size: 20px;
              line-height: 23px;
              color: #060606;
              margin: 0 10px;
            }
          }
          .leftMsg {
            margin-bottom: 20px;
            .leftMsgTitle {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              opacity: 0.6;
              margin-bottom: 10px;
            }
            .leftMsgTips {
              display: flex;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              color: #0045ff;
            }
            .leftMsgTipsSContainer {
              margin-top: 12px;
            }
            .leftMsgTipsS {
              margin-left: 6px;
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
              color: #000;
              opacity: 0.5;
            }
          }
          .leftMsgLast {
            margin-bottom: 0;
          }
        }
        .verifyCardRight {
          .verifyButton {
            width: 160px;
            height: 48px;
          }
        }
      }
    }
  }
}
.topContainer {
  h4 {
    color: #000;
  }
  .stepContainer {
    margin-top: 12px;
    background: #f5f5f5;
    padding: 36px 12px;
    border-radius: 4px;
  }
  .tipsContainer {
    margin-top: 24px;
    .leftMsg {
      .leftMsgTitle {
        font-weight: 500;
        color: #000;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 10px;
      }
      .leftMsgTips {
        display: flex;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #0045ff;
      }
      .leftMsgTipsBla {
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
        font-size: 14px;
      }
      .leftMsgTipsSContainer {
        margin-top: 12px;
      }
      .leftMsgTipsS {
        margin-left: 6px;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #000;
        opacity: 0.5;
      }
    }
  }
  .tipsContact {
    font-weight: 500;
    color: #000;
    margin-top: 24px;
    font-size: 14px;
    line-height: 16px;
  }
  .submitContainer {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    .verifyButton {
      width: 160px;
      height: 48px;
    }
  }
}
</style>
