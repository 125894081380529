var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper_card"},[_c('div',{staticClass:"card_container"},[_vm._m(0),_c('a-spin',{attrs:{"tip":"Loading...","spinning":_vm.loading}},[(_vm.current === 0)?_c('div',{staticClass:"stepContainer"},[_c('div',{staticClass:"inputContent"},[_c('div',{staticClass:"inputLabel"},[_vm._v("First name")]),_c('a-input',{staticClass:"stepInput",attrs:{"value":_vm.first_name,"placeholder":"Please enter your legal first name"},on:{"change":(e) => {
                this.first_name = e.target.value;
              }}})],1),_c('div',{staticClass:"inputContent"},[_c('div',{staticClass:"inputLabel"},[_vm._v("Last name")]),_c('a-input',{staticClass:"stepInput",attrs:{"value":_vm.last_name,"placeholder":"Please enter your legal last name"},on:{"change":(e) => {
                this.last_name = e.target.value;
              }}})],1),_c('div',{staticClass:"inputContent"},[_c('div',{staticClass:"inputLabel"},[_vm._v("Card Number")]),_c('a-input',{staticClass:"stepInput",attrs:{"value":_vm.card_number,"placeholder":"Please enter your card number"},on:{"change":(e) => {
                this.card_number = e.target.value;
              }}})],1)]):_vm._e(),(_vm.current === 1)?_c('div',{staticClass:"stepContainer"},[_c('a-space',{staticClass:"inputContainer",attrs:{"size":20}},[_c('div',{staticClass:"inputContent"},[_c('div',{staticClass:"inputLabel"},[_vm._v("Country")]),_c('a-select',{attrs:{"value":_vm.country,"options":_vm.countryOption,"placeholder":"Please select your nationality"},on:{"change":(value) => (this.country = value)}})],1),_c('div',{staticClass:"inputContent"},[_c('div',{staticClass:"inputLabel"},[_vm._v("City")]),_c('a-input',{staticClass:"stepInput",attrs:{"value":_vm.city,"placeholder":"Please enter your city"},on:{"change":(e) => {
                  this.city = e.target.value;
                }}})],1)]),_c('a-space',{staticClass:"inputContainer",attrs:{"size":20}},[_c('div',{staticClass:"inputContent"},[_c('div',{staticClass:"inputLabel"},[_vm._v("Street")]),_c('a-input',{staticClass:"stepInput",attrs:{"value":_vm.address,"placeholder":"Please enter your street"},on:{"change":(e) => {
                  this.address = e.target.value;
                }}})],1),_c('div',{staticClass:"inputContent"},[_c('div',{staticClass:"inputLabel"},[_vm._v("Postal code")]),_c('a-input',{staticClass:"stepInput",attrs:{"value":_vm.zipcode,"placeholder":"Please enter your Postal code"},on:{"change":(e) => {
                  this.zipcode = e.target.value;
                }}})],1)]),_c('div',{staticClass:"inputContent",attrs:{"size":20,"direction":"vertical"}},[_c('div',{staticClass:"inputLabel"},[_c('span',[_vm._v("Selfie with passport and card")]),_c('a-upload',{attrs:{"name":"file","showUploadList":false,"action":_vm.uploadUrl,"headers":_vm.headers,"maxCount":"1","before-upload":_vm.beforeUpload},on:{"change":_vm.handleChange}},[_c('a-button',{staticClass:"uploadBtn",attrs:{"type":"primary"}},[_c('span',[_vm._v(" upload "),_c('img',{attrs:{"src":require("@/assets/cloudUpload.svg"),"alt":"upload"}})])])],1)],1),_c('div',[_c('div',{staticClass:"idCardContainer"},[_c('a-spin',{attrs:{"spinning":_vm.frontLoading}},[(_vm.front_http_doc || _vm.front_doc)?_c('img',{attrs:{"src":_vm.front_http_doc || _vm.front_doc,"alt":"front card"}}):_c('img',{attrs:{"src":require("@/assets/drivingImg.svg"),"alt":"front card"}})])],1),_c('span',{staticClass:"uploadDescribe"},[_vm._v("*You must provide a passport that belong to you. Make sure the text on the passport is clear. ")]),_c('span',{staticClass:"uploadDescribe"},[_vm._v("*Plastic card: Hold the passport and the card. Make sure your face, your passport and the card are clearly visible. ")]),_c('span',{staticClass:"uploadDescribe"},[_vm._v("*Virtual card: Hold the passport and handwritten virtual card number. Make sure your face, your passport and the card number are clearly visible.")])])]),_c('div',{staticClass:"checkboxContainer"},[_c('a-checkbox',{attrs:{"checked":_vm.checked},on:{"change":_vm.checkBoxChange}}),_c('div',{staticClass:"tips"},[_vm._v(" I confirm to be the person in the photo, and the owner of the passport and the card. ")])],1)],1):_vm._e(),(_vm.current === 0 || _vm.current === 1)?_c('div',{staticClass:"stepsButtonContainer"},[_c('a-button',{staticClass:"stepsButton",attrs:{"type":"primary"},on:{"click":_vm.submitKyc}},[_vm._v(" "+_vm._s(_vm.btnTitle)+" ")])],1):_vm._e()])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"CardHeader"},[_c('div',{staticClass:"title"},[_vm._v("Add new card")]),_c('div',{staticClass:"titleTips"},[_vm._v(" Complete your card information for a higher purchase limit. ")])])
}]

export { render, staticRenderFns }